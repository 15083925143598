import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'column',
        marginTop: 24,
    },
    reportTitle:{
        color: 'black',
        fontSize: 9,
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
    },
    PrintTitle:{
      color: 'black',
      fontSize: 9,
      textAlign: 'left',
  },
    ServiceTitle:{
      color: 'black',
      fontSize: 8,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'left',
  },
    addressTitle:{
      color: 'black',
      fontSize: 7,
      textAlign: 'left',
  }
  });


  const InvoiceTitle = ({item}) => (
    <View style={styles.titleContainer}>
      <View
      style={{
        position:"absolute",
        marginTop:-35
      }}
      >
        <Text style={styles.addressTitle}>{"Crescent Arcade, G-8 Markaz, Islamabad, Pakistan."}</Text>
        <Text style={styles.addressTitle}>{"Phone : 051-111-988-988"}</Text>
        <Text style={styles.ServiceTitle}>{"24 Hours a Day, 7 Days a Week Service"}</Text>
     </View>
        <Text style={styles.reportTitle}>{item.branch}</Text>
  <Text style={{
      color: 'black',
      fontSize: 9,
      fontFamily: 'Helvetica',
      textAlign: 'left',
  }}>{item.Panel_Name}</Text>
  <Text style={styles.PrintTitle}>{"Printed on "+item.print_date+" at "+item.print_time}</Text>
    
    </View>
  );
  
  export default InvoiceTitle