import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import InvoiceItemsTable from './InvoiceItemsTable'
import logo from '../../mpl-logo.png'

const styles = StyleSheet.create({
    page: {
        paddingTop: 150,
        paddingBottom: 80,
        fontSize: 8,
        paddingLeft: 10,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },

    logo: {
        width: 130,
        height: 60,
        marginTop: -70
    },
    barcode: {
        width: 130,
        height: 20
    }
});


const Invoice = ({ invoice }) => {

    return (<Document>
        <Page style={styles.page}
            size="A5"
            wrap={true}
        >
            <View fixed style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                top: 0,
                position: 'absolute',
                paddingLeft: 25,
                alignItems: "center",
                paddingRight: 10,
                paddingTop: 10
            }} >
                <View
                    style={{
                        width: "30%"
                    }}
                >
                    <Image style={styles.logo} src={logo} />
                </View>

                <View style={{ marginTop: -70, marginLeft: 20, width: "30%" }} >
                    <Image style={{
                        width: 80,
                        height: 35,
                        marginLeft: 3,
                        paddingLeft: 13
                    }} src={require("../../invoice.png")} />

                    <View style={{
                        margin: 'auto', width: 70, marginLeft: 15, backgroundColor: '#F2F0F0', borderWidth: 1,
                        borderColor: 'black'
                    }}>
                        <Text style={{ paddingTop: '3em', paddingLeft: '3.5em', paddingRight: '3.5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 7 }}>MPL ID : {invoice.invoice_id}</Text>
                    </View>

                </View>
                <View style={{ width: "40%", marginLeft: -10 }}  >
                    <Image style={styles.barcode} src={invoice.barcode} />
                    <View

                    >
                        <Text style={{
                            marginLeft: '5em',
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            marginBottom: 10,
                            marginTop: 5,


                        }}>{invoice.toAddress + " " + invoice.name}</Text>
                    </View>
                    <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderTopColor: 'black',
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                        borderTopWidth: 0.75

                    }}>Age/Gender :  {invoice.age_gender}</Text>
                    <Text style={{
                        marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                        borderBottomColor: 'black',
                        borderBottomWidth: 0.75,
                    }}>Contact # :  {invoice.contact}</Text>
                    {
                        invoice.flight_no == "" ?
                            <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>Ref By : <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 7 }}>{invoice.Ref_By}</Text></Text> : null
                    }

                    {
                        invoice.Ref_No != "" && invoice.flight_no == ""
                            ? <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>Ref No : {invoice.Ref_No}</Text>
                            : null
                    }
                    {
                        invoice.Entitlement_Name != ""
                            ?
                            <Text style={{
                                marginLeft: '5em', paddingTop: '5em', fontSize: 7,
                                borderBottomColor: 'black',
                                borderBottomWidth: 0.75,
                            }}>Entitlement : <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 7 }}>{invoice.Entitlement_Name}</Text></Text>
                            :
                            null
                    }
                    {
                        invoice.flight_no != "" ?
                            <View>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '5em', fontFamily: 'Helvetica-Bold', fontSize: 7,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.75,
                                }}>Flight No : {invoice.flight_no}</Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '5em', fontFamily: 'Helvetica-Bold', fontSize: 7, borderBottomColor: 'black',
                                    borderBottomWidth: 0.75,
                                }}>Flight Date : {invoice.flight_date}</Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '5em', fontFamily: 'Helvetica-Bold', fontSize: 7, borderBottomColor: 'black',
                                    borderBottomWidth: 0.75,
                                }}>Flight Time : {invoice.flight_time}</Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '5em', fontFamily: 'Helvetica-Bold', fontSize: 7, borderBottomColor: 'black',
                                    borderBottomWidth: 0.75,
                                }}>Passport No : {invoice.passport_no}</Text>
                            </View>
                            : <View>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '15em', fontFamily: 'Helvetica-Bold', fontSize: 7,

                                }}></Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '15em', fontFamily: 'Helvetica-Bold', fontSize: 7,

                                }}></Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '15em', fontFamily: 'Helvetica-Bold', fontSize: 7,
                                }}></Text>
                                <Text style={{
                                    marginLeft: '5em', paddingTop: '15em', fontFamily: 'Helvetica-Bold', fontSize: 7,

                                }}></Text>
                            </View>
                    }
                </View>
            </View>
            <View style={{ width: '100%', marginTop: -20, paddingRight: 10, paddingLeft: 10 }}

            >

                <InvoiceItemsTable invoice={invoice}
                />

            </View>



            <View fixed style={{ position: 'absolute', width: '100%', bottom: 0 }}>
                <View style={{
                    paddingTop: 30,
                    paddingLeft: 20,
                    paddingBottom: 30,
                    flexDirection: 'row'
                }}>
                    <Image src={require("../../fssoft.png")} style={{
                        width: 50,
                        height: 20
                    }} />
                    <View style={{
                        marginLeft: 10,
                    }}>
                        <View style={{
                            flexDirection: 'row',
                            fontSize: 6
                        }}>
                            <Text style={{
                                fontSize: 6,
                                fontFamily: 'Helvetica-Bold',
                            }}>MPL Web Portal & MobileApps.</Text>
                            <Text>You can view online your results at</Text>
                        </View>
                        <View>
                            <Text style={{ fontSize: 6 }}>
                                https://mpl-labs.pk | mpl-labs.pk/android | https://mpl-labs.pk/ios</Text>
                        </View>
                        <View>
                            <Text style={{ fontSize: 6 }}>
                                Login ID : {invoice.patient_id} Password : {invoice.password}
                            </Text>
                        </View>
                        <View>
                            <Text style={{ fontSize: 6 }}>
                                User : {invoice.User_Name} Invoice Date : {invoice.invoice_date} Invoice Time : {invoice.invoice_time} Hrs
</Text>
                        </View>
                    </View>


                </View>


            </View>
        </Page>
    </Document>
    )
}

export default Invoice