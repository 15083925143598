import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text,Font } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'

import logo from '../mpl-logo.png'
import footer from '../mpl-footer.png'


Font.register({ family: 'Calibri Regular', src: require('../../fonts/Calibri Regular.ttf'),  });

Font.register({ family: 'Calibri Bold', src: require('../../fonts/calibri-bold.ttf') });
const styles = StyleSheet.create({
    page: { paddingTop:150,paddingBottom:180,
        fontSize: 8,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
      position: 'absolute',
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center'
    },
    qrcode:{
        width:60,
        height:60
    },
    logo: {
        width: 170,
        height: 90
    },
    barcode: {
        width: 150,
        height: 40
    }
  });

  
  const CovidTemplate=({invoice})=> {
     
   return (<Document  
   
    >
       <Page style={styles.page} size="A4" wrap 
             >
       <View fixed style={{
                 width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                 paddingLeft: 20,
                 alignItems:"center",
                 fontFamily:"Calibri Regular",
                 paddingRight: 20,
                 paddingTop: 10
             }} >
                 <Image style={styles.logo} src={logo} />
                
                <View style={{marginLeft:40}}>
                 <View style={{
                     margin: 'auto', width: 80, backgroundColor: '#F2F0F0', borderWidth: 1,
                     borderColor: 'black'
                 }}>
                     <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 8.5 }}>MPL ID : {invoice.invoice_id}</Text>
                 </View>
                 <View style={{
                     marginLeft:10,
                     paddingTop:20,
                     display:"flex",
                     flexDirection:'row'
 
                 }}>
                     <View >
                         <Text style={{
                             paddingLeft:19
                         }}>Online</Text>
                 <Image style={styles.qrcode}  src={invoice.QR_Code_Online} />
                 </View>
                 <View  style={{
                     marginLeft:30
                 }}>
                 <Text style={{
                       paddingLeft:19
                 }}>Offline</Text>
                 <Image style={styles.qrcode} src={invoice.QR_Code_Offline} />
                 </View>
                 </View>
                
                 </View>
                 <View style={{marginLeft:35, marginTop:5}}  >
                     <Image style={styles.barcode} src={invoice.barcode} />
 
                     <Text style={{
                         marginLeft: '5em', 
                         fontFamily: 'Calibri Bold', 
                         fontSize: 9, 
                         marginTop:5,
                         borderBottomColor: 'black',
                         borderBottomWidth: 0.3,
                     }}>{invoice.toAddress+" "+invoice.name}</Text>
                     <Text style={{
                         marginLeft: '5em', 
                         paddingTop: '5em',
                         fontSize: 6.5, 
                         paddingTop:8,
                         paddingBottom:2,
                         borderBottomColor: 'black',
                         borderBottomWidth: 0.3,
               
                     }}>{"Age/Gender   :  "+invoice.age_gender}</Text>
                   {
                       invoice.passport!=""
                       ?
                       <Text style={{
                         marginLeft: '5em',
                         paddingTop: '5em', 
                         fontSize: 6.5, 
                         paddingTop:8,
                         paddingBottom:2,
                         borderBottomColor: 'black',
                         borderBottomWidth: 0.3,
               
                     }}>{"Passport No   :  "+invoice.passport}</Text>
                     :
                     null
                   }
                     {invoice.title=="Covid-19 RNA by PCR" ?
                               <Text style={{
                                 marginLeft: '5em',
                                 paddingTop: '5em', 
                                 fontSize: 6.5, 
                                 paddingTop:8,
                                 paddingBottom:2,
                                 borderBottomColor: 'black',
                                 borderBottomWidth: 0.3,
                       
                                   }}>{"CNIC #             :  "+invoice.cnic}</Text>
                                   :null
                          }
 {
                     invoice.title=="Covid-19 RNA by PCR" || invoice.ShouldSampleDateTimePrint
                     ?
                     <View>
                     <Text style={{
                         marginLeft: '5em',
                         paddingTop: '5em', 
                         fontSize: 6.5, 
                         paddingTop:8,
                         paddingBottom:2,
                         borderBottomColor: 'black',
                         borderBottomWidth: 0.3,
               
                     }}>{"Sample Date  :  "+invoice.specimen_date}</Text>
                           <Text style={{
                         marginLeft: '5em',
                         paddingTop: '5em', 
                         fontSize: 6.5, 
                         paddingTop:8,
                         paddingBottom:2,
                         borderBottomColor: 'black',
                         borderBottomWidth: 0.3,
               
                           }}>{"Sample Time  :  "+invoice.specimen_time}</Text>
                           
                        
                               </View>
                               :null
 
                 }
                   <Text style={{
                         marginLeft: '5em',
                         paddingTop: '5em', 
                         fontSize: 6.5, 
                         paddingTop:8,
                         paddingBottom:2,
                         borderBottomColor: 'black',
                         borderBottomWidth: 0.3,
               
                     }}>{"Report Date   :  "+invoice.result_date}</Text>
                           <Text style={{
                         marginLeft: '5em',
                         paddingTop: '5em', 
                         fontSize: 6.5, 
                         paddingTop:8,
                         paddingBottom:2,
                         borderBottomColor: 'black',
                         borderBottomWidth: 0.3,
               
                           }}>{"Report Time   :  "+invoice.result_time}</Text>
                 </View>
                 
             
             </View>
             <View style={{width:'100%', height:'100%', marginTop:-40, paddingRight:20, paddingLeft:20}} 
            
            >
             
                <InvoiceItemsTable invoice={invoice}  
              />
            
            </View>
 
 
 
         <View fixed style={{ position: 'absolute', width: '100%', bottom: 0 }}>
             <View style={{alignItems:'flex-end', marginRight:20, marginBottom:20}}>
                 <Text style={{fontSize:7}}>User ID : {invoice.user_id}  </Text>
             <Text style={{color:'red', bottom:0, right:0, fontFamily:'Calibri Bold'}}>This is a digitally verified Report and does not require any Signature</Text>
          
             </View>
             <View>
                 <Image src={footer}  />
                 </View>
             </View>
       </Page>
     </Document>
  )
        }
  
export default CovidTemplate