import React, { useEffect } from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'
import Comments from './Comments'
import Specimen from './Specimen'
import Result from './Result'
import MedicineHeader from './MedicineHeader'
import CovidPDF from '../Covid_PDF/CovidTemplate'
import { keys } from '@material-ui/core/styles/createBreakpoints';
const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    fontFamily: "Helvetica",
    borderColor: 'black',
  },
  subReportTitle: {
    color: 'black',
        fontSize: 9,
        fontFamily:'Helvetica-Bold',
        paddingRight:5,
        paddingTop:5,
        textAlign: 'left'
  },
  GermTitle: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  SusProfileTitle:{
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  MedicineGroupName:{
    color: 'black',
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  }
});


class InvoiceItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.invoice,
      line: 2,
      page: 1,
      CrossCheck: {},
      NextPage: 0,
      Garbage: [],
      counter: 0,
      page:0,
      DefaultComments:{
        "Cobas C311":"Method/Analyzer: Cobas 6000, fully automated Modular System, from Roche Diagnostics, Switzerland.",
        "Cobas C111":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas e411":"Method/Analyzer: Cobas e 411, fully automated Electrochemiluminescence immunoassay (ECLIA) based analyzer, from Roche Diagnostics, Switzerland.",
        "Blue Diver Instrument":"Method/Analyzer: Blue Diver Instrument, automated Immunodot analyzer, from D-tek, Belgium.",
        "BC - 5000":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "BC - 5150":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Cobas U411":"Method/Analyzer: Cobas u 411, automated Urine analyzer, from Roche Diagnostics, Switzerland.",
        "Cube 30 Touch":"Method/Analyzer: Cube 30 TOUCH, Automatic instrument for ESR, from Diesse Diagnostica Senese, Italy.",
        "GeneXpert":"Method/Analyzer: GeneXpert ® PCR system by Cepheid, USA.",
        "BC6200":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Compact X, Behnk Elektronik":"Method/Analyzer: Thrombolyzer Compact X, , fully automated coagulation analyzer, from Behnk Elektronik GmbH & Co. Germany.",
        "FANhp UBT":"Method/Analyzer: FANhp, An automated Urea Breath Testing analyzer, from Fischer Analysen Instrumente (FAN), Germany.",
        "MiniCap Sebia":"Method/Analyzer: MINICAP FLEX PIERCING, fully automated Capillary Electrophoresis System, from Sebia, France.",
        "Chorus Trio":"Method/Analyzer: Chorus TRIO Instrument, automated ELISA based immunoassay analyzer, from Diesse Diagnostica Senese Spa, Italy.",
        "Manual":"",
        "OST":"",
        "Microlab 300":"",
        "Manual ELISA":"Method/Analyzer: PR-4100/PW-40, An Immunoassay System, from Bio-Rad, USA.",
        "Alegria Instrument":"Method/Analyzer: Alegria Instrument, automated ELISA based immunoassay analyzer, from Orgentec Diagnostika GmbH, Germany.",
        "HG SWIFT":"Method/Analyzer: HG Swift, A loop mediated iso thermal amplification (LAMP), from Hibergene, Ireland.",
      },
      Machine:"",
      singleTestCounter:0,
      MachineCommentsToShow:[],
      ShowMachineComments:true
    }
  }

  render() {

    return <View>
    
      {(Object.keys(this.state.data.list).map((key) => {

      var shiftMargin = 0
      var totalTests = 0
      this.state.line = this.state.line + 2
     
      var subGroupTitle = {}

      this.state.data.list[key].map((item) => {
        totalTests = totalTests + item.rowHeight
      })

      var FirstBox = this.state.line + this.state.data.list[key][0].rowHeight + 2

      if (FirstBox > 28) {
        this.state.line = 0
        this.state.NextPage = 1


      } else {
        this.state.NextPage = 0


      }
      if (key.includes("Complete") && this.state.data.list[key][0].prev1.length > 4) {
        this.state.line = this.state.line - 8
      }
      if (this.state.NextPage == 0) {

        this.state.Garbage.push(1)
      } else {
        this.state.Garbage = []
      }
      if (this.state.Garbage.length == 3) {
        this.state.NextPage = 1
        this.state.line = 0
      }

      if ((key.includes("Complete Blood Picture") || key.includes("Urine Routine Examination")) && this.state.counter > 0) {
        this.state.NextPage = 1
        this.state.line = 0
      }
      // Machine
    
      this.state.ShowMachineComments=false
      if(this.state.Machine!=this.state.data.Machine[key]){
        this.state.ShowMachineComments=true
        this.state.Machine=this.state.data.Machine[key]
        
      }
      console.log("Machine : ")
      console.log(this.state.data.Machine[key])
      var pasteMachineComm=this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]
      
      this.state.MachineCommentsToShow.push(pasteMachineComm)
   
      console.log(this.state.MachineCommentsToShow)
      // Germ Counter
      var GermCounter=0
      console.log(key)
      console.log(shiftMargin)
      console.log(this.state.NextPage)
      console.log(this.state.counter)
      console.log(Object.keys(this.state.data.Germs).length)
      console.log("Comments Flag")
      console.log(this.state.ShowMachineComments)
      console.log(this.state.data.MachineComments[key])
      this.state.counter++

      // Test counter
      console.log("Test List Length = ")
      console.log(Object.keys(this.state.data.list).length)
      if(this.state.data.list[key].length==1){
        this.state.singleTestCounter++
      }else{
        this.state.singleTestCounter=0
      }
      
     console.log("Machine Comments = ")
     console.log(this.state.data.comments[key])
      console.log(this.state.data.comments[key].trim().length)

      return <View>
                
          {
                key.includes("Covid") ||  key.includes("COVID-19 Rapid Antigen Test (RAT)")  ?
              <View>
               
              </View>
              :
              <View >
               
                <View style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: "100%",
                      fontSize: 7,
                      paddingLeft: 10,
                      paddingRight: 10,
                      marginTop:key.includes("Complete") ? -60 : -10
                    }}
                    >
                  
                  
                 </View>
              <View style={styles.tableContainer} >

                <View style={{
                  padding: 10

                }}>
                  {
                    this.state.counter > 1 ? <View break={true} key={key + "1"}></View> : null
                  }



                { ((this.state.data.list[key].length==1 && (key==this.state.data.list[key][0].test_name) ) || key=="Cobas e411" || key=="Cobas C311" ) ? null : <InvoiceTitle title={key} />}
                 
                  {
                    (this.state.data.list[key][0].subGroupTitle != "" && this.state.data.list[key][0].subGroupTitle != "-" && this.state.data.list[key][0].subGroupTitle[this.state.data.list[key][0].subGroupTitle] == undefined)
                      ?
                      null 
                    :
                     <InvoiceTableHeader data={this.state.data.list[key][0]} title={key}  />
                  }
  
                

                  {
                    this.state.data.list[key].map(item => {
                      this.state.line = this.state.line + item.rowHeight
                      var showSubHeadingBoolean = false
                      console.log(subGroupTitle[item.subGroupTitle])
                      if (item.subGroupTitle != "" && item.subGroupTitle != "-" && subGroupTitle[item.subGroupTitle] == undefined) {
                        showSubHeadingBoolean = true
                        subGroupTitle[item.subGroupTitle] = item.subGroupTitle
                        console.log("Sub Title")
                        console.log(subGroupTitle)
                        this.state.line = this.state.line + 2
                      }

                      var space = this.state.line > 28
                      if (this.state.line > 28) {
                        this.state.line = 0
                      }



                      return <View >

                            <View break={true} key={item.test_name + "1"}>

                            </View> 
                         
                        {
                          showSubHeadingBoolean ?
                          <View>
                            <View style={{
                              flexDirection: 'row',
                              justifyContent:'left'
                            }} >
                              <Text style={styles.subReportTitle}>

                                {item.subGroupTitle}

                              </Text>
                              </View>
                              {
                                key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"
                                ?
                                null
                                :
                                <InvoiceTableHeader data={this.state.data.list[key][0]} />

                              }
                             
                         
                           </View>
                            :
                            <Text></Text>
                        }
                        <View style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: "100%",
                          fontSize: 7,
                          marginTop: 0.5,
                          borderWidth: 0.5
                        }} key={item.test_name}>

                          <Text style={{

                            width: '20%',
                            textAlign: 'left',
                            paddingTop: 2,
                            paddingLeft: 8,
                            fontFamily: this.state.data.list[key].length==1 || (key=="Cobas e411" || key=="Cobas C311") ? "Helvetica-Bold" : "Helvetica",
                            height: '100%',
                            textAlign: 'left',
                            fontSize: 7
                          }}>{item.test_name}</Text>
                          <Text style={{

                            width: '25%',
                            textAlign: 'center',
                            borderLeftColor: 'black',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            fontWeight: '',
                            fontSize: 7.5,
                            height: '100%',
                            paddingLeft: 8,
                            fontFamily: "Helvetica-Bold",

                          }}>{item.result}</Text>
                          <Text style={{

                            width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '25%' : '15%',
                            height: '100%',
                            textAlign: 'left',
                            borderLeftColor: 'black',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            fontFamily: "Helvetica",
                            fontSize: 7,
                            paddingLeft: 8,

                          }}>{item.ref_range=="" ? "-" : item.ref_range}</Text>
                          <Text style={{
                            height: '100%',
                            width: '10%',
                            textAlign: 'left',
                            borderLeftColor: 'black',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            fontFamily: "Helvetica",
                            fontSize: 7,
                            paddingLeft: 8,



                          }}>{item.unit==""? "-" : item.unit}</Text>

                          <Text style={{

                            width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '10%' : '15%',
                            textAlign: 'left',
                            borderLeftColor: 'black',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            height: '100%',
                            fontFamily: "Helvetica",
                            fontSize: 6,
                            paddingLeft: 5,


                          }}>{item.prev1=="" ? "-" : item.prev1}</Text>
                          <Text style={{

                            width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '10%' : '15%',
                            height: '100%',
                            textAlign: 'left',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            fontSize: 6,
                            fontFamily: "Helvetica",
                            textOverflow: 'auto',
                            paddingLeft: 8

                          }}>{item.prev2=="" ? "-": item.prev2}</Text>

                        </View>


                      </View>
                    })

                  }
                  {
                    this.state.line > 28 ? this.state.line = 0 : null
                  }
                  <View style={{
                    width: "100%",
                    paddingTop: 2
                  }}>
                   
                  <View break={true} key={key + "2132187239"}><Text break={true} ></Text></View>

                  
                  <View>
                    {
                      key.includes("Complete") ?
                      <View style={{
                        paddingTop:10,
                        paddingLeft:10,
                        marginTop:5,
                        paddingBottom:10,
                        marginBottom:1,
                        backgroundColor:"#eeeeee",
                        borderWidth:0.5
                      }}>
                      <Text style={{
                         fontFamily: 'Helvetica-Bold',
                         fontSize: 8
                      }}>{"Pathologist Remarks"}</Text>
                      <View style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: "100%",
                          fontSize: 7,
                          paddingTop: 5
                          
    
                        }}
                        >
                          {
                            this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                              this.state.line = this.state.line + 1
                              var space = this.state.line > 28
                              if (data.length > 200) {
                                this.state.line = this.state.line + 1
                              }
    
                              if (data == "") {
                                return <View><Text>{""}</Text></View>
                              }
    
    
                              if (space) {
    
                                return <View style={{
                                  marginTop: 1,
                                  width: "100%",
                                }}>
                                  <View></View>
                                  <Text style={{
                                    textAlign: "left"
                                  }}>{data}</Text>
                                </View>
    
                              }
    
                              return <View style={{
                                marginTop: 1,
                                width: "100%",
                              }}>
                                <View></View>
                                <Text style={{
                                  textAlign: "left"
                                }}>{data}</Text>
                              </View>
                            })
                          }
             
                        </View>
                   
                     </View>
                     :
                   <View style={{
                    paddingTop:this.state.data.comments[key].trim().length==0 ? 0 :10,
                    paddingLeft:this.state.data.comments[key].trim().length==0 ? 0 :10,
                    paddingRight:this.state.data.comments[key].trim().length==0 ? 0 :10,
                    fontFamily:"Roboto",
                    marginTop:this.state.data.comments[key].trim().length==0 ? 0 : 5,
                    paddingBottom:this.state.data.comments[key].trim().length==0 ? 0 :10,
                    marginBottom:this.state.data.comments[key].trim().length==0 ? 0 :1,
                    backgroundColor:this.state.data.comments[key].trim().length==0 ? "white" :"#eeeeee",
                    borderWidth:this.state.data.comments[key].trim().length==0 ? 0 :0.5

                    }}
                    >
                      {
                         this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                          this.state.line = this.state.line + 1
                          var space = this.state.line > 28
                          if (data.length > 200) {
                            this.state.line = this.state.line + 1
                          }

                          if (data == "") {
                            return <View><Text>{""}</Text></View>
                          }


                          if (space) {

                            return <View style={{
                              marginTop: 1,
                              width: "100%",
                            }}>
                              <View></View>
                              <Text style={{
                                textAlign: "left"
                              }}>{data}</Text>
                            </View>

                          }

                          return <View style={{
                            marginTop: 1,
                            width: "100%"
                          }}>
                            <View></View>
                            <Text style={{
                              textAlign: "left"
                            }}>{data}</Text>
                          </View>
                        })
                     
                      }
                 
                   
                 
                    </View> 
                    }
                    
                    <Text style={{ fontSize: 7 }}>{this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]}</Text>
               
                  </View> 
                    

                  </View>
                </View>
                           


              </View>
             </View>
        }

      </View>


    }))}
    </View>
    
  }
}



export default InvoiceItemsTable