import React, { useEffect, useRef ,  Fragment} from "react";

import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";

import Modal from "@material-ui/core/Modal";

import NotificationAlert from "react-notification-alert";
import MaterialTable from "material-table";
import PrintIcon from '@material-ui/icons/Print';
import FormControl from "@material-ui/core/FormControl";
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import { addYears, set, max } from "date-fns";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import Report from './Report/Report'
import { render } from "react-dom";
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import PatientImgEx from "../images/avatarNew2.jpg"

import ReactWhatsapp from 'react-whatsapp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});


export default function Printing() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([])
  const [invoiceID, setInvoiceID] = React.useState("")
  const [Invalid, setInvalid] = React.useState(false)
  // Call Patient
  const [id,setId]=React.useState("")
  const [name,setName]=React.useState("")
  const [mobile,setMobile]=React.useState("")
  const [search_table, setSearch_table] = React.useState([]);
  const [open,setOpen]=React.useState(false)
  const [invoices,setInvoices]=React.useState([])
   // 25-jan to add new field for Search by refrnce no
  // const [RefNo,setRefNo]=React.useState("")

   
 
  // Invoice Record
  const [openInvoice,setOpenInvoice]=React.useState(false)
  // To Print the Reports
  const [toPrint, setToprint] = React.useState({})

  // Report Data Insertion
  const [reportData,setReportdata]=React.useState([])

  // Report Visibilty
  const [reportVisibility,setReportVisibility]=React.useState(false)

  // Print Indicator
  const [printIndicator, setPrintiDicator] = React.useState(false)

  // Select Controller
  const [SelectionControl,setSelectionControl]=React.useState(true)
  //GrouId for Picture
  const handleClose = () => {
    setOpen(false);
  };
  const inputRef = useRef(null);
  useEffect(() => {
    var vSearchStr={
      "User_ID" :localStorage.getItem('user_id'),
      "Menu_Option" :"WebPortal_Reporting"
  }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess",{
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify(vSearchStr)
    }).then(res=>res.json()).then((res)=>{
      console.log(res)
      if(res[0].Option_Status=="Authorized"){
        
      }else{
        localStorage.clear()
        window.location.href = "https://printing.mpl-labs.pk/"
      }
    })
  }, [])
  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  function sortKeys(obj_1) {
    // console.log("Printing 112 sortKeys value is ",obj_1)
    var key = Object.keys(obj_1)
    .sort(function order(key1, key2) {
     
        if ((obj_1[key1][0].TestAlignOrder) < (obj_1[key2][0].TestAlignOrder)) return -1;
        else if ((obj_1[key1][0].TestAlignOrder) > (obj_1[key2][0].TestAlignOrder)) return +1;
        else return 0;
    }); 
    console.log("Keys"+key.toString())
      
    // Taking the object in 'temp' object
    // and deleting the original object.
    var temp = {};
      
    for (var i = 0; i < key.length; i++) {
        temp[key[i]] = obj_1[key[i]];
        delete obj_1[key[i]];
    } 

    // Copying the object from 'temp' to 
    // 'original object'.
    for (var i = 0; i < key.length; i++) {
        obj_1[key[i]] = temp[key[i]];
    } 
    return obj_1
   
}

const handleOpen = () => {
  setOpen(true);
};
const search_patient = () => {
   // 25-jan to add new field for Search by refrnce no yhan pe RefNo de dena hai Api me 
  console.log(name)
  fetch(
    "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetsPatientsList?vName=" +
    name +
    "&vMobile=" +
    mobile +
    "&vID=" +
    id
  )
    .then((res) => res.json())
    .then((response) => {
      var data = [];
      response.map((item) => {
        console.log(item)
        var obj = {
          Patient_ID: item.Patient_ID,
          Patient_Name: item.Patient_Name,
          MobileNo: item.MobileNo,
          ToAddress: item.ToAddress,
          eMail: item.eMail,
          cnic: item.CNIC,

          select: (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
            setId(item.Patient_ID)
            setName(item.Patient_Name)
            setMobile(item.MobileNo)
            handleClose()
              }}
            >
              Select
            </Button>
          ),
        };

        data.push(obj);
      });
      setSearch_table(data);
      handleOpen(true);
    });
};
function TestLineSort(obj1) {
  console.log("Data = ")
  console.log(obj1)
  function compare( a, b ) {
    if ( a.alignNumber < b.alignNumber ){
      return -1;
    }
    if ( a.alignNumber > b.alignNumber ){
      return 1;
    }
    return 0;
  }
  Object.keys(obj1).map((value)=>{
    obj1[value].sort( compare )
  })

}

  function msToTime(seconds) {
 
    var minutes = parseInt(seconds / 60, 10);
    seconds = seconds % 60;
    var hours = parseInt(minutes / 60, 10);
    minutes = minutes % 60;
  
    return addZero(hours) + ':' + addZero(minutes);
  }

  const passOrderToPrint = () => {
    console.log("ToPrint value in Printing 220 :",toPrint)
    if(toPrint.AllowPrint.includes(false)){
      alert("UnPaid, UnPaid Report can't Print")
      return
    }
    if(toPrint.departNames.includes("MICROBIOLOGY") && toPrint.departNames.length!=1){
      alert("MicroBiology, One Report can be Printed at a Time.")
      return
    }
    if(toPrint.departNames.includes("HISTOPATHOLOGY") && toPrint.departNames.length!=1){
      alert("Histopathology, One Report can be Printed at a Time.")
      return
    }

    if(toPrint.departNames.includes("Special Pathology") && toPrint.natures.includes("ParaGraPH") && toPrint.natures.includes("RanGE")){
      alert("Special Pathology, One Report can be Printed at a Time.")
      return
    }
//In ToPrint All selected array that cannot b contain above value ^ above condition
//inVoiceId Array almost same  and auXid if 3 reports then 3 differen auXid Aarray send to API 
    var obj={
      ArrayInvoiceNo: toPrint.inVoiceID,
      ArrayAux_IDs: toPrint.auXiD,
      //for print Image
      ArrayGroupID:toPrint.groupIdPicDisp
      
    }
    console.log("Obj for Web_ReportsPrintingResultCalls "+JSON.stringify( obj))
    // fetch("https://reports.mpl-labs.pk:8443/4DACTION/_11_00_TestingAPI", {

    fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_ReportsPrintingResultsCalls", {
    // fetch("http://202.142.169.174:8090/4DACTION/Web_ReportsPrintingResultsCalls", {

      method:"POST",
      body:JSON.stringify(obj)
  }).then(res=>res.json()).then((response)=>{
    {console.log("Printing Response of API Results of Reports of 246 ",response)}
    svgString2Image(response[0].Barcode ,800, 600,'png',(Barcode)=>{
      
     var prevTestName=""
     var list=[]
     var counter=0
     var temp={}
     var comments=[]
     var speicmenDate=[]
     var specimenTime=[]
     var testLines={}
     // TestNature
     var testNature={}
     // Invoice Time
     var InvoiceTime=[]
     // Result
     
     var commentsTest={}
     var Germs={}
     var DoctorSignature={}

     var rowCounter=0

      // Result Date & Time
     var ResultDate=[]
     var ResultTime=[]
     var month= ["JAN","FEB","MAR","APR","MAY","JUN","JULY","AUG","SEP","OCT","NOV","DEC"]
     //Department
     var department=""
     var GermCounter=0
     var HistoFlag=false
     // COVID-19 Flag
     var Covid19Flag=false

     // COVID-19 AntiGen
     var Covid19AntiGen=false

     // Department Fexible
     var FexibleDeptName=""

     // MachineName
     var Machine={}
     var MachineComments={}

     // Microbiology
     var Microbiology={}

     //Hematology
     var Hematology={}

     // Histopathology
     var Histopathology={}

     // Other
     var Other={}

     // COVID-19 RECORD
     var covid_record={}

     // Object Sample Date & Time
     var ObjSampleDate=""
     var ObjSampleTime=""

     // Object Result Date & Time
     var ObjResultDate=""
     var ObjResultTime="" 
//month ki array hai opr jis me sary month hn getMonth number de deta hai aur us array se wo month show ho rha hai
     var TodayDate=new Date().getDate()+"-"+(month[new Date().getMonth()])+"-"+new Date().getFullYear()
     var TodayTime= new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds()

     console.log("Printing check ArrMedicineGroupName in Api response 316 ",response[0].ArrMedicineGroupName)
     if(response[0].ArrMedicineGroupName!=""){
     //GermCounter initialze in value 0 and Germs initial by object Germs={}
     JSON.parse(response[0].ArrMedicineGroupName).map((value)=>{
      if( Germs[value]==undefined){
        Germs[value]=[{
          Medicine:JSON.parse(response[0].ArrMedicineName)[GermCounter],
          Result:JSON.parse(response[0].ArrMedicineGerm1)[GermCounter],
          Result2:JSON.parse(response[0].ArrMedicineGerm2)[GermCounter],
          Result3:JSON.parse(response[0].ArrMedicineGerm3)[GermCounter],
          Result4:JSON.parse(response[0].ArrMedicineGerm4)[GermCounter],
          Result5:JSON.parse(response[0].ArrMedicineGerm5)[GermCounter]
         }] 
         GermCounter++
         return
      }
       Germs[value].push(
         {
          Medicine:JSON.parse(response[0].ArrMedicineName)[GermCounter],
          Result:JSON.parse(response[0].ArrMedicineGerm1)[GermCounter],
          Result2:JSON.parse(response[0].ArrMedicineGerm2)[GermCounter],
          Result3:JSON.parse(response[0].ArrMedicineGerm3)[GermCounter],
          Result4:JSON.parse(response[0].ArrMedicineGerm4)[GermCounter],
          Result5:JSON.parse(response[0].ArrMedicineGerm5)[GermCounter]
         }
       )
       GermCounter++
   }
     )
  }
  // end of ArrMedicineGroupName that are update array of && beCovidlow condition if Covid then true flag of 
  //ArrayTestName   opr wali sari arrays ab fill hongi map se 250 t0 311
  JSON.parse(response[0].ArrayTestName).map((name)=>{
                  if(response[0].ArrayTestName.includes("Covid-19 RNA by PCR")){
                                                                                 Covid19Flag=true
                                                                                }
                  if(response[0].ArrayTestName.includes("COVID-19 Rapid Antigen Test (RAT)")){
                                                                                 Covid19AntiGen=true
                                                                                             }
 //temp is an object that are init in temp={} map
         if(temp[name]==undefined){
          //doctorSignature is init by doctorSignature={} map and if(temp[name]==undefined)
             DoctorSignature=JSON.parse(response[0].ArrayUserSignName)[counter]
          // Result 
            var result=JSON.parse(response[0].ArrayTestResult)[counter].split("\r")
          // console.log("result are in var result that are come to  ArrayTestResult map with cond 361",result)
          // Result Date & Time
          ResultDate.push(new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getFullYear())
          ResultTime.push(JSON.parse(response[0].ArrReportingTime)[counter])
         
          console.log(new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth())
          // Test Nature 
          testNature[name]=JSON.parse(response[0].ArrayTestNature)[counter]
                   if(JSON.parse(response[0].ArrayTestNature)[counter]=="ParaGraPH"){
                       HistoFlag=true
                      }
          
         
          // Ref Range arrayRefRange se aae jo table me ref rang hai 
          var refLines=JSON.parse(response[0].ArrayRefRange)[counter].split("\r")
         

          var commentsLines=JSON.parse(response[0].ArrayCommentsTest)[counter].split("\r")
          console.log("CommentsLine that are init by ArrayCommentsTest printing 379 map",commentsLines)

          testLines[name]={
            refLines:refLines.length,
            commentsLines:commentsLines.length
          }
          console.log("testLines that are init by reflines +commentsline printing 385 map if",testLines)
          // Machine
          var MachineName=JSON.parse(response[0].ArrayCommentsMachine)[counter]
          if(MachineName=="Cobas C111"){
            MachineName="Cobas C311"
          }
          Machine[name]=MachineName
          console.log("Machine Comments that are ini by ArrayCommentsMachine printing 392 map if",Machine )
          var eachMachineComment=JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").split("Please note:-")[1]
          if(eachMachineComment==undefined){
            eachMachineComment=""
          }
          MachineComments[name]=eachMachineComment==""? "": "Please note:- "+eachMachineComment

          //Comments
          if(JSON.parse(response[0].ArrayCommentsMachine)[counter]!="Manual"){
            commentsTest[name]=JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," ").split("Please note:-")[0]

            comments.push(JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," ").split("Please note:-")[0])
            
          }else{
            
            commentsTest[name]=JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," ")

            comments.push(JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "))
          }
          
          ObjSampleDate=new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getFullYear()
          ObjSampleTime=JSON.parse(response[0].ArraySampleTime)[counter]
          ObjResultDate=new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getFullYear()
          ObjResultTime=JSON.parse(response[0].ArrReportingTime)[counter]

          //Specimen
          speicmenDate.push(new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getFullYear())
          
          specimenTime.push(JSON.parse(response[0].ArraySampleTime)[counter])
          

          // Invoice Time 
          InvoiceTime.push(JSON.parse(response[0].ArrayInvoiceTime)[counter])
          //Department ID
          department=toPrint.department[rowCounter]
          // Department Name
          FexibleDeptName=toPrint.departNames[rowCounter]
          if(testNature[name]=="ParaGraPH"){
            FexibleDeptName="HISTOPATHOLOGY"
          }

          temp[name]=[
            {
              title:name,
              rowHeight:refLines.length,
              resultLines:result.length,
              test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
              result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              unit:JSON.parse(response[0].ArrayUnit)[counter],
              prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              space:true,
              alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
              subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
              TestAlignOrder:department,
              speicmenDate:JSON.parse(response[0].ArraySampleDate)[counter],
              specimenTime:JSON.parse(response[0].ArraySampleTime)[counter],
              reportDate:JSON.parse(response[0].ArrReportingDate)[counter],
              reportTime:JSON.parse(response[0].ArrReportingTime)[counter],
              //for groupId and title
              // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
              // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
            }
          ]
          console.log("Printing Temp={} array fill by the map if cond 455",temp)
          rowCounter++
          console.log("check for micro nature"+JSON.parse(response[0].ArrayTestNature)[counter])
          //15-june-2023 JSON.parse(response[0].ArrayTestNature)[counter]=="MicrobioLoGY" agr nature me micro aae ga to micro ki array fill ho aur micro ka format use ho otherwise wo Other me jae refrange wala format bn jae
          if(FexibleDeptName=="MICROBIOLOGY" &&JSON.parse(response[0].ArrayTestNature)[counter]=="MicrobioLoGY"){
            Microbiology[name]=[
              {
                title:name,
                rowHeight:refLines.length,
                resultLines:result.length,
                test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
                result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                unit:JSON.parse(response[0].ArrayUnit)[counter],
                prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                space:true,
                alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
                subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
                TestAlignOrder:department,
                speicmenDate:JSON.parse(response[0].ArraySampleDate)[counter],
                specimenTime:JSON.parse(response[0].ArraySampleTime)[counter],
                reportDate:JSON.parse(response[0].ArrReportingDate)[counter],
                reportTime:JSON.parse(response[0].ArrReportingTime)[counter],
                  //for groupId and title
                  // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
                  // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
         
              }
            ]
          }else if(FexibleDeptName=="HISTOPATHOLOGY"){
            Histopathology[name]=[
            {
              title:name,
              rowHeight:refLines.length,
              resultLines:result.length,
              test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
              result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              unit:JSON.parse(response[0].ArrayUnit)[counter],
              prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              space:true,
              alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
              subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
              TestAlignOrder:department,
              ActualDepartment:toPrint.departNames[rowCounter],
                //for groupId and title
                // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
                // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
            }
          ]
          }else if(FexibleDeptName=="HEMATOLOGY"){
            Hematology[name]=[
            {
              title:name,
              rowHeight:refLines.length,
              resultLines:result.length,
              test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
              result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              unit:JSON.parse(response[0].ArrayUnit)[counter],
              prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              space:true,
              alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
              subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
              TestAlignOrder:name.includes("Complete")? 1 : department,
              speicmenDate:new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getFullYear(),
              specimenTime:JSON.parse(response[0].ArraySampleTime)[counter],
              reportDate:new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getFullYear(),
              reportTime:JSON.parse(response[0].ArrReportingTime)[counter],
                //for groupId and title
                // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
                // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
            }
          ]
          console.log("Printing Hematology Array fill in Printing map if cond 523",Hematology)
          // YE WALI OTHER FILL HO RHI HAI 
          }else{
            Other[name]=[
            {
              title:name,
              
              rowHeight:refLines.length,
              resultLines:result.length,
              test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
              result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              unit:JSON.parse(response[0].ArrayUnit)[counter],
              prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              space:true,
              alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
              subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
              TestAlignOrder:department,
              MachineName:MachineName,
              speicmenDate:new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getFullYear(),
              specimenTime:JSON.parse(response[0].ArraySampleTime)[counter],
              reportDate:new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getFullYear(),
              reportTime:JSON.parse(response[0].ArrReportingTime)[counter],
                //for groupId and title
            //  newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
            //  newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
              // patientImage:"abcd",
              // patientImage:""
              // this is for patient image that are given by 4d in this patient image we can make pic in printing=>report by conditonal show on the base of this pic
             
              
            }
          ]
          console.log("Printing Other Array fill by map if cond 549 ")
          console.log(Other)
          }
          
         }else{
          // Result 
          var result=JSON.parse(response[0].ArrayTestResult)[counter].split("\r")

          // Ref Range
         
          var refLines=JSON.parse(response[0].ArrayRefRange)[counter].split("\r")
          testLines[name].refLines=testLines[name].refLines+refLines.length

          // Comments Lines
          var commentsLines=JSON.parse(response[0].ArrayCommentsTest)[counter].split("Please note:-")[0].split("\r")
          console.log(commentsLines)
          testLines[name].commentsLines=testLines[name].commentsLines+commentsLines.length

          // Test Nature
          testNature[name]=JSON.parse(response[0].ArrayTestNature)[counter]
          
          if(testNature[name]=="ParaGraPH"){
            FexibleDeptName="HISTOPATHOLOGY"
          }

          temp[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:department,
            //for groupId and title
            // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
            // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
    
          })
          if(FexibleDeptName=="MICROBIOLOGY"&&JSON.parse(response[0].ArrayTestNature)[counter]=="MicrobioLoGY"){
            Microbiology[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:department,
            //for groupId and title
            // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
            // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
    
          })
          }else if(FexibleDeptName=="HISTOPATHOLOGY" ){
            Histopathology[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:department,
            ActualDepartment:toPrint.departNames[rowCounter],
            //for groupId and title
            // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
            // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
    
          })
          }else if(FexibleDeptName=="HEMATOLOGY"){
            Hematology[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:name.includes("Complete")? 1 : department,
            speicmenDate:  ObjSampleDate,
            speicmenTime: ObjSampleTime,
            reportDate: ObjResultDate,
            reportTime: ObjResultTime,
            //for groupId and title
            // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
            // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
    
          })
          }else{
            Other[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:department,
            speicmenDate:  ObjSampleDate,
            speicmenTime: ObjSampleTime,
            reportDate: ObjResultDate,
            reportTime: ObjResultTime,
            //for groupId and title
            // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
            // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
           
    
          })
          }
        }
          
      counter++;

      })
     console.log(TestLineSort(Hematology))
     console.log(sortKeys(Hematology))
     

      console.log(temp)

      console.log("Microbiology")
      console.log(Microbiology)
      console.log("Histopathology")
      console.log(Histopathology)
      console.log("Hematology")
      console.log(Hematology)
      console.log("Other")
      console.log(Other)
      var Cobas311_411={}
      Object.keys(Other).map((key)=>{
        {console.log(key)}
        if(Other[key].length==1){
          if((Other[key][0].MachineName=="Cobas C311" || Other[key][0].MachineName=="Cobas e411") && key.includes(Other[key][0].test_name) ){
            Other[key][0].TestAlignOrder=8.5
            console.log("printing 698",Other[key][0].TestAlignOrder)
          }
          console.log("Comments")
          console.log(commentsTest[key])
          if(Other[key][0].MachineName=="Cobas C311" && (Other[key][0].result.includes("**") ||commentsTest[key].trim()=="" || commentsTest[key].trim().toLowerCase()=="sample received from outside." || commentsTest[key].trim().toLowerCase()=="sample received from outside laboratory.") && key.includes(Other[key][0].test_name)){
            if(Other["Cobas C311"]==undefined){
             
           Other[key][0].TestAlignOrder=8.5

           if(Other[key][0].result.includes("**")){
            Other[key][0].TestAlignOrder=8.7 
           }

              Other["Cobas C311"]=[...Other[key]]
              console.log(Other)
            }else{
              Other[key][0].TestAlignOrder=8.5
              if(Other[key][0].result.includes("**")){
                Other[key][0].TestAlignOrder=8.7
              }

              Other["Cobas C311"].push(...Other[key])
              console.log(Other)
            }

            console.log(Other)
            Machine["Cobas C311"]="Cobas C311"
            if(commentsTest["Cobas C311"]==undefined){
              
              commentsTest["Cobas C311"]=commentsTest[key]
              console.log(Other)
            }else{
            if(commentsTest["Cobas C311"].length<commentsTest[key].length){
              commentsTest["Cobas C311"]=commentsTest[key]
            }
            console.log(Other)
           }
            delete Other[key]
           
           }
           if(Other[key]!= undefined && Other[key][0].MachineName=="Cobas e411" && (Other[key][0].result.includes("**") || commentsTest[key].trim()=="" || commentsTest[key].trim().toLowerCase()=="sample received from outside." || commentsTest[key].trim().toLowerCase()=="sample received from outside laboratory.") && key.includes(Other[key][0].test_name)){
            
            if(Other["Cobas e411"]==undefined){
              Other[key][0].TestAlignOrder=8.5
              if(Other[key][0].result.includes("**")){
                Other[key][0].TestAlignOrder=8.7
               }
              Other["Cobas e411"]= [...Other[key]]
            console.log(Other)

            }else{
              Other[key][0].TestAlignOrder=8.5
              if(Other[key][0].result.includes("**")){
                Other[key][0].TestAlignOrder=8.7
               }

              Other["Cobas e411"].push(...Other[key])
            console.log(Other)

            }
           
            console.log(Other)
  
            Machine["Cobas e411"]="Cobas e411"
            if(commentsTest["Cobas e411"]==undefined){
              commentsTest["Cobas e411"]=commentsTest[key]
            console.log(Other)

            }else{
              if(commentsTest["Cobas e411"].length<commentsTest[key].length){
                commentsTest["Cobas e411"]=commentsTest[key]
              }
              console.log(Other)

            }

            delete Other[key]
          }
          
        }
      })

     console.log(TestLineSort(Other))
     console.log(sortKeys(Other))  
   var covid_antigen={}
      //jo b array fill ho gae hn wo sab ab BLOB me dal deni hai aur ye BLOB reportData ko pass kr deni hai 
     //departmernt wise array fill ho gae hn Hematology wali Hematolgy me etc and other wali other me Other me jo data hai wo ab list me hoga 
      var BLOB={
       barcode:Barcode,
       DoctorSignature:DoctorSignature,
       testLines:testLines,
       invoice_id:JSON.parse(response[0].ArrayInvoiceNo)[0],
       //  27-july-2023
       patient_id:rows[0].patientID,
       name:response[0].PatientName,
       age_gender:response[0].PatientAge,
       list:Other,
       Cobas311_411:Cobas311_411,
       covid_antigen:covid_antigen,
       Hematology:Hematology,
       Microbiology:Microbiology,
       Histopathology:Histopathology,
       Germs:Germs,
       HistoFlag:HistoFlag,
       MachineComments:MachineComments,
       TodayDate:TodayDate,
       TodayTime:TodayTime,
       InvoiceTime:InvoiceTime,
       InvoiceDate:toPrint.date,
       Machine:Machine,
       covid_record:covid_record,
       ReferenceNo:response[0].ReferenceNo,
       MB_Germ1:response[0].MB_Germ1,
       MB_Germ2:response[0].MB_Germ2,
       MB_Germ3:response[0].MB_Germ3,
       MB_Germ4:response[0].MB_Germ4,
       MB_Germ5:response[0].MB_Germ5,
       testNature:testNature,
       Ref_By:response[0].Ref_By,
       speicmenDate:speicmenDate,
       specimenTime:specimenTime,
       ResultDate:ResultDate,
       ResultTime:ResultTime,
       comments:commentsTest,
       user_id:"Najam",
       //for image print in pdf
       PatientImage:response[0].If_PicturePrintingTrue?"data:image/png;base64,"+response[0].PictureImageBase64:null ,    
      //  PatientImage:null,
      //for GroupTitle 

      GroupTitleNew:response[0].varGroupTitle!=undefined?response[0].varGroupTitle:null,
      // GroupTitleNew:"My new GroupT"

      // for new MR panel 3-JAN-2023 && 4-jan-2023 this cond add ==true && response[0].cnic!=""
      cnic:response[0].if_panelMR_cnic_father==true && response[0].cnic!=""? response[0].cnic:"null",
      // cnic:"37405-1287363-9",
      father_husband:response[0].if_panelMR_cnic_father==true && response[0].father_husband!=""?response[0].father_husband:"null"
      // father_husband:"Ashraf Mehmood"


      }
    console.log(BLOB)
    setReportVisibility(true)
//when thses array are fill now ReportVisibility true and Dialog (Modal display) 1632
//*********************es se niche covid ki reports***************/
         setTimeout(()=>{
         if(Covid19Flag && !Covid19AntiGen){
    
         fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + toPrint.covidAux).then(res => res.json()).then((covid) => {
         console.log("Printing Covid Api response 831 :",covid)
          svgString2Image(covid[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
          svgString2Image(covid[0].barcode, 800, 600, 'png', (barcode) => {
          svgString2Image(covid[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
            var test_name = covid[0].test_name
            if (covid[0].test_name == "") {
              test_name = "Covid-19 RNA by PCR"
            }
            var cnic = covid[0].cnic + ""
            var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")

            covid_record = {
              barcode:barcode,
              StringDOB:covid[0].StringDOB,
              invoice_id:covid[0].invoice_id,
              QR_Code_Online:QR_Code_Online,
              Base64_PicturePatient:"data:image/png;base64,"+covid[0].Base64_PicturePatient,
              Base64_ImageSampleTaking:"data:image/png;base64,"+covid[0].Base64_ImageSampleTaking,

              equipment_used_1:covid[0].equipment_used_1,
              equipment_used_2:covid[0].equipment_used_2,
              regent_kits_1:covid[0].regent_kits_1,
              regent_kits_2:covid[0].regent_kits_2,
              batch_number_1:covid[0].batch_number_1,
              batch_number_2:covid[0].batch_number_2,

              QR_Code_Offline:QR_Code_Offline,
              Airline:covid[0].air_line,
              country:covid[0].country,
              dob:covid[0].dob,
              flight_no:covid[0].flight_no,
              flight_date:covid[0].flight_date,
              final_time:covid[0].final_time,
              ticket_no:covid[0].ticket_no,
              e_mail:covid[0].e_mail,
              contact:covid[0].PatientMobile,
              name:covid[0].name,
              passport:covid[0].passport,
              ShouldSampleDateTimePrint:covid[0].ShouldSampleDateTimePrint,
              title:test_name,
              age_gender:covid[0].age_gender,
              specimen_date:covid[0].specimen_date,
              specimen_time:covid[0].specimen_Time,
              cnic:validated_cnic,
              list:[{
                title:test_name,
                reports:[{
                  result:covid[0].result,
                  test_name:test_name,
                  comments:"",
                  ref_range:"",
                  prev1: covid[0].prev_result_1.replace('\r','\n'),
                  prev2: covid[0].prev_result_2.replace('\r','\n'),
                  specimen_date:covid[0].specimen_date,
                  result_date:covid[0].result_date,
                  specimen_time:covid[0].specimen_Time,
                }],
                id:covid[0].login_id,
                password:covid[0].Password,
                e_mail:covid[0].e_mail,
                contact:response[0].PatientMobile,
                Airline:covid[0].air_line,
              }],
              toAddress:covid[0].toAddress,
              result_date:covid[0].result_date,
              branch_address:covid[0].branch_address,
              result_time:covid[0].result_time,
              user_id:covid[0].User_Name.toUpperCase()
            
            }
            BLOB.covid_record=covid_record
            console.log("Printing Covid-19 RNA by PCR data set in reportData 902 : ", BLOB)
            setReportdata(BLOB)
         })
          })
          })
          })
          }
         else if(Covid19AntiGen && !Covid19Flag){
    
          fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + toPrint.covidAntiGen).then(res => res.json()).then((covid) => {
  
          svgString2Image(covid[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
          svgString2Image(covid[0].barcode, 800, 600, 'png', (barcode) => {
            svgString2Image(covid[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
              var test_name = covid[0].test_name
              if (covid[0].test_name == "") {
                test_name = "Covid-19 RNA by PCR"
              }
              var cnic = covid[0].cnic + ""
              var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")
  
              covid_antigen = {
                barcode:barcode,
                StringDOB:covid[0].StringDOB,
                invoice_id:covid[0].invoice_id,
                Base64_PicturePatient:"data:image/png;base64,"+covid[0].Base64_PicturePatient,
                Base64_ImageSampleTaking:"data:image/png;base64,"+covid[0].Base64_ImageSampleTaking,
                QR_Code_Online:QR_Code_Online,
                QR_Code_Offline:QR_Code_Offline,
                Airline:covid[0].air_line,
                country:covid[0].country,
                dob:covid[0].dob,
                flight_no:covid[0].flight_no,
                flight_date:covid[0].flight_date,
                final_time:covid[0].final_time,
                ticket_no:covid[0].ticket_no,
                e_mail:covid[0].e_mail,
                contact:response[0].PatientMobile,
                name:covid[0].name,
                passport:covid[0].passport,
                ShouldSampleDateTimePrint:covid[0].ShouldSampleDateTimePrint,
                title:test_name,
                age_gender:covid[0].age_gender,
                specimen_date:covid[0].specimen_date,
                specimen_time:covid[0].specimen_Time,
                cnic:validated_cnic,
                list:[{
                  title:test_name,
                  reports:[{
                    result:covid[0].result,
                    test_name:test_name,
                    comments:"",
                    ref_range:"",
                    prev1: covid[0].prev_result_1.replace('\r','\n'),
                  prev2: covid[0].prev_result_2.replace('\r','\n'),
                    specimen_date:covid[0].specimen_date,
                    result_date:covid[0].result_date,
                    specimen_time:covid[0].specimen_Time,
                  }],
                  id:covid[0].login_id,
                  password:covid[0].Password,
                  e_mail:covid[0].e_mail,
                  contact:response[0].PatientMobile,
                  Airline:covid[0].air_line,
                }],
                toAddress:covid[0].toAddress,
                branch_address:covid[0].branch_address,
                result_date:covid[0].result_date,
                result_time:covid[0].result_time,
                user_id:covid[0].User_Name.toUpperCase()
              
              }
              BLOB.covid_antigen=covid_antigen
              console.log(BLOB)
              setReportdata(BLOB)
          })
         })
         })
         })
         }
         else if(Covid19AntiGen && Covid19Flag){
    
          fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + toPrint.covidAntiGen).then(res => res.json()).then((covid) => {
    
          svgString2Image(covid[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
            svgString2Image(covid[0].barcode, 800, 600, 'png', (barcode) => {
              svgString2Image(covid[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                var test_name = covid[0].test_name
                if (covid[0].test_name == "") {
                  test_name = "Covid-19 RNA by PCR"
                }
                var cnic = covid[0].cnic + ""
                var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")
    
                covid_antigen = {
                  barcode:barcode,
                  StringDOB:covid[0].StringDOB,
                  invoice_id:covid[0].invoice_id,
                  Base64_PicturePatient:"data:image/png;base64,"+covid[0].Base64_PicturePatient,
                  Base64_ImageSampleTaking:"data:image/png;base64,"+covid[0].Base64_ImageSampleTaking,
                  QR_Code_Online:QR_Code_Online,
                  QR_Code_Offline:QR_Code_Offline,
                  Airline:covid[0].air_line,
                  country:covid[0].country,
                  dob:covid[0].dob,
                  flight_no:covid[0].flight_no,
                  flight_date:covid[0].flight_date,
                  final_time:covid[0].final_time,
                  ticket_no:covid[0].ticket_no,
                  e_mail:covid[0].e_mail,
                  contact:response[0].PatientMobile,
                  name:covid[0].name,
                  passport:covid[0].passport,
                  ShouldSampleDateTimePrint:covid[0].ShouldSampleDateTimePrint,
                  title:test_name,
                  age_gender:covid[0].age_gender,
                  specimen_date:covid[0].specimen_date,
                  specimen_time:covid[0].specimen_Time,
                  cnic:validated_cnic,
                  list:[{
                    title:test_name,
                    reports:[{
                      result:covid[0].result,
                      test_name:test_name,
                      comments:"",
                      ref_range:"",
                      prev1: covid[0].prev_result_1.replace('\r','\n'),
                      prev2: covid[0].prev_result_2.replace('\r','\n'),
                      specimen_date:covid[0].specimen_date,
                      result_date:covid[0].result_date,
                      specimen_time:covid[0].specimen_Time,
                    }],
                    id:covid[0].login_id,
                    password:covid[0].Password,
                    e_mail:covid[0].e_mail,
                    contact:response[0].PatientMobile,
                    Airline:covid[0].air_line,
                  }],
                  toAddress:covid[0].toAddress,
                  branch_address:covid[0].branch_address,
                  result_date:covid[0].result_date,
                  result_time:covid[0].result_time,
                  user_id:covid[0].User_Name.toUpperCase()
                
                }
                BLOB.covid_antigen=covid_antigen
              
                fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + toPrint.covidAux).then(res => res.json()).then((covid) => {
    
          svgString2Image(covid[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
            svgString2Image(covid[0].barcode, 800, 600, 'png', (barcode) => {
              svgString2Image(covid[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                var test_name = covid[0].test_name
                if (covid[0].test_name == "") {
                  test_name = "Covid-19 RNA by PCR"
                }
                var cnic = covid[0].cnic + ""
                var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")
    
                covid_record = {
                  barcode:barcode,
                  StringDOB:covid[0].StringDOB,
                  invoice_id:covid[0].invoice_id,
                  Base64_PicturePatient:"data:image/png;base64,"+covid[0].Base64_PicturePatient,
                  Base64_ImageSampleTaking:"data:image/png;base64,"+covid[0].Base64_ImageSampleTaking,


              equipment_used_1:covid[0].equipment_used_1,
              equipment_used_2:covid[0].equipment_used_2,
              regent_kits_1:covid[0].regent_kits_1,
              regent_kits_2:covid[0].regent_kits_2,
              batch_number_1:covid[0].batch_number_1,
              batch_number_2:covid[0].batch_number_2,


                  QR_Code_Online:QR_Code_Online,
                  QR_Code_Offline:QR_Code_Offline,
                  Airline:covid[0].air_line,
                  country:covid[0].country,
                  dob:covid[0].dob,
                  flight_no:covid[0].flight_no,
                  flight_date:covid[0].flight_date,
                  final_time:covid[0].final_time,
                  ticket_no:covid[0].ticket_no,
                  e_mail:covid[0].e_mail,
                  contact:covid[0].PatientMobile,
                  name:covid[0].name,
                  passport:covid[0].passport,
                  ShouldSampleDateTimePrint:covid[0].ShouldSampleDateTimePrint,
                  title:test_name,
                  age_gender:covid[0].age_gender,
                  specimen_date:covid[0].specimen_date,
                  specimen_time:covid[0].specimen_Time,
                  cnic:validated_cnic,
                  list:[{
                    title:test_name,
                    reports:[{
                      result:covid[0].result,
                      test_name:test_name,
                      comments:"",
                      ref_range:"",
                      prev1: covid[0].prev_result_1.replace('\r','\n'),
                      prev2: covid[0].prev_result_2.replace('\r','\n'),
                      specimen_date:covid[0].specimen_date,
                      result_date:covid[0].result_date,
                      specimen_time:covid[0].specimen_Time,
                    }],
                    id:covid[0].login_id,
                    password:covid[0].Password,
                    e_mail:covid[0].e_mail,
                    contact:response[0].PatientMobile,
                    Airline:covid[0].air_line,
                  }],
                  toAddress:covid[0].toAddress,
                  branch_address:covid[0].branch_address,
                  result_date:covid[0].result_date,
                  result_time:covid[0].result_time,
                  user_id:covid[0].User_Name.toUpperCase()
                
                }
                BLOB.covid_record=covid_record
                console.log(BLOB)
                setReportdata(BLOB)
            })
          })
          })
          })
               
            })
          })
          })
          })
        }else{
        setReportdata(BLOB)
        console.log(BLOB)

      }
    
  
  
},800)

   })


  })
  .catch(error=>{
    alert("Web_ReportsPrintingResultsCalls"+ error)
  })
}
Date.prototype.ddmmyyy = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [
    (dd > 9 ? '' : '0') + dd,
    (mm > 9 ? '' : '0') + mm,
    this.getFullYear()
  ].join('/');
};

  var svgString2Image = (svgString, width, height, format, callback) => {
    // console.log("Printing Before convert svgStringImage 1161 : ",svgString)

    var svgStringFormat = window.atob(svgString)
    // console.log("Printing Decode svgStringFormat 1164 : ",svgStringFormat)
    var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // console.log("Printing temp svgStringFormat.split 1166 : ",temp)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // console.log("Printing svgData Encode image 1171 : ",svgData)
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/' + format);
      // pass png data URL to callbac
      // console.log("Printing After convert svgStringImage 1190 : ",pngData)
      callback(pngData)
    }; // end async
    image.src = svgData


  }

  return (

    <Paper className={classes.root}
      style={{
        flexDirection: "column",
        textAlign: "center"
      }}
    >
      <div style={{ width: "100%" , marginTop:"1em",}}>
          <form onSubmit={(e) => {
            e.preventDefault();
            search_patient()
          }} >
            {/* 25-jan to add new field for Search by refrnce no */}
            {/* <TextField
              id="outlined-required"
              label="Refrence No"
              value={RefNo}
              onChange={(e) => {
                setRefNo(e.target.value);
              }}
              variant="outlined"
            /> */}
            <TextField
              id="outlined-required"
              label="Patient ID"
              value={id}
              onChange={(e) => {
                setId(e.target.value);
              }}
              variant="outlined"
            />
            <input type="submit" style={{
              position: 'absolute',
              left: '-9999px'
            }} />
         
            <TextField
              id="outlined-required"
              label="Patient Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
            />
            <TextField
              id="outlined-required"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              label="Patient Mobile"
              variant="outlined"
            />
             <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" , marginLeft:"2em"}}
            onClick={() => {
              
              search_patient();
              
            }}
            className={classes.button}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em", marginLeft:"1em" }}
            onClick={() => {
              setId("");
              setMobile("");
              setName("");
            }}
            className={classes.button}
          >
            Clear
          </Button>
          


          </form>
          
        
        {
          id.trim()!="" ?
          <Button
          variant="contained"
          style={{ marginTop: "1em" , marginLeft:"2em", backgroundColor:"yellow"}}
          onClick={() => {
            var obj={
              patient_id:id,
              branch_id:parseInt(localStorage.getItem('branch_id')),
              panel_id:localStorage.getItem('panel_code')
            }
            
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortalCallingPatientInvoices", {
              method: "POST",
              body: JSON.stringify(obj),
            })
              .then((res) => res.json())
              .then((response)=>{
                var InvoiceBox=[]
                response.map((temp)=>{
                  var obj={
                    invoice_no:temp.invoice_no,
                    date:new Date(temp.invoice_date),
                    select:(<Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        setInvoiceID(temp.invoice_no)
                        setOpenInvoice(false)
                      }}
                    >
                      Select
                    </Button>)
                  }
                  InvoiceBox.push(obj)

                })
                const sortedData = InvoiceBox.sort((a, b) =>  new Date(b.date) - new Date(a.date));


                setSearch_table(sortedData)
               setOpenInvoice(true)
              })
              
          }}
          className={classes.button}
        >
        Call Invoices
        </Button>
        :
        null
          
        }
          </div>
      
      <div style={{
        flexDirection: "row",
        flex: 1,
        textAlign: 'center',
        padding: "2em"
      }}>
        
        <TextField
          required
          id="outlined-required"
          label="Invoice No"
          variant="outlined"
          value={invoiceID}
          onChange={(e) => {
            setRows([])
            setPrintiDicator(false)
            setInvoiceID(e.target.value)
          }}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            margin: '1em'
          }}
          onClick={() => {
           
            var obj = {
              invoice_no: invoiceID,
              branch_id:parseInt(localStorage.getItem('branch_id')),
              panel_id:localStorage.getItem('panel_code')
            }
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_ReportsPrintingListCallup", {
            // fetch("http://202.142.169.174:8090/4DACTION/Web_ReportsPrintingListCallup", {
              method: "POST",
              body: JSON.stringify(obj)
            }).then(res => res.json()).then((response) => {
              console.log("response Line 1341 Printing ",response[0])
              if (response[0].ArrayAuxID == "[]") {
                setInvalid(true)
                return
              }
            
              setInvalid(false)
              var data = response[0]

              var collectionBox = []

              var counter = 0

              var month= ["JAN","FEB","MAR","APR","MAY","JUN","JULY","AUG","SEP","OCT","NOV","DEC"]

              var counterData=0
              console.log("data ArrayAuxId Line 1357 Printing",data.ArrayAuxID)

              JSON.parse(data.ArrayAuxID).map((gRidRow) => {
                console.log("data ArrayAuxId with map Line 1360 Printing",gRidRow)
                
                if(JSON.parse(data.ArrayCallsBlobPDF)[counter]){
                  console.log("data ArrayCallsBlobPDF with JSON pars 1363 Printing",(data.ArrayCallsBlobPDF)[counter])
                  window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + JSON.parse(data.ArrayAuxID)[counter] )
                  counterData=1
                  return
                }
                var temp = {
                  AuxId: gRidRow,
                  patientID: JSON.parse(data.ArrayPatientID)[counter],
                  id: JSON.parse(data.ArrayInvoiceNo)[counter],
                  date: new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getDate() + "-" +(month[new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getMonth()])+ "-" + new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getFullYear(),
                  name: JSON.parse(data.ArrayPatientName)[counter],
                  mobile: JSON.parse(data.ArrayPatientMobile)[counter],
                  test_code: JSON.parse(data.ArrayTestCode)[counter],
                  test_name: JSON.parse(data.ArrayTestName)[counter],
                  department: JSON.parse(data.ArrayDepartmentID)[counter],
                  nature: JSON.parse(data.ArrayTestNature)[counter],
                  departmentName: JSON.parse(data.ArrayDepartmentName)[counter],
                  alloWprint:!JSON.parse(data.ArrayPrintingNOTAllow)[counter],
                  DeliveryStatus:JSON.parse(data.ArrayRepDeliverStatus)[counter] ? <Check color="green"/> : <Close color="error" />,
                 //for printing image
                  groupId: JSON.parse(data.ArrayGroupID)[counter], 
                  // picturePrinting:JSON.parse(data.ArrayIfPicturePrinting)[counter],
                }
                counter++
                collectionBox.push(temp)
                console.log("Collection  box value is 1385 printing",collectionBox)
              })
              if(counterData==0){
                console.log("Collection  box value is 1385################################ printing",collectionBox)
                setRows(collectionBox)
                setToprint({})
                setPrintiDicator(false)
              }else{

              }

            })

          }}
        >Search</Button>
          {rows.length!==0?
          <>
        
          <ReactWhatsapp  style={{border:"none",cursor:"pointer",alignSelf:"center",position:"absolute",background:"white"}} number={rows[0].mobile.replace("0","+92")} message="" >
           
            <WhatsAppIcon  size="large" style={{color:"green",height:50,width:50}}></WhatsAppIcon>
          </ReactWhatsapp>
          </>:<></>}
      </div>
      {
        Invalid ?
          <p style={{
            textAlign: "center",
            color: "red"
          }}>Invalid Invoice No</p>
          : null
      }
      <div style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-evenly"
      }}>
      {
        printIndicator ?
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<PrintIcon fontSize={"large"} />}
            onClick={() => {
              if(toPrint=={}){
                alert("Please Select Tests!!")
                return
              }
              passOrderToPrint()
            }}

          >Print Preview</Button>
          :
          null
      }
      {
        printIndicator ?
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<Check fontSize={"large"} />}
            onClick={() => {
              var aux_id=rows.map((row)=>(row.AuxId))
               fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebRepPrintDeliveryStatusUpdate",{
                method:"POST",
                'Content-Type':"application/json",
                body: JSON.stringify({
                  "aux_id":aux_id,
                  'user_id':localStorage.getItem("user_id")
                })
              }).then(res=>res.json()).then((res)=>{
                if(res.DeliveryUpdateStatus=="Successful"){
                  alert("Reports Successfully Delivered")
                  window.location.reload()
                }else{
                  alert("Sorry Failed!")

                }
              })

            }}

          >Deliver Reports</Button>
          :
          null
      }
      </div>

      <MaterialTable
        title="Report Printing"
        data={rows}
        columns={[
          { title: 'Invoice ID', field: 'id' },
          { title: 'Patient ID', field: 'patientID' },
          {title: 'Allow Print', field:"alloWprint", type:"boolean"},
          { title: 'Department', field: 'departmentName', cellStyle: {
            backgroundColor: '#039be5',
            color: '#FFF'
          } },
          { title: 'Date', field: 'date' },
          { title: 'Name', field: 'name' },
          { title: 'Mobile', field: 'mobile' },
          { title: 'Test Code', field: 'test_code' },
          { title: 'Test Name', field: 'test_name' },
          { title: 'Delivery Status', field: 'DeliveryStatus' }
          
        ]}
        options={{
          selection: true,
          paging:false,
          minBodyHeight:50,
          showSelectAllCheckbox:SelectionControl
        }}

        onSelectionChange={(orderToPrint) => {
                                              if (orderToPrint.length == 0) {
                                                    setPrintiDicator(false)
                                                     return
                                                                            }
                                                    setPrintiDicator(true)
          
          
          var inVoice=[]
          var AuXiD=[]
          var departments=[]
          var date=[]
          var covidAux=""
          var covidAntiGen=""
          var natures=[]
        
          var departNames=[]
          var AllowPrint=[]
           // for print image
           var groupIdfrPic=[]
           var picturePrintingfrPic=[]

          //all selected item map into this section that are in orderToPrint
          {console.log("Printing selected item for print is in  1512 ",orderToPrint)}
            orderToPrint.map((item)=>{
              AllowPrint.push(item.alloWprint)
              AuXiD.push(item.AuxId)
              inVoice.push(item.id+"")
              departments.push(item.department)
              date.push(item.date)
              natures.push(item.nature)
              departNames.push(item.departmentName)
              // for print image
               groupIdfrPic.push(item.groupId)
              // picturePrintingfrPic.push(item.picturePrinting)
              if(item.test_code=="COVIDPCR"){
                covidAux=item.AuxId
              }
              if(item.test_code=="COVAG"){
                covidAntiGen=item.AuxId
              }
            })
            {console.log("Printing AllowPrint Array after map 1575",AllowPrint)}
            {console.log("Printing AuXiD Array after map 1576",AuXiD)}
            {console.log("Printing Invoice Array after map 1577",inVoice)}
            {console.log("Printing Departments Array after map 1578",departments)}
            {console.log("Printing date Array after map 1579",date)}
            {console.log("Printing natures Array after map 1580",natures)}
            {console.log("Printing departNames Array after map 1581",departNames)}
            {console.log("Printing covidAux Array after map 1582",covidAux)}
            {console.log("Printing covidAntiGen Array after map 1583",covidAntiGen)}
            //for image print
             {console.log("Printing groupIds for print image Array after map 1585",groupIdfrPic)}
            // {console.log("Printing picture true or false fr print image Array after map 1586",picturePrintingfrPic)}
          
          setToprint({
            AllowPrint:AllowPrint,
            inVoiceID:inVoice,
            auXiD:AuXiD,
            natures:natures,
            department:departments,
            date:date,
            departNames:departNames,
            covidAux:covidAux,
            covidAntiGen:covidAntiGen,
            //for printing image
            groupIdPicDisp:groupIdfrPic,
            // picturePrintingForPrintImage:picturePrintingfrPic
          })
          {console.log("Printing setToPrint Array after map 1549",toPrint)}
          {console.log("#################################################################### 1551" )}
        }}
      />
      {/* Invoices Column */}
      <Modal
        open={openInvoice}
        onClose={()=>{
          setOpenInvoice(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
        <div style={{
         width:"70%",
         marginLeft:"15%",
         marginRight:"15%"
       }}>
          <MaterialTable
            columns={[
              { field: "invoice_no", title: "Invoice ID" },
              { field: "date", title: "Invoice Date", type:"date"},
              { field: "select", title: "Select" },
            ]}
            data={search_table}
            title={"Invoice Record"}
            options={{
              thirdSortClick: false,
              sorting:false
            }}
          />
          </div>
        </div>
      </Modal>
      
      {/* Patient Selection */}
     
       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
        <div style={{
         width:"70%",
         marginLeft:"15%",
         marginRight:"15%"
       }}>
          <MaterialTable
            columns={[
              { field: "Patient_ID", title: "ID" },
              { field: "ToAddress", title: "Title" },
              { field: "Patient_Name", title: "Name" },
              { field: "Age", title: "Age" },
              { field: "MobileNo", title: "Mobile" },
              { field: "eMail", title: "Email" },
              { field: "cnic", title: "CNIC" },
              { field: "select", title: "Select" },
            ]}
            data={search_table}
            title={"Patient Record"}
          />
          </div>
        </div>
      </Modal>
      
       <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={reportVisibility}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
         setReportdata({
          reports: []
         })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h1 className={classes.modalTitle}><b>Report</b></h1>
        </DialogTitle>
        {console.log("Printing Dialog box report data in 1660",reportData)}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            reportData.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer >
          
                    <PDFViewer width="1000" height="1000" className="app" >
                      <Report invoice={reportData} />
                    </PDFViewer>
         
                 
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setReportVisibility(false)
              setReportdata({
                reports: []
               })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
        {/* <DialogActions className={classes.modalFooter}>
      
        <PDFDownloadLink document={<Report/>} fileName="xyz.pdf">
    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
</PDFDownloadLink>
</DialogActions> */}
      </Dialog>
    </Paper>
  )
}
