import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'black',
        borderBottomWidth: 0.5,
        borderTopWidth: 0.5,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        alignItems: 'center',
        height: 16,
        fontFamily:'Helvetica',
        fontSize:7,
        color:'white',
        textAlign: 'center',
        flexGrow: 1,

    },
    description: {
        width: '20%',
        fontSize:7,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
    qty: {
        width:  "25%",
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:7,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
    rate: {
        width: '20%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        paddingTop:3,
        fontSize:7,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
    unit: {
        width: '10%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        paddingTop:3,
        height:"100%",
        fontSize:7,
        borderWidth:1,
        borderColor:"white"
    },
    prev1: {
        width: '10%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:7,
        paddingTop:3,
        borderWidth:1,
        height:"100%",
        borderColor:"white"
    },
    prev2: {
        width: '10%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        fontSize:7,
        paddingTop:3,
        height:"100%",
        borderWidth:1,
        borderColor:"white"
    },
  });

  const InvoiceTableHeader = ({data,title}) => {
     
    return <View style={styles.container}>
        <Text style={{
                    width:  data.title=="Hormonal Profile for Female" ? '15%' : '20%',
                    fontSize:7,
                    paddingTop:3,
                    height:"100%",
                    borderWidth:1,
                    borderColor:"white"
        }}>Test Name</Text>
  <Text style={{
     width:  data.title=="Hormonal Profile for Female" ? '20%' : '25%',
     borderLeftColor: borderColor,
     borderLeftWidth: 0.5,
     fontSize:7,
     paddingTop:3,
     height:"100%",
     borderWidth:1,
     borderColor:"white"
  }}>Result (<Text style={{fontSize:6}}>{data.reportDate}@{data.reportTime}</Text>)</Text>
  <Text style={

    title=="Glucose tolerance test with 75 gm Glucose in Pregnancy" 

  ? {
    width:  '25%',
    borderLeftColor: borderColor,
    borderLeftWidth: 0.5,
    paddingTop:3,
    fontSize:7,
    height:"100%",
    borderWidth:1,
    borderColor:"white"
  }
  :
  {
    width: data.title=="Hormonal Profile for Female" ? '25%' : '15%',
    borderLeftColor: borderColor,
    borderLeftWidth: 0.5,
    paddingTop:3,
    fontSize:7,
    height:"100%",
    borderWidth:1,
    borderColor:"white"
  }
  }>{title=="Glucose tolerance test with 75 gm Glucose in Pregnancy" ? "Threshold for Diagnosis of Gestational Diabetes" : "Ref Range"}</Text>
        <Text style={styles.unit}>Unit</Text>
        <Text style={
           title=="Glucose tolerance test with 75 gm Glucose in Pregnancy" 

           ? {
                width: '10%',
                borderLeftColor: borderColor,
                borderLeftWidth: 0.5,
                fontSize:7,
                paddingTop:3,
                borderWidth:1,
                height:"100%",
                borderColor:"white"
            }
            :
            {
                width: '15%',
                borderLeftColor: borderColor,
                borderLeftWidth: 0.5,
                fontSize:7,
                paddingTop:3,
                borderWidth:1,
                height:"100%",
                borderColor:"white"
            }
        }>Previous 1</Text>
        <Text style={
            title=="Glucose tolerance test with 75 gm Glucose in Pregnancy" 

            ? {
                 width: '10%',
                 borderLeftColor: borderColor,
                 borderLeftWidth: 0.5,
                 fontSize:7,
                 paddingTop:3,
                 borderWidth:1,
                 height:"100%",
                 borderColor:"white"
             }
             :
             {
                 width: '15%',
                 borderLeftColor: borderColor,
                 borderLeftWidth: 0.5,
                 fontSize:7,
                 paddingTop:3,
                 borderWidth:1,
                 height:"100%",
                 borderColor:"white"
             }
        }>Previous 2</Text>
  
    </View>
  }
  
  
  export default InvoiceTableHeader