import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'

import logo from '../blank_header.png'

import footer from '../blank_footer.png'


Font.register({ family: 'Roboto Regular', src: require('../../fonts/Roboto-Regular.ttf'), });

Font.register({ family: 'Roboto Bold', src: require('../../fonts/Roboto-Bold.ttf') });
const styles = StyleSheet.create({
  page: {
    paddingTop: 150, paddingBottom: 50,
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  box: { width: '100%', marginBottom: 30, borderRadius: 5 },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
  qrcode: {
    width: 60,
    height: 60
  },
  logo: {
    width: 170,
    height: 90

  },
  barcode: {
    width: 150,
    height: 27,
    marginTop:13
  }
});


const CovidTemplate = ({ invoice }) => {
  {console.log("CovidTemplate data get in parameter 50 ",invoice)}

  return (
    <Page style={styles.page} size="A4" wrap
    >
        <Text style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 5,
                            width:"100%",
                            marginTop:10,
                            marginLeft:-15,
                            textAlign:"right",
                            marginBottom: 1,
                            position:"absolute"

                        }}>{"DOC No: MPL-GEN-F026 | Issue No: 01 | Issue Date: 04-01-2021"}</Text>

      <View fixed style={{
        width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
        paddingLeft: 20,
        alignItems: "center",
        fontFamily: "Roboto Regular",
        paddingRight: 20,
        height: 210,
        paddingTop: 10,
        // backgroundColor:"green"
       
      }} >
        <Image style={styles.logo} src={logo} />
        
{/* online image condition start in this line */}

        {

          (invoice.Airline.toLowerCase().includes('pegasus') || invoice.Airline.toLowerCase().includes('turkish')) && invoice.Base64_PicturePatient != "data:image/png;base64," ?
            <View style={{ marginLeft: 33, flexDirection: "column" }}>
              <View style={{
                margin: 'auto',
                width: 100,
                backgroundColor: '#F2F0F0',
                borderWidth: 1,
                position: "absolute",
                borderColor: 'black',
                marginTop: 20,
                marginLeft: 40
              }}>
                <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Roboto Bold', fontSize: 9 }}>MPL ID : {invoice.invoice_id}</Text>
              </View>

              <View

                style={{
                  marginLeft: 10,
                  marginTop: 10,
                  flexDirection: "row"
                }}
              >


                <Text style={{
                  marginTop: 40,
                  marginRight: -45,
                  fontSize: 9
                }}>Online</Text>
                <Image style={{
                  width: 70,
                  height: 70,
                  marginTop: 50,
                  marginRight: 10
                }} src={invoice.QR_Code_Online} />
                <Image style={{
                  width: 100,
                  height: 100,
                  marginTop: 50
                }} src={invoice.Base64_PicturePatient} />

              </View>

            </View>
            :
            invoice.Base64_ImageSampleTaking != "data:image/png;base64,"
              ?
              <View style={{ marginLeft: 33, flexDirection: "column" }}>
                {/* ye nazar aa rhi in this case */}
                <View style={{
                  margin: 'auto',
                  width: 100,
                  backgroundColor: '#F2F0F0',
                  borderWidth: 1,
                  position: "absolute",
                  borderColor: 'black',
                  marginTop: 20,
                  marginLeft: 40
                }}>
                  <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Roboto Bold', fontSize: 9 }}>MPL ID : {invoice.invoice_id}</Text>
                </View>

                <View

                  style={{
                    marginLeft: 10,
                    marginTop: 10,
                    flexDirection: "row"
                  }}
                >


                  <Text style={{
                    marginTop: 40,
                    marginRight: -45,
                    fontSize: 9
                  }}>Online</Text>
                  <Image style={{
                    width: 70,
                    height: 70,
                    marginTop: 50,
                    marginRight: 10
                  }} src={invoice.QR_Code_Online} />
                  <Image style={{
                    width: 100,
                    height: 100,
                    marginTop: 50
                  }} src={invoice.Base64_ImageSampleTaking} />

                </View>

              </View>
              :
              // 03-aug-2023 60 se 30 30 2 pe divide kr dya left tha phly
              <View style={{ marginLeft: 30,marginRight:30, flexDirection: "column" ,}}>
                <View style={{
                  margin: 'auto',
                  width: 100,
                  backgroundColor: '#F2F0F0',
                  borderWidth: 1,
                  position: "absolute",
                  borderColor: 'black',
                  marginTop: -30,
                  marginLeft: 20
                }}>
                  <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Roboto Bold', fontSize: 9 }}>MPL ID : {invoice.invoice_id}</Text>
                </View>
                <View

                  style={{
                    marginLeft: 40,
                    marginTop: 10
                  }}
                >

                  <Text style={{
                    paddingLeft: 20,
                    fontSize: 10,
                    marginTop: 10
                  }}>Online</Text>
                  <Image style={{
                    width: 70,
                    height: 70,
                    marginTop: -5
                  }} src={invoice.QR_Code_Online} />
                </View>

              </View>
        }

{/* online image condition end in this line */}

        <View style={{ marginLeft: (invoice.Airline.toLowerCase().includes('turkish') || invoice.Airline.toLowerCase().includes('pegasus')) && invoice.Base64_PicturePatient != "data:image/png;base64," ? 10 : invoice.Base64_ImageSampleTaking != "data:image/png;base64," ? 10 : 43, marginTop: 2 }}  >

          <Image style={styles.barcode} src={invoice.barcode} />

          <Text style={{
            fontFamily: 'Roboto Bold',
            fontSize: 11,
            marginTop: 5,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,
          }}>{invoice.toAddress + " " + invoice.name}</Text>
          <Text style={{

            paddingTop: '5em',
            fontSize: 9,
            fontFamily: 'Roboto Bold',
            paddingTop: 3,
            paddingBottom: 2,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,

          }}>{"Age/Gender    :  "}<Text style={{
            textAlign: 'center', fontFamily: 'Roboto Bold'
          }}>{invoice.age_gender}</Text></Text>
          <Text style={{

            paddingTop: '5em',
            fontFamily: 'Roboto Bold',
            fontSize: 9,
            paddingTop: 3,
            paddingBottom: 2,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,

          }}>{"Date of Birth   :  "}<Text style={{
            textAlign: 'center', fontFamily: 'Roboto Bold'
          }}>{invoice.StringDOB}</Text></Text>
          {/* 2-aug-2023 */}

           {invoice.cnic  ?
            <Text style={{

              paddingTop: '5em',
              fontFamily: 'Roboto Bold',
              fontSize: 9,
              paddingTop: 3,
              paddingBottom: 2,
              borderBottomColor: 'black',
              borderBottomWidth: 0.3,

            }}>{"CNIC #              :  "}<Text style={{
              textAlign: 'center', fontFamily: 'Roboto Bold'
            }}>{invoice.cnic}</Text></Text>
            : null
          }
          {
            invoice.passport != ""
              ?
              <Text style={{

                paddingTop: '5em',
                fontSize: 9,
                fontFamily: 'Roboto Bold',
                paddingTop: 3,
                paddingBottom: 2,
                borderBottomColor: 'black',
                borderBottomWidth: 0.3,

              }}>{"Passport No.  :  "}<Text style={{
                textAlign: 'center', fontFamily: 'Roboto Bold'
              }}>{invoice.passport}</Text></Text>
              :
              null
          }
          {
            (invoice.Airline.toLowerCase().includes('qatar') || invoice.Airline.toLowerCase().includes('pegasus') || invoice.Airline.toLowerCase().includes('turkish') || invoice.Airline.toLowerCase().includes('etihad'))
              ?
              <View>
                <Text style={{

                  paddingTop: '5em',
                  fontSize: 9,
                  fontFamily: 'Roboto Bold',
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Flight No.        :  "}<Text style={{
                  textAlign: 'center', fontFamily: 'Roboto Bold'
                }}>{invoice.flight_no}</Text></Text>
                <Text style={{

                  paddingTop: '5em',
                  fontSize: 9,
                  paddingTop: 3,
                  fontFamily: 'Roboto Bold',
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Flight Date      :  "}<Text style={{
                  textAlign: 'center', fontFamily: 'Roboto Bold'
                }}>{invoice.flight_date}</Text></Text>
                <Text style={{

                  paddingTop: '5em',
                  fontFamily: 'Roboto Bold',
                  fontSize: 9,
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Ticket No.       :  "}<Text style={{
                  textAlign: 'center', fontFamily: 'Roboto Bold'
                }}>{invoice.ticket_no}</Text></Text>
              </View>
              : null
          }
          {invoice.title == "Covid-19 RNA by PCR" ?
            <Text style={{

              paddingTop: '5em',
              fontFamily: 'Roboto Bold',
              fontSize: 9,
              paddingTop: 3,
              paddingBottom: 2,
              borderBottomColor: 'black',
              borderBottomWidth: 0.3,

            }}>{"CNIC #              :  "}<Text style={{
              textAlign: 'center', fontFamily: 'Roboto Bold'
            }}>{invoice.cnic}</Text></Text>
            : null
          }
          {
            invoice.title == "Covid-19 RNA by PCR" || invoice.ShouldSampleDateTimePrint
              ?
              <View>
                <Text style={{

                  paddingTop: '5em',
                  fontSize: 9,
                  fontFamily: 'Roboto Bold',
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Sample Date   :  "}<Text style={{
                  textAlign: 'center', fontFamily: 'Roboto Bold'
                }}>{invoice.specimen_date + " " + invoice.specimen_time}</Text></Text>



              </View>
              : null

          }
          <Text style={{

            paddingTop: '5em',
            fontSize: 9,
            fontFamily: 'Roboto Bold',
            paddingTop: 3,
            paddingBottom: 2,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,

          }}>{"Report Date    :  "}<Text style={{
            textAlign: 'center', fontFamily: 'Roboto Bold'
          }}>{invoice.result_date + " " + invoice.result_time}</Text></Text>


        </View>


      </View>
      <View style={{ width: '100%', height: '100%', marginTop: -40, paddingRight: 20, paddingLeft: 20 }} >

        <InvoiceItemsTable invoice={invoice}
        />
        <View style={{
          display: "flex",
          flex: 1,
          width: "100%",
          flexDirection: 'row',
          paddingRight: 5, paddingLeft: 30
        }}>

          <View
            style={{
              width: "35%",
              alignItems: "flex-start",
              marginTop: 10
            }}
          >
            <View style={{
              flexDirection: "column"
            }}>
              <View style={{ flexDirection: "column" }}>
                <Text style={{ fontFamily: 'Roboto Bold', fontSize: 8, color: (invoice.equipment_used_1 == "" && invoice.equipment_used_2 == "") ? "white" : "black" }}>Testing Site:</Text>
                <Text style={{ fontSize: 7, color: invoice.equipment_used_1 == "" ? "white" : "black" }}>{invoice.equipment_used_1}</Text>
                <Text style={{ fontSize: 7, color: invoice.equipment_used_2 == "" ? "white" : "black" }}>{invoice.equipment_used_2 == "" ? "--" : invoice.equipment_used_2}</Text>
              </View>
              <View style={{ flexDirection: "column", color: "white" }}>
                <Text style={{ fontFamily: 'Roboto Bold', fontSize: 8 }}>Testing Site:</Text>
                <Text style={{ fontSize: 7 }}>(1) Nucleic Acid Extraction : ROCHE Latest</Text>
                <Text style={{ fontSize: 7 }}>(1) RT PCR : Abbott Device</Text>
              </View>
              {
                invoice.Airline.toLowerCase().includes('pegasus') || invoice.Airline.toLowerCase().includes('turkish') ?

                  <View style={{
                    flexDirection: "column"
                  }}>
                    <View style={{ flexDirection: "column" }}>
                      <Text style={{ fontSize: 8 }}>e-Mail:</Text>
                      <Text style={{ fontFamily: 'Roboto Bold', fontSize: 8 }}>{invoice.list[0].e_mail}</Text>
                    </View>

                  </View>
                  :
                  null
              }
            </View>

          </View>
          <View
            style={{
              width: "35%",
              alignItems: "flex-start",
              marginTop: 10
            }}
          >
            <View style={{
              flexDirection: "column"
            }}>
              <View style={{ flexDirection: "column" }}>
                <Text style={{ fontFamily: 'Roboto Bold', fontSize: 8, color: (invoice.regent_kits_1 == "" && invoice.regent_kits_2 == "") ? "white" : "black" }}>Regent used:</Text>
                <Text style={{ fontSize: 7, color: invoice.regent_kits_1 == "" ? "white" : "black" }} >(i) Nucleic Acid Extraction : {invoice.regent_kits_1}</Text>
                <Text style={{ fontSize: 7, color: invoice.regent_kits_2 == "" ? "white" : "black" }}>(ii) RT PCR : {invoice.regent_kits_2}</Text>
              </View>

              <View style={{ flexDirection: "column" }}>
                <Text style={{ fontFamily: 'Roboto Bold', fontSize: 8, color: (invoice.batch_number_1 == "" && invoice.batch_number_2 == "") ? "white" : "black" }}>Batch Number:</Text>
                <Text style={{ fontSize: 7, color: invoice.batch_number_1 == "" ? "white" : "black" }} >(i) Nucleic Acid Extraction : {invoice.batch_number_1}</Text>
                <Text style={{ fontSize: 7, color: invoice.batch_number_2 == "" ? "white" : "black" }}>(ii) RT PCR : {invoice.batch_number_2}</Text>
              </View>
              {
                invoice.Airline.toLowerCase().includes('pegasus') || invoice.Airline.toLowerCase().includes('turkish') ?

                  <View style={{
                    flexDirection: "column"
                  }}>

                    <View style={{ flexDirection: "column" }}>
                      <Text style={{ fontSize: 8 }}>Contact #</Text>
                      <Text style={{ fontFamily: 'Roboto Bold', fontSize: 8 }} >{invoice.list[0].contact}</Text>
                    </View>
                  </View>
                  :
                  null
              }
            </View>

          </View>
          <View
            style={{
              width: "30%",
              marginTop: 5,
              flexDirection: 'row'
            }}
          >
            <View style={{
              width: "50%",
              alignItems: "flex-start"
            }}>

            </View>

            <View style={{
              width: "50%",
              alignItems: "center"
            }}>
              <Text style={{
              }}>Offline</Text>
              <Image style={styles.qrcode} src={invoice.QR_Code_Offline} />
            </View>
          </View>
        </View>

      </View>



      <View fixed style={{ position: 'absolute', width: '100%', bottom: 0, marginTop: -10 }}>

        <View style={{ alignItems: 'flex-end', marginRight: 20, marginBottom: 0 }}>


          <Text style={{ fontSize: 7 }}>User ID : {invoice.user_id}  </Text>
          <Text style={{ color: 'red', bottom: 0, right: 0, fontFamily: 'Roboto Bold' }}>This is a digitally verified Report and does not require any Signature</Text>

        </View>
        <View >
          <Image src={footer} style={{ height: 100 }} />
        </View>
      </View>
    </Page>
  )
}

export default CovidTemplate