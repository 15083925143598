import  firebase from 'firebase';
import  admin from 'firebase-admin';

export const Robo = firebase.initializeApp(
  {
  
        apiKey: "AIzaSyBb4oVovLTQPk_uNt0g8O9Bo5mwhQMdZcM",
        authDomain: "machines-9a031.firebaseapp.com",
        databaseURL: "https://machines-9a031-default-rtdb.firebaseio.com",
        projectId: "machines-9a031",
        storageBucket: "machines-9a031.appspot.com",
        messagingSenderId: "979223620450",
        appId: "1:979223620450:web:760a0f259aa935ed1c5a3b",
        measurementId: "G-YPCJYW6YB0"

}, 
  'Robo' // this name will be used to retrieve firebase instance. E.g. first.database();
);

export const HomeSampling = firebase.initializeApp(
  {
    authDomain: "metropole-laboratories.firebaseapp.com",
    databaseURL: "https://metropole-laboratories.firebaseio.com",
    projectId: "metropole-laboratories",
    storageBucket: "metropole-laboratories.appspot.com",
    messagingSenderId: "1023562329135",
    appId: "1:1023562329135:web:223c2cb0aa5ce56db72ada",
    measurementId: "G-MGCRV9LPWH"
  }, 
  'HomeSampling' // this name will be used to retrieve firebase instance. E.g. second.database();
);


