import React, { useEffect } from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font,PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'

import logo from '../blank_header.png'
// import logo from "../../PrintingWithLogo/mpl-logo.png"

import footer from '../blank_footer.png'

import CovidPDF from '../Covid_PDF/CovidTemplate'
import Microbiology from '../Microbiology/Report'
import Histopathology from '../Histopathology/Report'
import Hematology from '../Hematology/Report'
import CovidAntiGen from '../Covid_AntiGen/CovidTemplate'
import PatientImgEx from "../../images/avatar.jpg"
import InvoiceTitleNewGroup from './InvoiceTitleNewGroup';
import InvoiceTitleNewGroupLine from './InvoiceTitleNewGroupLine';

Font.register({ family: 'Calibri Regular', src: require('../../fonts/Calibri Regular.ttf'), });
Font.registerHyphenationCallback(word => (
    [word]
  ));

Font.register({ family: 'Roboto', src: require('../../fonts/roboto-light-webfont.ttf'), });

const styles = StyleSheet.create({
    page: {
        paddingTop: 130,
        paddingBottom: 100,
        fontSize: 8,
        fontFamily: "Helvetica",
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    qrcode: {
        width: 60,
        height: 60
    },
    logo: {
        // 08-aug-2023
        // width: 200,
        width: 200,
        marginTop:10,

        height: 100,
        // marginTop:5
    },
    barcode: {
        width: 130,
        height: 30,
    }
});

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
const Report = ({ invoice }) => {
    const [numPages, setNumPages] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(null)
    const [showImage ,setshowImage]=React.useState(false)
    const [picInBase64,setpicInBase64]=React.useState("")
    var IncludeValue=""

//     useEffect(()=>{
// // myFunction()
//         // var PicShow =window.alert("Show pic on the Pdf ?");
//         // console.log(PicShow)
//     },[])
useEffect(() => {
    // Update the document title using the browser API
    // showImageHandler()
    console.log("group title length is "+invoice.GroupTitleNew.length)
  },[]);
    // function myFunction() {
    //     // window.confirm("Press a button!");
    //   }
    var title = Object.keys(invoice.list).map((key) => {
        console.log("Report.js vry first 68 line Key are return by Invoice.list(Other) ",key)
        return key
    })
   const showImageHandler=()=>{
       if( window.confirm("print picture ?"))
       {
       setshowImage(true)

      
        convertIntoBase()
     
      

    //    base64Convert(PatientImgEx)
    

    //    return true;
       }
       else
       setshowImage(false)
    }
    const convertIntoBase =()=>{
        console.log("Original Image",PatientImgEx)
        var splitImg=PatientImgEx
        var splitImage =splitImg.split("data:image/jpeg;base64,")
        console.log("Image with some value split ",splitImage[1])
        var Incode =window.btoa(splitImage[1])
        console.log("Incode Image",Incode)
        var Decode =window.atob(Incode) 
        console.log("Decode Image",Decode)
        IncludeValue ="data:image/svg+xml;base64,"+Decode
        console.log("Again Orignal Image",IncludeValue)
        setpicInBase64(IncludeValue)



       
        // var coverBack =window.atob(PatientImgEx)
       
        
        // console.log("decode ######################",coverBack)
    }
   var getBase64=(file, cb)=> {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
    
    <Document
        onLoadSuccess={({ numPages, totalPages }) => {
            setNumPages(numPages)
            setTotalPages(totalPages)
        }}
    >
        {console.log("Report Invoice data in  76",invoice)}
        {console.log("Reoert Invoice data Hematology in Report.js 77",(Object.keys(invoice.Hematology)).length)}
        {console.log("Report Invoice data List(OtherArray) in Report.js 78",(Object.keys(invoice.list)).length)}
        {/* Object.Keys krne se jo title hota hai wo aa jata hai  */}
        {Object.keys(invoice.Hematology).length>0 ? <Hematology invoice={invoice} /> :null}

        {
            Object.keys(invoice.covid_record).length > 0 && title.length == 1 || Object.keys(invoice.covid_antigen).length > 0 && title.length == 1
                ? 
                null    
                :
                (Object.keys(invoice.list).length > 0) ?
                    <Page
                        style={{
                            paddingTop: 135,
                            // 08-aug-2023
                            // paddingBottom: 140,
                            paddingBottom: 120,

                            fontSize: 8,
                            // 08-aug-2023
                            paddingLeft: 20,
                            // paddingLeft: 40,
                            paddingRight: 20,
                            lineHeight: 1.5
                        }}
                        size="A4"
                        wrap={true}
                    >
                        <Text fixed style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 5,
                            width:"100%",
                            marginTop:10,
                            // 08-aug-2023
                            // marginLeft:42,
                            marginLeft:30,

                            textAlign:"right",
                            marginBottom: 1,
                            position:"absolute"

                        }}>{"DOC No: MPL-GEN-F026 | Issue No: 01 | Issue Date: 04-01-2021"}</Text>
                        {/* all commint and patientimge!==null for show patient image in pdf */}
                        <View fixed style={{
                            //fixed is used for display in all pages
                            width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                            paddingLeft: 20,
                            alignItems: "center",
                            fontFamily: "Helvetica",
                            paddingRight: 20,
                            // 08-aug-2023 es ko dekh lena hai with logo me 15 wali ni lgi
                            paddingTop:invoice.PatientImage !==null? 15:10,
                            paddingBottom:5,

                            // paddingTop:10,
                            // backgroundColor:"red"
                        }} >
                            {/* <Image style={[styles.logo,{height:invoice.PatientImage !==null?90: 100,width:invoice.PatientImage !==null?150:200}]} src={logo} /> */}
                            <Image style={[styles.logo,{height: 100,width:200,marginTop:invoice.PatientImage !==null?-20:0}]} src={logo} />
                            
{/* <View style={{ marginLeft:invoice.PatientImage !==null?10: 25,marginTop:10, flexDirection: "column", alignItems: "center" }}> */}
                           {/* 08-aug-2023 marginLeft: 25, */}
                            <View style={{ marginLeft: 25,marginTop:invoice.PatientImage !==null?0:-5, flexDirection: "column", alignItems: "center" ,
                            // backgroundColor:"green"
                            }}>
                             
                                <Image style={[styles.barcode,{height:invoice.PatientImage !==null?25:30}]} src={invoice.barcode} />
                            
                                <View style={{
                                    margin: 'auto', 
                                    // width: 80,
                                    width:invoice.PatientImage !==null?75:80, 
                                    backgroundColor: '#F2F0F0',
                                   // 27-july-2023
                                    // borderWidth: 1,
                                    borderWidth: 0.5,
                                    borderColor: 'black',
                                    // marginTop: 20
                                      // 27-july-2023 marginTop:8.5 change to 5 and 20 to 10, 

                                      marginTop:invoice.PatientImage !==null?5:10
                                    // marginTop:invoice.PatientImage !==null?8.5:20
                                }}>
                                    <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize:invoice.PatientImage !==null? 7.5:8.5,
                                // fontSize:8.5
                                }}>MPL ID : {invoice.invoice_id}</Text>
                                </View>
                                  {/* 27-july-2023 */}
                                  <View style={{
                                    
                                    margin: 'auto', 
                                    // width: 80,
                                    width:invoice.PatientImage !==null?75:80, 
            
                                    marginTop:invoice.PatientImage !==null?2:5
                                }}>
                                <Text style={{  textAlign: 'center', fontSize:invoice.PatientImage !==null? 7.5:8.5,
                                // fontSize:8.5
                                }}>MR No : {invoice.patient_id}</Text>
                                </View>
                                {/* 27-july-2023 marginTop:8.5 change, */}
                                {invoice.PatientImage !==null  ? <View style={{height:65,width:75,marginTop:2,}}>
                        <Image height={800} width={200}  src={invoice.PatientImage} />

                            </View>:<></>}

                            </View>
                            
                          
                       
                            {/* <View style={{ marginLeft:invoice.PatientImage !==null?   10: 27.5, marginTop: 5 }}  > */}
                            {/* 08-aug-2023 27.5 */}
                            <View style={{  marginLeft: 32, marginTop:invoice.PatientImage !==null? -10:5 }}  >


                                <Text style={{
                                    marginLeft: '5em',
                                    width: "170em",
                                    fontFamily: 'Helvetica-Bold',
                                    fontSize: 9,
                                    marginTop: 5,
                                }}>{invoice.name}</Text>
                                {/*  for new MR panel 3-JAN-2023 */}
                                  {
                                    invoice.father_husband == "null"
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Father / Husband</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            <Text style={{ width: "45%" }}>{invoice.father_husband}</Text>
                                        </View>
                                }
                                {
                                    invoice.cnic == "null"
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>CNIC</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            <Text style={{ width: "45%" }}>{invoice.cnic}</Text>
                                        </View>
                                }
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderTopWidth: 0.3,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                   <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>{invoice.age_gender.split('|')[0]}</Text>
                                                <Text style={{ width: "10%" }}>:</Text>
                                                {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404|| invoice.invoice_id==1901385 ? */}
                    {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385||invoice.invoice_id==5801467 || invoice.invoice_id== "0401479" ||invoice.invoice_id==9701419||invoice.invoice_id==5801466 || invoice.invoice_id==5901519  ? */}
                    {/* {invoice.invoice_id==2101409 || invoice.invoice_id==2701404 ||invoice.invoice_id==1901385||invoice.invoice_id==5801467 || invoice.invoice_id== "0401479" ||invoice.invoice_id==9701419||invoice.invoice_id==5801466 || invoice.invoice_id==5901519 || invoice.invoice_id==7101439 ||invoice.invoice_id==4401512||invoice.invoice_id==9201456 || invoice.invoice_id== 9201457  ? */}
                     {/* {(invoice.age_gender.includes("6739")) ||(invoice.age_gender.includes("6740"))? this will apply for other than human because age is calculated from hazrat adam jab 6740 se b zyada ho gae to es me aur include dal den ge*/}
                  
                     {(invoice.age_gender.includes("6739")) ||(invoice.age_gender.includes("6740"))?                            
                                                 <Text style={{ width: "45%" }}>N/A</Text>: <Text style={{ width: "45%" }}>{invoice.age_gender.split('|')[1]}</Text>}
                                                {/* <Text style={{ width: "45%" }}>{invoice.age_gender.split('|')[1]}</Text> */}
                                                {/* <Text style={{ width: "45%" }}>{invoice.age_gender.split('|')[1]}</Text> */}
                                </View>
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Ref By</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice.Ref_By}</Text>
                                </View>

                                {
                                    invoice.ReferenceNo == ""
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Reference No</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            <Text style={{ width: "45%" }}>{invoice.ReferenceNo}</Text>
                                        </View>
                                }
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Specimen Date/Time</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice.speicmenDate[0] + " " + invoice.specimenTime[0]}</Text>
                                </View>

                                {/* <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Result Date/Time</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice.ResultDate[0] + " " + invoice.ResultTime[0]}</Text>
                                </View> */}


                            </View>
                            {  invoice.GroupTitleNew=="" ? null : <InvoiceTitleNewGroup title={invoice} />}


                        </View>
                        {/*17-apr-2023 length ko dynamic k lye line us hisab se lgy  */}
                        {  invoice.GroupTitleNew=="" ? null : <InvoiceTitleNewGroupLine title={invoice} 
                        // titleLength={34}
          // 17-apr-2023 groupLine ko dynamic kiya mainwebsite pe Report and Hematology me dala hai 

                        titleLength={invoice.GroupTitleNew.length}

                         />}

                         <InvoiceItemsTable invoice={invoice}
                            pageNumbers={numPages}
                            totalPage={totalPages}
                        />
                        <View fixed style={{ position: 'absolute', width: '100%', bottom: 0, }}>


                            <View style={{ marginTop: 0 }}>
{/* 24-july-2023 Top:10 li jga -15 kiya  */}


                                <Text fixed style={{ width: "100%", left: 0, paddingLeft: 50, position: "absolute", fontSize: 6,top:-15  }} render={({ pageNumber, totalPages }) => (
                                    "Page " + `${pageNumber} / ${totalPages}`
                                )} />
                                {/* <View style={{ alignItems: 'flex-end', marginRight: -40 }}>
                                    <Text style={{ fontSize: 6 }}>User ID : {capitalizeFirstLetter(invoice.DoctorSignature)} Printed on Date   {invoice.TodayDate} at {invoice.TodayTime}   Inv.Date: {invoice.InvoiceDate[0]}  Inv.Time {invoice.InvoiceTime[0]}</Text>
                                    <Text style={{ color: 'red', bottom: 0, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                                 
                                    {
                                        invoice.DoctorSignature.includes("Waqar")||invoice.DoctorSignature.includes("Najam1") ?
                                            <View style={{alignItems: 'flex-end'}}>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                            </View>
                                            :
                                            (
                                            invoice.DoctorSignature.includes("Amna")||invoice.DoctorSignature.includes("Najam2") ?
                                            
                                            <View style={{alignItems: 'flex-end'}}>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Dr. Amna Afzal"}</Text>
                                            <Text style={{bottom:0, right:0,fontSize:6, fontFamily:'Helvetica'}}>FCPS Microbiology</Text>
                                              <Text style={{bottom:0, right:0,fontSize:6, fontFamily:'Helvetica'}}>Consultant Microbiologist</Text>
                                              </View>
                                              :
                                            <View style={{alignItems: 'flex-end'}}>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{" "}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>

                                        </View>
                                            )

                                    }
                                </View> */}
                            </View>


                            <View>
                                {/* 08-aug-2023 ye b differnce tha jis ko cange ni kiya with logo wali se  */}
                                <Image src={footer} style={{ width: "110%", marginTop: 10, height: 80 }} />
                            </View>
                        </View>

                    </Page>
                    : null


        }
        
        {Object.keys(invoice.Microbiology).length > 0 ? <Microbiology invoice={invoice} /> : null}
        {Object.keys(invoice.Histopathology).length > 0 ? <Histopathology invoice={invoice} /> : null}

        {Object.keys(invoice.covid_record).length > 0?<CovidPDF invoice={invoice.covid_record} />:null}
        {Object.keys(invoice.covid_antigen).length > 0? <CovidAntiGen invoice={invoice.covid_antigen} /> :null }
    </Document>
    )
}

export default Report