import React, { useEffect } from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'
import Comments from './Comments'
import Specimen from './Specimen'
import Result from './Result'
import MedicineHeader from './MedicineHeader'
import CovidPDF from '../Covid_PDF/CovidTemplate'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import InvoiceTitleNewGroup from './InvoiceTitleNewGroup';
const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    fontFamily: "Helvetica",
    borderColor: 'black',
    // backgroundColor:"green"
  },
  subReportTitle: {
    color: 'black',
        fontSize: 9,
        fontFamily:'Helvetica-Bold',
        paddingRight:5,
        paddingTop:5,
        textAlign: 'left'
  },
  GermTitle: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  SusProfileTitle:{
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  MedicineGroupName:{
    color: 'black',
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  }
});


class InvoiceItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.invoice,
      line: 2,
      page: 1,
      CrossCheck: {},
      NextPage: 0,
      Garbage: [],
      counter: 0,
      page:0,
      DefaultComments:{
        "Cobas C311":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas C111":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas e411":"Method/Analyzer: Cobas e 411, fully automated Electrochemiluminescence immunoassay (ECLIA) based analyzer, from Roche Diagnostics, Switzerland.",
        // 11-july-2023 new machine add en dono me se koi aik extra hogi
      "IDS-iSYS": " Please note:- Test (s) are performed on state-of-the-art IDS-iSYS, fully automated specialty Chemiluminescent Immunoassay analyzer (CLIA), from Immunodiagnostic Systems Holdings PLC, UK.",
      "IDS - iSYS": " Please note:- Test (s) are performed on state-of-the-art IDS-iSYS, fully automated specialty Chemiluminescent Immunoassay analyzer (CLIA), from Immunodiagnostic Systems Holdings PLC, UK.",
        "Blue Diver Instrument":"Method/Analyzer: Blue Diver Instrument, automated Immunodot analyzer, from D-tek, Belgium.",
        "BC - 5000":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "BC - 5150":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Cobas U411":"Method/Analyzer: Cobas u 411, automated Urine analyzer, from Roche Diagnostics, Switzerland.",
        "Cube 30 Touch":"Method/Analyzer: Cube 30 TOUCH, Automatic instrument for ESR, from Diesse Diagnostica Senese, Italy.",
        "GeneXpert":"Method/Analyzer: GeneXpert ® PCR system by Cepheid, USA.",
        "BC6200":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Compact X, Behnk Elektronik":"Method/Analyzer: Thrombolyzer Compact X, , fully automated coagulation analyzer, from Behnk Elektronik GmbH & Co. Germany.",
        "FANhp UBT":"Method/Analyzer: FANhp, An automated Urea Breath Testing analyzer, from Fischer Analysen Instrumente (FAN), Germany.",
        "MiniCap Sebia":"Method/Analyzer: MINICAP FLEX PIERCING, fully automated Capillary Electrophoresis System, from Sebia, France.",
        "Chorus Trio":"Method/Analyzer: Chorus TRIO Instrument, automated ELISA based immunoassay analyzer, from Diesse Diagnostica Senese Spa, Italy.",
        "Manual":"",
        "OST":"",
        "Microlab 300":"",
        "Alegria Instrument":"Method/Analyzer: Alegria Instrument, automated ELISA based immunoassay analyzer, from Orgentec Diagnostika GmbH, Germany.",
        "HG SWIFT":"Method/Analyzer: HG Swift, A loop mediated iso thermal amplification (LAMP), from Hibergene, Ireland.",
        "VIDAS":"Method/Analyzer: Automated immunoassay analyzer (VIDAS) from Biomerieux SA, France."
      },
      Machine:"",
      singleTestCounter:0,
      MachineCommentsToShow:[],
      ShowMachineComments:true
    }
  }

  render() {

    return <View>
    {console.log("data pass to invoice Table Hematology->InvoiceItemtable whole invoice 98",(Object.keys(this.state.data)))}

    {console.log("data pass to invoice Table Hematology->InvoiceItemtable 100",(Object.keys(this.state.data.Hematology)))}
      {(Object.keys(this.state.data.Hematology).map((key) => {

      var shiftMargin = 0
      var totalTests = 0
      this.state.line = this.state.line + 2
    {console.log("InvoiceItemTable state.line Hematology->InvoiceItemtable ",this.state.line)}
      var subGroupTitle = {}

      this.state.data.Hematology[key].map((item) => {
        totalTests = totalTests + item.rowHeight
        {console.log("TotalTest that are calculated in Hemotology -> InvoiceItemTable 108",totalTests)}
      })

      var FirstBox = this.state.line + this.state.data.Hematology[key][0].rowHeight + 2
      {console.log("FirstBox  that are calculated in Hemotology -> InvoiceItemTable 112",FirstBox)}
      if (FirstBox > 28) {
        this.state.line = 0
        this.state.NextPage = 1


      } else {
        this.state.NextPage = 0


      }
      if (key.includes("Complete") && this.state.data.Hematology[key][0].prev1.length > 4) {
        this.state.line = this.state.line - 8
      }
      if (this.state.NextPage == 0) {

        this.state.Garbage.push(1)
      } else {
        this.state.Garbage = []
      }
      if (this.state.Garbage.length == 3) {
        this.state.NextPage = 1
        this.state.line = 0
      }

      if ((key.includes("Complete Blood Picture") || key.includes("Urine Routine Examination")) && this.state.counter > 0) {
        this.state.NextPage = 1
        this.state.line = 0
      }
      // Machine
    
      this.state.ShowMachineComments=false
      if(this.state.Machine!=this.state.data.Machine[key]){
        this.state.ShowMachineComments=true
        this.state.Machine=this.state.data.Machine[key]
        
      }
      console.log("Machine : ")
      console.log(this.state.data.Machine[key])
      var pasteMachineComm=this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]
      
      this.state.MachineCommentsToShow.push(pasteMachineComm)
   
      console.log(this.state.MachineCommentsToShow)
      // Germ Counter
      var GermCounter=0
      console.log(key)
      console.log(shiftMargin)
      console.log(this.state.NextPage)
      console.log(this.state.counter)
      console.log(Object.keys(this.state.data.Germs).length)
      console.log("Comments Flag")
      console.log(this.state.ShowMachineComments)
      console.log(this.state.data.MachineComments[key])
      this.state.counter++

      // Test counter
      console.log("Test Hematology Length = ")
      console.log(this.state.data.Hematology[key].length)
      if(this.state.data.Hematology[key].length==1){
        this.state.singleTestCounter++
      }else{
        this.state.singleTestCounter=0
      }
      
     

      return <View >
                
          {
               key.includes("Covid") ||  key.includes("COVID-19 Rapid Antigen Test (RAT)")  ?
              <View>
               
              </View>
              :
              <View >
               
                <View style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: "100%",
                      fontSize: 7,
                      paddingLeft: 10,
                      paddingRight: 10,
                    //  backgroundColor:"red",
                    // ( this.state.data.Hematology[key].prev1!=""||this.state.data.Hematology[key].prev2!="" )?-70 this cond apply for 6401418 31-jan-2023 2 pages pe aa rhi thi report 2nd page empty es lye -60 ki -70 kr dya
                    // 20-jun-2023
                    marginTop:key.includes("Complete") ? key.includes('Complete Blood Analysis with Peripheral Film/Smear') ? -40 :( this.state.data.Hematology[key].prev1!=""||this.state.data.Hematology[key].prev2!="" )?-70: -60 : -10
                  // marginTop:10  
                  }}
                    >
                 </View>
                 {/* for patient image this condition work */}
              <View style={[styles.tableContainer,{marginTop:this.state.data.PatientImage!=null? 60:this.state.data.GroupTitleNew!=null?45:10}]} >

                <View style={{
                  padding: 10,
                   // this marginTop:this.state.counter==2 will add for 8401331 report 2nd ICTMP ko opr krny k lye 8 dec sir najam otherwise ye ni tha phly
                   marginTop:this.state.counter==2?-40:0,

                }}>
                  {
                    this.state.counter > 1 ? <View break={true} key={key + "1"}></View> : null
                  }

 {/* for display new group title  3 nov 2022*/}
 {/* { ((this.state.data.Hematology[key].length==1 && (key==this.state.data.Hematology[key][0].test_name) ) && this.state.data.Hematology[key][0].newValueGroupTitle==null || key=="Cobas e411" || key=="Cobas C311" ) ? null : <InvoiceTitleNewGroup title={this.state.data.Hematology[key][0].newValueGroupTitle} />} */}
              {/* for display new group title on every page 5 nov 2022* */}
              {/* {  this.state.data.GroupTitleNew==null ? null : <InvoiceTitleNewGroup title={this.state.data.GroupTitleNew} />} */}
                 
                  {console.log("InvoiceTitle In hematology->invoiceTable 213",(this.state.data.Hematology[key][0].test_name))}
                   { ((this.state.data.Hematology[key].length==1 && (key==this.state.data.Hematology[key][0].test_name) )|| key=="Cobas e411" || key=="Cobas C311" ) ? null : <InvoiceTitle title={key} />}

                  {/* {
                    (this.state.data.Hematology[key][0].subGroupTitle != "" &&  this.state.data.Hematology[key][0].subGroupTitle != "-" && this.state.data.Hematology[key][0].subGroupTitle[this.state.data.Hematology[key][0].subGroupTitle] == undefined)
                      ?
                      null
                      :
                    
                     <InvoiceTableHeader data={this.state.data.Hematology[key][0]}  />
                   
                  } */}
                  {/* <View style={{
                    marginTop:key.includes("Complete") ? -10 :0
                  }}>

                  </View> */}
<InvoiceTableHeader data={this.state.data.Hematology[key][0]}  />


                  {
                    this.state.data.Hematology[key].map(item => {
                      {console.log("Hematology ->InvoiceItemTable map data 233",item)}
                      this.state.line = this.state.line + item.rowHeight
                      {console.log("Hematology ->InvoiceItemTable state Line 235",this.state.line)}
                      var showSubHeadingBoolean = false
                      console.log("Hematology ->InvoiceItemTable subGroupTitle 236", subGroupTitle[item.subGroupTitle])
                      if (item.subGroupTitle != "" && item.subGroupTitle != "-" && subGroupTitle[item.subGroupTitle] == undefined) {
                        showSubHeadingBoolean = true
                        subGroupTitle[item.subGroupTitle] = item.subGroupTitle
                        console.log("Hematology ->InvoiceItemTable subGroupTitle after initialization 241", subGroupTitle)
                        console.log("Sub Title")
                        console.log(subGroupTitle)
                        this.state.line = this.state.line + 2
                      }

                      var space = this.state.line > 28
                      if (this.state.line > 28) {
                        this.state.line = 0
                      }



                      return <View >
                          <View break={true} key={item.test_name + "1"}>

                         </View> 
                         {console.log("Hematology ->InvoiceItemTable showSubHeadingBoolean 258 ",showSubHeadingBoolean)}
                        {
                          showSubHeadingBoolean ?
                          <View>
                            <View style={{
                              flexDirection: 'row',
                              justifyContent:'left'
                            }} >
                              <Text style={styles.subReportTitle}>

                                {item.subGroupTitle}

                              </Text>
                              </View>
                              {/* 24-july-2023 this condioton apply */}

                              {/* <InvoiceTableHeader  data={this.state.data.Hematology[key][0]} /> */}
                         
                           </View>
                            :
                            <Text></Text>
                        }
                        <View style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: "100%",
                          fontSize: 7,
                          // 24-july-2023
        // marginTop: 0.5,

        marginTop: -0.5,
        // 27-july-2023
        // borderWidth: 0.5
        borderWidth: 0.5,
        // 27-july-2023
        borderColor:"#c5c5c5",
        borderStyle:"solid"
                        }} key={item.test_name}>

                          <Text style={{

                            // 27-july-2023
        // width: '20%',
        width: '22%',
                            textAlign: 'left',
                            paddingTop: 2,
                            paddingLeft: 8,
                            fontFamily: "Helvetica",
                            height: '100%',
                            textAlign: 'left',
                            fontSize: 7
                          }}>{item.test_name}</Text>
                          <Text style={{

                            width: '20%',
                            textAlign: 'center',
                           // 27-july-2023
                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            fontWeight: '',
                            fontSize: 8,
                            height: '100%',
                            paddingLeft: 8,
                            fontFamily: "Helvetica-Bold",
                            // 20-june-2023
                            padding:5


                          }}>{item.result}</Text>
                          <Text style={{

                           // 27-july-2023
        // width: '20%',
        width: '18%',
                            height: '100%',
                            textAlign: 'left',
                             // 27-july-2023
                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            fontFamily: "Helvetica",
                            fontSize: 7,
                            paddingLeft: 8,

                          }}>{item.ref_range=="" ? "-" : item.ref_range}</Text>
                          <Text style={{
                            height: '100%',
                            width: '10%',
                            textAlign: 'left',
                           // 27-july-2023

                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            fontFamily: "Helvetica",
                            fontSize: 7,
                            paddingLeft: 8,



                          }}>{item.unit==""? "-" : item.unit}</Text>

                          <Text style={{

                            width: '15%',
                            textAlign: 'left',
                            // 27-july-2023

                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            height: '100%',
                            fontFamily: "Helvetica",
                            fontSize:5.5,
                            paddingLeft: 5,


                          }}>{item.prev1=="" ? "-" : item.prev1}</Text>
                          <Text style={{

                            width: '15%',
                            height: '100%',
                            textAlign: 'left',
                          // 27-july-2023
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 0.5,
                            paddingTop: 2,
                            fontSize: 5.5,
                            fontFamily: "Helvetica",
                            textOverflow: 'auto',
                            paddingLeft: 8

                          }}>{item.prev2=="" ? "-": item.prev2}</Text>

                        </View>


                      </View>
                    })

                  }
                  {
                    this.state.line > 28 ? this.state.line = 0 : null
                  }
                  <View style={{
                    width: "100%",
                    paddingTop: 2,
                    //this line add
                    //  backgroundColor:"yellow"
                     }}>
                        {/*  this line add 8 dec 8401331 report share by sir najam */}
                      {this.state.data.comments[key].includes("Eosinophilia")?<></>:<View break={true} key={key + "2132187239"}><Text break={true} ></Text></View>}
                      {/* <View break={true} key={key + "2132187239"}><Text break={true} ></Text></View> */}
                  
                     <View>
                    {
                      key.includes("Complete") ?
                      <View style={{
                        paddingTop:2,
                        paddingLeft:5,

                        //20-june-2023
                         //27-july-2023
                        //  paddingRight:"60%",
                        
                         paddingRight:"1%",
                         //27-july-2023
                         marginTop:2,
                        // marginTop:5,
                        backgroundColor:"#eeeeee",
                        // backgroundColor:"red",
                        borderWidth:0.5,
                        // fontWeight:"bold",
                    color:"black",
                      // 20-june-2023
                        // fontFamily:"Roboto",
                        
                      }}>
                      <Text style={{
                         fontFamily: 'Helvetica-Bold',
                         fontSize: 8
                      }}>{"Pathologist Remarks"}</Text>
                      <View style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: "100%",
                          //this add
                          // fontSize:7,
                          fontSize:7,
                          // fontWeight:"bold",
                          // fontFamily:"Roboto",
                    color:"black",

                          // this add
                          // backgroundColor:"green"
                        }}
                        >
                        
                          {console.log("Table data complete and remark in the end of table InvoiceItemTable 409 ",this.state.data.comments[key])}
                          {
                            this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                              this.state.line = this.state.line + 1
                              var space = this.state.line > 28
                              if (data.length > 200) {
                                this.state.line = this.state.line + 1
                              }
    
                              if (data == "") {
                                return <View><Text>{""}</Text></View>
                              }
    
    
                              if (space) {
    
                                return <View style={{
                                  marginTop: 0.5,
                                  width: "100%",
                                  // add thses line extra jo phly ni thi 08-aug-2023
                                // paddingLeft:"55%"
                           // 20-june-2023
                           color:"black",
                           //  this line add 8 dec 8401331 report share by sir najam 460 wali b condition same esi purpose k lye agr yhi report to aisa ho
                             
                             paddingRight:this.state.data.comments[key].includes("Eosinophilia") && this.state.data.comments[key].includes("Serum CRP.") &&this.state.data.comments[key].includes("3. Follow up with serial")?"55%":"0%"
                                }}>
                                  <View>
                                    {/*Pathologist Remarks show on the nxt page   ye and 468 both condition same purpose k lye agr 8401331 jaisi hai to ye chalen */}
                                  {/* {(this.state.line==29&&this.state.data.comments[key].includes("Eosinophilia"))&&<Text style={{ */}
                                  {((this.state.line==29)&&(this.state.data.comments[key].includes("Eosinophilia"))&&(this.state.data.comments[key].includes("Neutrophilic"))&&(this.state.data.comments[key].includes("1. Serum CRP"))&&(this.state.data.comments[key].includes("Follow up"))&&(this.state.data.comments[key].includes("CBC")))&&<Text style={{
                                
                                  fontFamily: 'Helvetica-Bold',
                                  fontSize: 8
                               }}>{"Pathologist Remarks"}</Text>
                            
                              }
                                  </View>
                                  <Text style={{
                                    textAlign: "left",
                                    // this line add 8 dec 8401331 report share by sir najam
                          // paddingRight:this.state.data.comments[key].includes("Eosinophilia") && this.state.data.comments[key].includes("Serum CRP.") &&this.state.data.comments[key].includes("3. Follow up with serial")?"55%":"0%"
// ye line comment ki hai 08-aug-2023
                          // paddingRight:this.state.data.comments[key].includes("Eosinophilia")&& this.state.data.comments[key].includes("Serum CRP.") &&this.state.data.comments[key].includes("3. Follow up with serial")?"60%":"0%"

                                  }}>{data}</Text>
                                </View>
    
                              }
    
                              return <View style={{
                                marginTop: 0.5,
                                width: "100%",
                              }}>
                                <View></View>
                                <Text style={{
                                  textAlign: "left"
                                }}>{data}</Text>
                              </View>
                            })
                          }
             
                        </View>
                   
                     </View>
                     :
                   <View style={{
                      flexDirection: 'column',
                      alignItems: 'left',
                      width: "100%",
                      fontSize: 7 ,
                      paddingTop:this.state.data.comments[key].trim().length==0 ? 0 :10,
                      paddingLeft:this.state.data.comments[key].trim().length==0 ? 0 :10,
                      paddingRight:this.state.data.comments[key].trim().length==0 ? 0 :10,
                      
                      marginTop:this.state.data.comments[key].trim().length==0 ? 0 : 5,
                      paddingBottom:this.state.data.comments[key].trim().length==0 ? 0 :10,
                      marginBottom:this.state.data.comments[key].trim().length==0 ? 0 :1,
                      backgroundColor:this.state.data.comments[key].trim().length==0 ? "white" :"#eeeeee",
                      borderWidth:this.state.data.comments[key].trim().length==0 ? 0 :0.5,
 // 20-june-2023
                      // fontFamily:"Roboto"
                     
                color:"black",

                    }}
                    >
                      {
                        this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                          this.state.line = this.state.line + 1
                          var space = this.state.line > 28
                          if (data.length > 200) {
                            this.state.line = this.state.line + 1
                          }

                          if (data == "") {
                            return <View><Text>{""}</Text></View>
                          }


                          if (space) {

                            return <View style={{
                              marginTop: 1,
                              width: "100%",
                            }}>
                              <View></View>
                              <Text style={{
                                textAlign: "left"
                              }}>{data}</Text>
                            </View>

                          }

                          return <View style={{
                            marginTop: 1,
                            width: "100%"
                          }}>
                            <View></View>
                            <Text style={{
                              textAlign: "left"
                            }}>{data}</Text>
                          </View>
                        })
                      }
                 
                   
                 
                    </View> 
                    }
                    
                    <Text style={{ fontSize: 7 }}>{this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]}</Text>
               
                    </View> 
                    

                    </View>
                    </View>
                           


                    </View>
                 </View>
        }

      </View>


    }))}
      {/* 24-july-2023 this total view */}
      <View style={{ alignItems: 'flex-end',  }}>
                                   
                                   <Text style={{ fontSize: 6 ,bottom: -10,}}>User ID : {this.state.data.DoctorSignature} Printed on Date   {this.state.data.TodayDate} at {this.state.data.TodayTime}   Inv.Date: {this.state.data.InvoiceDate[0]}  Inv.Time {this.state.data.InvoiceTime[0]}</Text>
                                   <Text style={{ color: 'red', bottom: -8, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                   
                                   {
                                       this.state.data.DoctorSignature.includes("Waqar")||this.state.data.DoctorSignature.includes("Najam") ?
                                           <View style={{alignItems: 'flex-end'}}>
                                               <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                               <Text style={{ bottom: -4, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                               <Text style={{ bottom: -2, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                           </View>
                                           :
                                           <View style={{alignItems: 'flex-end'}}>
                                           <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{" "}</Text>
                                           <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>
                                           <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>

                                       </View>
                                   //     <View style={{alignItems: 'flex-end'}}>
                                   //     <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                   //     <Text style={{ bottom: -4, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                   //     <Text style={{ bottom: -2, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                   // </View>
                                   }

                               </View>
    </View>
    
  }
}



export default InvoiceItemsTable