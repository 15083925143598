import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'white'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: '#666666',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontFamily:'Helvetica-Bold',
        fontSize:6,
        color:'white',
        textAlign: 'center',
        flexGrow: 1,

    },
    sn: {
        width: '5%',
        height:"100%",
        marginTop:"7em"
    },
    test_title: {
        width: '25%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"7em"
    },
    reporting_date: {
        width: '25%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"7em"
    },
    fee: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"7em"
    },
    disc: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"7em"
    },
    net_value: {
        width: '15%',
        borderLeftColor: borderColor,
        borderLeftWidth: 0.5,
        height:"100%",
        
        marginTop:"7em"
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.sn}>S/N</Text>
        <Text style={styles.test_title}>Test Title</Text>
        <Text style={styles.reporting_date}>Reporting Date/Time</Text>
        <Text style={styles.fee}>Fee</Text>
        <Text style={styles.disc}>Disc</Text>
        <Text style={styles.net_value}>Net Value</Text>
  
    </View>
  );
  
  export default InvoiceTableHeader