import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'

import logo from '../mpl-logo.png'
import footer from '../mpl-footer.jpg'

import CovidPDF from '../Covid_PDF/CovidTemplate'
import CovidAntiGen from '../Covid_AntiGen/CovidTemplate'
import Microbiology from '../Microbiology/Report'
import Histopathology from '../Histopathology/Report'
import Hematology from '../Hematology/Report'

Font.register({ family: 'Calibri Regular', src: require('../../fonts/Calibri Regular.ttf'), });
Font.registerHyphenationCallback(word => (
    [word]
  ));
  
const styles = StyleSheet.create({
    page: {
        paddingTop: 130,
        paddingBottom: 100,
        fontSize: 8,
        fontFamily: "Helvetica",
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    qrcode: {
        width: 60,
        height: 60
    },
    logo: {
        width: 180,
        height: 100
    },
    barcode: {
        width: 130,
        height: 30,
    }
});

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const Report = ({ invoice }) => {
    const [numPages, setNumPages] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(null)
    var title = Object.keys(invoice.list).map((key) => {
        return key
    })

    return (<Document
        onLoadSuccess={({ numPages, totalPages }) => {
            setNumPages(numPages)
            setTotalPages(totalPages)
        }}
    >

        {Object.keys(invoice.Hematology).length > 0 ? <Hematology invoice={invoice} /> : null}

        {
           Object.keys(invoice.covid_record).length > 0 && title.length == 1 || Object.keys(invoice.covid_antigen).length > 0 && title.length == 1
            
                ? null
                :
                (Object.keys(invoice.list).length > 0) ?
                    <Page
                        style={{
                            paddingTop: 135,
                            paddingBottom: 140,
                            fontSize: 8,
                            paddingLeft: 20,
                            paddingRight: 20,
                            lineHeight: 1.5
                        }}
                        size="A4"
                        wrap={true}
                    >
                         <Text style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 5,
                            width:"100%",
                            marginTop:10,
                            marginLeft:30,
                            textAlign:"right",
                            marginBottom: 1,
                            position:"absolute"

                        }}>{"DOC No: MPL-GEN-F026 | Issue No: 01 | Issue Date: 04-01-2021"}</Text>
                        <View fixed style={{
                            width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
                            paddingLeft: 20,
                            alignItems: "center",
                            fontFamily: "Helvetica",
                            paddingRight: 20,
                            paddingTop: 10
                        }} >

                            <Image style={styles.logo} src={logo} />

                            <View style={{ marginLeft: 35, marginTop: -10, flexDirection: "column", alignItems: "center" }}>
                            
                                <Image style={styles.barcode} src={invoice.barcode} />
                                <View style={{
                                    margin: 'auto', width: 80,
                                    backgroundColor: '#F2F0F0',
                                    borderWidth: 1,
                                    borderColor: 'black',
                                    marginTop: 20
                                }}>
                                    <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 8.5 }}>MPL ID : {"XXXX"}</Text>
                                </View>


                            </View>
                            <View style={{ marginLeft: 50, marginTop: 5 }}  >


                                <Text style={{
                                    marginLeft: '5em',
                                    width: "170em",
                                    fontFamily: 'Helvetica-Bold',
                                    fontSize: 9,
                                    marginTop: 5,
                                }}>{"XXXX"}</Text>
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderTopWidth: 0.3,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Age/Gender</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{""}</Text>
                                </View>
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Ref By</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{""}</Text>
                                </View>

                                {
                                    invoice.ReferenceNo == ""
                                        ?
                                        <Text></Text>
                                        :
                                        <View style={{
                                            marginLeft: '5em',
                                            paddingTop: '5em',
                                            fontSize: 7,
                                            flexDirection: "row",
                                            width: "170em",
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderBottomColor: 'black',
                                            borderBottomWidth: 0.3,

                                        }}>
                                            <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Reference No</Text>
                                            <Text style={{ width: "10%" }}>:</Text>
                                            <Text style={{ width: "45%" }}>{""}</Text>
                                        </View>
                                }
                                <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold', }}>Specimen Date/Time</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{""}</Text>
                                </View>

                                {/* <View style={{
                                    marginLeft: '5em',
                                    paddingTop: '5em',
                                    fontSize: 7,
                                    flexDirection: "row",
                                    width: "170em",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottomColor: 'black',
                                    borderBottomWidth: 0.3,

                                }}>
                                    <Text style={{ width: "45%", fontFamily: 'Helvetica-Bold' }}>Result Date/Time</Text>
                                    <Text style={{ width: "10%" }}>:</Text>
                                    <Text style={{ width: "45%" }}>{invoice.ResultDate[0] + " " + invoice.ResultTime[0]}</Text>
                                </View> */}


                            </View>


                        </View>

                        <InvoiceItemsTable invoice={invoice}
                            pageNumbers={numPages}
                            totalPage={totalPages}
                        />
                        <View fixed style={{ position: 'absolute', width: '100%', bottom: 0, }}>


                            <View style={{ marginTop: 0 }}>

                                <Text style={{ width: "100%", left: 0, paddingLeft: 50, position: "absolute", fontSize: 6 }} render={({ pageNumber, totalPages }) => (
                                    "Page " + `${pageNumber} / ${totalPages}`
                                )} />
                                <View style={{ alignItems: 'flex-end', marginRight: -20 }}>
                                    <Text style={{ fontSize: 6 }}>User ID : {capitalizeFirstLetter(invoice.DoctorSignature)} Printed on Date   {invoice.TodayDate} at {invoice.TodayTime}   Inv.Date: {invoice.InvoiceDate[0]}  Inv.Time {invoice.InvoiceTime[0]}</Text>
                                    <Text style={{ color: 'red', bottom: 0, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                                    {
                                        invoice.DoctorSignature.includes("Waqar") ?
                                            <View style={{alignItems: 'flex-end'}}>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                            </View>
                                            :
                                            <View style={{alignItems: 'flex-end'}}>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{" "}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>

                                        </View>

                                    }
                                </View>
                            </View>


                            <View>
                                <Image src={footer} style={{ width: "108%", height: 100 }} />
                            </View>
                        </View>

                    </Page>
                    : null


        }

        {Object.keys(invoice.Microbiology).length > 0 ? <Microbiology invoice={invoice} /> : null}
        {Object.keys(invoice.Histopathology).length > 0 ? <Histopathology invoice={invoice} /> : null}

        {
            Object.keys(invoice.covid_record).length > 0
                ?
                <CovidPDF invoice={invoice.covid_record} />
                :
                null
        }

{
            Object.keys(invoice.covid_antigen).length > 0
                ?
                <CovidAntiGen invoice={invoice.covid_antigen} />
                :
                null
        }
    </Document>
    )
}

export default Report