import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';


import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
import Phelebotmy from './Phelebotmy'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const columns =
  [{ id: 'invoice_no', label: 'Invoice ID' },
  { id: 'patient_name', label: 'Patient Name' },
  { id: 'mobile_no', label: 'Mobile No' },
  { id: "date", label: "Date" },
  { id: 'remarks', label: 'Remarks' },
  { id: 'view', label: 'Action' }
  ]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

export default function PhelebotomyCallUp() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')
  date.setDate(date.getDate() - 1);


  const [selected, setSelected] = React.useState({
    reports: []
  })

  const [patient_invoice, setPatientInvoice] = React.useState(false)


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(false)

  const [invoice_no, setInvoiceNo] = React.useState("")

  const [to, setTo] = React.useState(new Date())
  var date_from = new Date()
  date_from.setDate(new Date().getDate() - 1)
  const [from, setFrom] = React.useState(date_from)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [OpenPhelebotomy, setOpenPhelebotomy] = React.useState(false)

  const [state, setState] = React.useState({
    status: 'All',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const BoxClose=()=>{
    setOpenPhelebotomy(false)
   
 
                var obj = {
                  branch_id: parseInt(localStorage.getItem('branch_id')),
                  date_start: from.toISOString(),
                  date_end: to.toISOString()
                }
                fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_PhlebotomyCallingUpInvoices", {
                  method: "POST",
                  body: JSON.stringify(obj)
                }).then(res => res.json()).then((response) => {
                  var the_items = []
                  response.map((items) => {
                    var obj1 = {
                      date: new Date(items.invoice_date).getDate() + "/" + (new Date(items.invoice_date).getMonth() + 1) + "/" + (new Date(items.invoice_date).getFullYear()),
                      mobile_no: items.mobile_no,
                      patient_name: items.patient_name,
                      remarks: items.remarks,
                      invoice_no: items.invoice_no,
                      view:
                        <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
                          setInvoiceNo(items.invoice_no)
                          setOpenPhelebotomy(true)
                        }}>
                          <  ReceiptIcon />
                        </Button>
 

                    }
                    the_items.push(obj1)
                  })
                  setRows(the_items)
                  setOrg_Rows(the_items)
               
                })
  }
  useEffect(() => {
    if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk"
    }
    if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk/login"
    }
    if(localStorage.getItem('branch_id')=="0"){
      alert("Please Select Branch!!")
      window.location.href="https://invoicing.mpl-labs.pk"
    }
    var obj = {
      branch_id: parseInt(localStorage.getItem('branch_id')),
      date_start: from.toISOString(),
      date_end: to.toISOString()
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_PhlebotomyCallingUpInvoices", {
      method: "POST",
      body: JSON.stringify(obj)
    }).then(res => res.json()).then((response) => {
      var the_items = []
      console.log(response)
      response.map((items) => {
        var obj1 = {
          date: new Date(items.invoice_date).getDate() + "/" + (new Date(items.invoice_date).getMonth() + 1) + "/" + (new Date(items.invoice_date).getFullYear()),
          mobile_no: items.mobile_no,
          patient_name: <b>{items.patient_name}</b>,
          remarks: items.remarks,
          invoice_no: items.invoice_no,
          view: 
            <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
              setInvoiceNo(items.invoice_no)
              setOpenPhelebotomy(true)
            }}>
              <  ReceiptIcon />
            </Button>


        }
        the_items.push(obj1)
      })
      setRows(the_items)
    })


  }, [])

  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <>
      <Paper className={classes.root}>


        <GridContainer style={{
          padding: '1em'
        }}>

          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                ampm={false}
                minDate={new Date().setMonth(new Date().getMonth() - 1)}
                format="dd/MM/yyyy"
                value={from}
                maxDate={to}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => {
                  setFrom(date)
                }}
              />
            </MuiPickersUtilsProvider>
          </GridItem>


          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                inputVariant="outlined"
                label="To"
                ampm={false}
                format="dd/MM/yyyy"
                maxDate={new Date()}
                value={to}
                minDate={from}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setTo(date)}
              />
            </MuiPickersUtilsProvider>

          </GridItem>
          <GridItem xs={6} sm={6} md={2} lg={2}>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginTop: "1em" }}
              onClick={() => {
              
                var obj = {
                  branch_id: parseInt(localStorage.getItem('branch_id')),
                  date_start: from.toISOString(),
                  date_end: to.toISOString()
                }
            
                fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_PhlebotomyCallingUpInvoices", {
                  method: "POST",
                  body: JSON.stringify(obj)
                }).then(res => res.json()).then((response) => {
                  var the_items = []
                  console.log(response)
                  response.map((items) => {
                    var obj1 = {
                      date: new Date(items.invoice_date).getDate() + "/" + (new Date(items.invoice_date).getMonth() + 1) + "/" + (new Date(items.invoice_date).getFullYear()),
                      mobile_no: items.mobile_no,
                      patient_name: items.patient_name,
                      remarks: items.remarks,
                      invoice_no: items.invoice_no,
                      view:
                        <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
                          setInvoiceNo(items.invoice_no)
                          setOpenPhelebotomy(true)
                        }}>
                          <  ReceiptIcon />
                        </Button>
 

                    }
                    the_items.push(obj1)
                  })
                  setRows(the_items)
                  setOrg_Rows(the_items)
                 
                })
             
                 
              }}
              className={classes.button}
            >
              Search
          </Button>

          </GridItem>

        </GridContainer>


        <div align="right">
          <TextField id="standard-basic" label="Search"

            onChange={async (e) => {
              var lab_tests = []
              var text = e.target.value
              var data = await org_rows.map((item) => {



                if (item.name.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.passport.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.cnic.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.id.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                return item

              })
              Promise.all(data).then((item) => {

                setRows(lab_tests)

              })

            }}
          />
        </div>
        {loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                        {columns.map((column) => {
                          const value = row[column.id];

                          return (
                            <>

                              <TableCell key={column.id} align={column.align}  >
                                {value}
                              </TableCell>

                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }


      </Paper>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenPhelebotomy}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOpenPhelebotomy(false)

        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h2 className={classes.modalTitle}><b>Phelebotomy</b></h2>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >

          {OpenPhelebotomy ?
            <center>
              <Fragment key={"sqq"}>
                <Phelebotmy invoice_no={invoice_no} BoxClose={BoxClose}/>
              </Fragment>
            </center> : null}

        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          
        </DialogActions>
      </Dialog>


    </>
  );
}
