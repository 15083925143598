import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
  titleContainer:{
    flexDirection: 'row',
   justifyContent:'start',
   //margin:2,
   //marginTop:0,
  //  top:95,
   top:105,

   left:50,
    // backgroundColor:"green",
//     width:180,
// borderStyle:"solid",
// borderBottomColor: 'black',
// borderBottomWidth: 1,
position:"absolute"
   
},
reportTitle:{
    color: 'black',
    fontSize: 10.5,
    // backgroundColor: '#F2F0F0',
    // border:1,
    fontFamily:'Helvetica-Bold',
    // paddingLeft:5,
    // paddingRight:20,
    //  paddingTop:-15
}

  });


  const InvoiceTitleNewGroup = ({title}) => (
    // top:title.PatientImage!=null?109:95 apply 02-march-2023 4601472 

    <View style={[styles.titleContainer,{top:title.PatientImage!=null?124:115}]}>
        {/* <Text style={[styles.reportTitle,{ fontSize:title=="MPL Pre Employment Medical (Riders/Drivers)"?9.7:title=="MPL Pre Employment Medical (General)"?9.7: 10.5,}]}>{title}</Text> */}
        <Text style={[styles.reportTitle,{ 
          // fontSize:title.GroupTitleNew=="MPL Pre Employment Medical (Riders/Drivers)"?9.7:title.GroupTitleNew=="MPL Pre Employment Medical (General)"?9.7:title.GroupTitleNew=="MPL Staff Medical (Kitchen)"? 10.5:8.7
          fontSize:8.7
        
        }]}>{title.GroupTitleNew}</Text>

        {/* <Text style={styles.reportTitle}>{title}</Text> */}
    </View>
  );
  
  export default InvoiceTitleNewGroup