import React, { useEffect } from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'
import Comments from './Comments'
import Specimen from './Specimen'
import Result from './Result'
import MedicineHeader from './MedicineHeader'
import CovidPDF from '../Covid_PDF/CovidTemplate'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import InvoiceTitleNewGroup from './InvoiceTitleNewGroup';
const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    fontFamily: "Helvetica",
    borderColor: 'black',
  },
  subReportTitle: {
    color: 'black',
        fontSize: 9,
        fontFamily:'Helvetica-Bold',
        paddingRight:5,
        paddingTop:5,
        textAlign: 'left'
  },
  GermTitle: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  SusProfileTitle:{
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  MedicineGroupName:{
    color: 'black',
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  }
});


class InvoiceItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.invoice,
      line: 2,
      page: 1,
      CrossCheck: {},
      NextPage: 0,
      Garbage: [],
      counter: 0,
      page:0,
      DefaultComments:{
        "Cobas C311":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas C111":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        "Cobas e411":"Method/Analyzer: Cobas e 411, fully automated Electrochemiluminescence immunoassay (ECLIA) based analyzer, from Roche Diagnostics, Switzerland.",
         // 11-july-2023 new machine add en dono me se koi aik extra hogi
      "IDS-iSYS": " Please note:- Test (s) are performed on state-of-the-art IDS-iSYS, fully automated specialty Chemiluminescent Immunoassay analyzer (CLIA), from Immunodiagnostic Systems Holdings PLC, UK.",
      "IDS - iSYS": " Please note:- Test (s) are performed on state-of-the-art IDS-iSYS, fully automated specialty Chemiluminescent Immunoassay analyzer (CLIA), from Immunodiagnostic Systems Holdings PLC, UK.",
        "Blue Diver Instrument":"Method/Analyzer: Blue Diver Instrument, automated Immunodot analyzer, from D-tek, Belgium.",
        "BC - 5000":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "BC - 5150":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Cobas U411":"Method/Analyzer: Cobas u 411, automated Urine analyzer, from Roche Diagnostics, Switzerland.",
        "Cube 30 Touch":"Method/Analyzer: Cube 30 TOUCH, Automatic instrument for ESR, from Diesse Diagnostica Senese, Italy.",
        "GeneXpert":"Method/Analyzer: GeneXpert ® PCR system by Cepheid, USA.",
        "BC6200":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Compact X, Behnk Elektronik":"Method/Analyzer: Thrombolyzer Compact X, , fully automated coagulation analyzer, from Behnk Elektronik GmbH & Co. Germany.",
        "FANhp UBT":"Method/Analyzer: FANhp, An automated Urea Breath Testing analyzer, from Fischer Analysen Instrumente (FAN), Germany.",
        "MiniCap Sebia":"Method/Analyzer: MINICAP FLEX PIERCING, fully automated Capillary Electrophoresis System, from Sebia, France.",
        "Chorus Trio":"Method/Analyzer: Chorus TRIO Instrument, automated ELISA based immunoassay analyzer, from Diesse Diagnostica Senese Spa, Italy.",
        "Manual":"",
        "OST":"",
        "Microlab 300":"",
        "Alegria Instrument":"Method/Analyzer: Alegria Instrument, automated ELISA based immunoassay analyzer, from Orgentec Diagnostika GmbH, Germany.",
        "HG SWIFT":"Method/Analyzer: HG Swift, A loop mediated iso thermal amplification (LAMP), from Hibergene, Ireland.",
        "VIDAS":"Method/Analyzer: Automated immunoassay analyzer (VIDAS) from Biomerieux SA, France."
      },
      Machine:"",
      singleTestCounter:0,
      MachineCommentsToShow:[],
      ShowMachineComments:true,
      showPathRemarkOnNextPage:true
    }
  }

  render() {

    return <View>
      {/* 10-aug-2023 8501795 Cp k bad signature + baqi test me gap zyada aa rha hai */}  
    
      {(Object.keys(this.state.data.Hematology).map((key,index) => {

      var shiftMargin = 0
      var totalTests = 0
      this.state.line = this.state.line + 2
     
      var subGroupTitle = {}

      this.state.data.Hematology[key].map((item) => {
        totalTests = totalTests + item.rowHeight
      })

      var FirstBox = this.state.line + this.state.data.Hematology[key][0].rowHeight + 2

      if (FirstBox > 28) {
        this.state.line = 0
        this.state.NextPage = 1


      } else {
        this.state.NextPage = 0


      }
      if (key.includes("Complete") && this.state.data.Hematology[key][0].prev1.length > 4) {
        this.state.line = this.state.line - 8
      }
      if (this.state.NextPage == 0) {

        this.state.Garbage.push(1)
      } else {
        this.state.Garbage = []
      }
      if (this.state.Garbage.length == 3) {
        this.state.NextPage = 1
        this.state.line = 0
      }

      if ((key.includes("Complete Blood Picture") || key.includes("Urine Routine Examination")) && this.state.counter > 0) {
        this.state.NextPage = 1
        this.state.line = 0
      }
      // Machine
    
      this.state.ShowMachineComments=false
      if(this.state.Machine!=this.state.data.Machine[key]){
        this.state.ShowMachineComments=true
        this.state.Machine=this.state.data.Machine[key]
        
      }
      console.log("Machine : ")
      console.log(this.state.data.Machine[key])
      var pasteMachineComm=this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]
      
      this.state.MachineCommentsToShow.push(pasteMachineComm)
   
      console.log(this.state.MachineCommentsToShow)
      // Germ Counter
      var GermCounter=0
      console.log(key)
      console.log(shiftMargin)
      console.log(this.state.NextPage)
      console.log(this.state.counter)
      console.log(Object.keys(this.state.data.Germs).length)
      console.log("Comments Flag")
      console.log(this.state.ShowMachineComments)
      console.log(this.state.data.MachineComments[key])
      this.state.counter++

      // Test counter
      console.log("Test Hematology Length = ")
      console.log(this.state.data.Hematology[key].length)
      if(this.state.data.Hematology[key].length==1){
        this.state.singleTestCounter++
      }else{
        this.state.singleTestCounter=0
      }
      console.log("Prev1 and Prev 2 check "+this.state.data.Hematology[key][0].prev1.length+this.state.data.Hematology[key][0].prev2.length)
     

      return <View>
                
          {
              key.includes("Covid") ||  key.includes("COVID-19 Rapid Antigen Test (RAT)")  ?
              <View>
               
              </View>
              :
              <View >

               
                <View style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: "100%",
                      fontSize: 7,
                      paddingLeft: 10,
                      paddingRight: 10,
                      // paddingTop:1,
                      //  backgroundColor:"red",
                                        // ( this.state.data.Hematology[key].prev1!=""||this.state.data.Hematology[key].prev2!="" )?-78 this cond apply for 6401418 31-jan-2023 2 pages pe aa rhi thi report 2nd page empty es lye -60 ki -78 kr dya withot logo me -70 hai
                      // marginTop:key.includes("Complete") ? key.includes('Complete Blood Analysis with Peripheral Film/Smear') ? -40 : -60 : -10 
                     // 10-aug-2023 sir najam space bht aa rhi thi hematology k test me un ko remove krny k lye 8501795
                                        // marginTop:key.includes("Complete") ? key.includes('Complete Blood Analysis with Peripheral Film/Smear') ? -40 : -60 : 0
                                        // marginTop: -60,
                                        //6-apr-2024 asad sab msg 260748

                       //14-apr-2024 asad sab msg 261803 ye prev comment kr dya ager CP hai to -60 ho jae otherwise sab k lye -40    
                                        // marginTop:this.state.data.invoice_id=="261803"?-40:-60,
                  
                                        marginTop:key.includes("Complete Blood Picture")?-60:-40,


                      // marginTop:key.includes("Complete") ? key.includes('Complete Blood Analysis with Peripheral Film/Smear') ? -40 :(this.state.data.Hematology[key][0].prev1!="" ||this.state.data.Hematology[key][0].prev2!="")?-78: -60 : -10
                  //  ye agr Complete Blood analysis with ka aaya 2 page ja rhi hai to comment uncomment kr dena hai
                  // marginTop:key.includes("Complete") ? key.includes('Complete Blood Analysis with Peripheral Film/Smear') ? -75 :(this.state.data.Hematology[key][0].prev1!="" ||this.state.data.Hematology[key][0].prev2!="")?-78: -60 : -10
// add lines marginBottom 24-feb-2024 for 252398 signature oper aa rhy thy 2nd test k
                  marginBottom:this.state.data.invoice_id=="252398"||this.state.data.invoice_id=="253360"?20:0
                 
                }}
                    >
                 </View>
                 {/* condition add for GroupTitle display */}
                   {/* for patient image this condition work */}
              {/* <View style={[styles.tableContainer,{marginTop:this.state.data.PatientImage!=null? 55:10}]} > */}
              {/* <View style={[styles.tableContainer,{marginTop:this.state.data.Hematology[key][0].newValueGroupTitle!=null?30:this.state.data.PatientImage!=null? 55:10}]} > */}
{/* yhan background lgaya tha red wala */}
              <View style={[styles.tableContainer,{marginTop:this.state.data.PatientImage!=null? 60:this.state.data.GroupTitleNew!=null?45:10,}]} >

                <View style={{
                  padding: 10,
                  // this marginTop:this.state.counter==2 will add for 8401331 report 2nd ICTMP ko opr krny k lye 8 dec sir najam otherwise ye ni tha phly
                  // 10-aug-2023 sir najam space bht aa rhi thi hematology k test me un ko remove krny k lye 8501795
               
                  // marginTop:this.state.counter==2?-40:0,
                  // marginTop:this.state.counter==2?-40:0,
                  // backgroundColor:"green"

                }}>
                {console.log("#####$$$$$%%%%%%%%%",this.state.data)}
                {console.log("#####$$$$$%%%%%%%%%",this.state.data.Hematology[key][0].title)}

                  {
                    this.state.counter > 1 ? <View break={true} key={key + "1"}></View> : null
                  }

{/* for display new group title  3 nov 2022*/}
 {/* { ((this.state.data.Hematology[key].length==1 && (key==this.state.data.Hematology[key][0].test_name) ) && this.state.data.Hematology[key][0].newValueGroupTitle==null || key=="Cobas e411" || key=="Cobas C311" ) ? null : <InvoiceTitleNewGroup title={this.state.data.Hematology[key][0].newValueGroupTitle} />} */}
              {/* for display new group title on every page 5 nov 2022* */}
              {/* {  this.state.data.GroupTitleNew==null ? null : <InvoiceTitleNewGroup title={this.state.data.GroupTitleNew} />} */}

{ ((this.state.data.Hematology[key].length==1 && (key==this.state.data.Hematology[key][0].test_name) )|| key=="Cobas e411" || key=="Cobas C311" ) ? null : <InvoiceTitle title={key} />}
{/* 27-july-2023  */}
                  {/* {
                    (this.state.data.Hematology[key][0].subGroupTitle != "" &&  this.state.data.Hematology[key][0].subGroupTitle != "-" && this.state.data.Hematology[key][0].subGroupTitle[this.state.data.Hematology[key][0].subGroupTitle] == undefined)
                      ?
                      null
                      :
                     <InvoiceTableHeader data={this.state.data.Hematology[key][0]}  />
                  
                  } */}
                  {/* <View style={{
                    marginTop:key.includes("Complete") ? -10 :0
                  }}>

                  </View> */}
                  {/* ye agr 2nd Group Title aaya hai us k bad b show na ho to ye uncomment kr dety hn */}
                  {/* {this.state.counter==2?null:
              <InvoiceTableHeader data={this.state.data.Hematology[key][0]}  />} */}
<InvoiceTableHeader data={this.state.data.Hematology[key][0]}  />

                  {
                    // 24-july-2023 index add in map
                    this.state.data.Hematology[key].map((item,index) => {
        console.log("value of index is "+index)

                      console.log("#############################",item)
                      this.state.line = this.state.line + item.rowHeight
                      var showSubHeadingBoolean = false
                      console.log(subGroupTitle[item.subGroupTitle])
                      if (item.subGroupTitle != "" && item.subGroupTitle != "-" && subGroupTitle[item.subGroupTitle] == undefined) {
                        showSubHeadingBoolean = true
                        subGroupTitle[item.subGroupTitle] = item.subGroupTitle
                        console.log("Sub Title")
                        console.log(subGroupTitle)
                        this.state.line = this.state.line + 2
                      }

                      var space = this.state.line > 28
                      if (this.state.line > 28) {
                        this.state.line = 0
                      }



                      return <View >
                          <View break={true} key={item.test_name + "1"}>

                         </View> 

                        {
                          showSubHeadingBoolean ?
                          <View>

                            <View style={{
                              flexDirection: 'row',
                              justifyContent:'left',
                             
                            }} >
                              <Text style={styles.subReportTitle}>

                                {item.subGroupTitle}

                              </Text>
                              </View>
                              {/* 24-july-2023 this condioton apply */}
                              {/* {index==0?
                              <InvoiceTableHeader  data={this.state.data.Hematology[key][0]} />:<></>
                            } */}
                         
                           </View>
                            :
                            <Text></Text>
                        }

                        <View style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: "100%",
                          fontSize: 7,
        // 24-july-2023
        // marginTop: 0.5,

                          // marginTop: -0.5,
                          //15-feb-2024
                          marginTop: -1,

                          // 27-july-2023
                          // borderWidth: 0.5
                          borderWidth: 1,
                          // 27-july-2023
                          borderColor:"#c5c5c5",
                          borderStyle:"solid"

                        }} key={item.test_name}>

                          <Text style={{
  // 27-july-2023
        // width: '20%',
                            width: '22%',
                            textAlign: 'left',
                            paddingTop: 2,
                            paddingLeft: 8,
                            fontFamily: "Helvetica",
                            height: '100%',
                            textAlign: 'left',
                            fontSize: 7
                          }}>{item.test_name}</Text>
                          <Text style={{

                            width: '20%',
                            textAlign: 'center',
                            // 27-july-2023
                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 1,
                            paddingTop: 2,
                            fontWeight: '',
                            fontSize: 8,
                            height: '100%',
                            paddingLeft: 8,
                            fontFamily: "Helvetica-Bold",
                             // 20-june-2023
                             padding:5

                          }}>{item.result}</Text>
                          <Text style={{
  // 27-july-2023
        // width: '20%',
                            width: '18%',
                            height: '100%',
                            textAlign: 'left',
                            // 27-july-2023
                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 1,
                            paddingTop: 2,
                            fontFamily: "Helvetica",
                            fontSize: 7,
                            paddingLeft: 8,

                          }}>{item.ref_range=="" ? "-" : item.ref_range}</Text>
                          <Text style={{
                            height: '100%',
                            width: '10%',
                            textAlign: 'left',
                            // 27-july-2023

                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 1,
                            paddingTop: 2,
                            fontFamily: "Helvetica",
                            fontSize: 7,
                            paddingLeft: 8,



                          }}>{item.unit==""? "-" : item.unit}</Text>

                          <Text style={{

                            width: '15%',
                            textAlign: 'left',
                            // 27-july-2023
                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 1,
                            paddingTop: 2,
                            height: '100%',
                            fontFamily: "Helvetica",
                            fontSize: 5.5,
                            paddingLeft: 5,


                          }}>{item.prev1=="" ? "-" : item.prev1}</Text>
                          <Text style={{

                            width: '15%',
                            height: '100%',
                            textAlign: 'left',
                            // 27-july-2023
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 1,

                            paddingTop: 2,
                            fontSize: 5.5,
                            fontFamily: "Helvetica",
                            textOverflow: 'auto',
                            paddingLeft: 8

                          }}>{item.prev2=="" ? "-": item.prev2}</Text>

                        </View>


                      </View>
                    })

                  }
                  {
                    this.state.line > 28 ? this.state.line = 0 : null
                  }
                  <View style={{
                    width: "100%",
                    paddingTop: 2
                  }}>
                        {/*  this line add 8 dec 8401331 report share by sir najam */}
                      {this.state.data.comments[key].includes("Eosinophilia")?<></>:<View break={true} key={key + "2132187239"}><Text break={true} ></Text></View>}
                   {/* <View break={true} key={key + "2132187239"}><Text break={true} ></Text></View> */}
                 
                  <View>
                    {
                      key.includes("Complete") ?
                      <View style={{
                        paddingTop:2,
                        paddingLeft:5,
                         //20-june-2023
                         //27-july-2023
                        //  paddingRight:"60%",
                        
                         paddingRight:"1%",
                        marginTop:2,
                        marginBottom:1,
                        backgroundColor:"#eeeeee",
                        borderWidth:0.5,
                         // 20-june-2023
                         color:"black"
                        // backgroundColor:"red",
                        // width:this.state.NextPage==0 ?"190%":"50%",


                      }}>
                      <Text style={{
                         fontFamily: 'Helvetica-Bold',
                         fontSize: 8
                      }}>{"Pathologist Remarks"}</Text>
                      <View style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: "100%",
                          // backgroundColor:"red",
                        // width:this.state.NextPage==1 ?"190%":"100%",

                          // fontSize: 7
                          fontSize:7,

                          
    
                        }}
                        >
                          {
                            this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                              this.state.line = this.state.line + 1
                              var space = this.state.line > 27
                              if (data.length > 200) {
                                this.state.line = this.state.line + 1
                              }
    
                              if (data == "") {
                                return <View><Text>{""}</Text></View>
                              }
    
    
                              if (space) {
    
                                return <View style={{
                                  marginTop: 0.5,
                                  width: "100%",
                          //  backgroundColor:"green",
                          // marginLeft:"55%"
                          // paddingLeft:"55%"
                           // 20-june-2023
                           color:"black",
                        //  this line add 8 dec 8401331 report share by sir najam 460 wali b condition same esi purpose k lye agr yhi report to aisa ho
                          
                          paddingRight:this.state.data.comments[key].includes("Eosinophilia") && this.state.data.comments[key].includes("Serum CRP.") &&this.state.data.comments[key].includes("3. Follow up with serial")?"55%":"0%"

                                }}>
                                  <View>
                                     {/*Pathologist Remarks show on the nxt page 8 dec 2022   */}
                                     {console.log("123123123 ",this.state.data.comments[key])}
                                     {/* {(this.state.line==28&&this.state.data.comments[key].includes("Eosinophilia"))&&<Text style={{ */}
                                     {((this.state.line==28)&&(this.state.data.comments[key].includes("Eosinophilia"))&&(this.state.data.comments[key].includes("Neutrophilic"))&&(this.state.data.comments[key].includes("1. Serum CRP"))&&(this.state.data.comments[key].includes("Follow up"))&&(this.state.data.comments[key].includes("CBC")))&&<Text style={{
                                  
                                  
                                  fontFamily: 'Helvetica-Bold',
                                  fontSize: 8
                               }}>{"Pathologist Remarks"}</Text>
                            
                              }
                                  </View>
                                  <Text style={{
                                    textAlign: "left",

                          // backgroundColor:"pink",
                          //  marginRight:"55%"
                          //  marginLeft:"55%"

                          
                          

                                  }}>
                                    {data}
                                    </Text>
                                </View>
    
                              }
    
                              return <View style={{
                                marginTop: 0.5,
                                width: "100%",
                              }}>
                                <View></View>
                                <Text style={{
                                  textAlign: "left"
                                }}>{data}</Text>
                              </View>
                            })
                          }
             
                        </View>
                   
                     </View>
                     :
                   <View style={{
                      flexDirection: 'column',
                      alignItems: 'left',
                      width: "100%",
                      fontSize: 7 ,
                      paddingTop:this.state.data.comments[key].trim().length==0 ? 0 :10,
                      paddingLeft:this.state.data.comments[key].trim().length==0 ? 0 :10,
                      paddingRight:this.state.data.comments[key].trim().length==0 ? 0 :10,
                      
                      marginTop:this.state.data.comments[key].trim().length==0 ? 0 : 5,
                      paddingBottom:this.state.data.comments[key].trim().length==0 ? 0 :10,
                      marginBottom:this.state.data.comments[key].trim().length==0 ? 0 :1,
                      backgroundColor:this.state.data.comments[key].trim().length==0 ? "white" :"#eeeeee",
                      borderWidth:this.state.data.comments[key].trim().length==0 ? 0 :0.5

                    }}
                    >
                      {
                        this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                          this.state.line = this.state.line + 1
                          var space = this.state.line > 28
                          if (data.length > 200) {
                            this.state.line = this.state.line + 1
                          }

                          if (data == "") {
                            return <View><Text>{""}</Text></View>
                          }


                          if (space) {

                            return <View style={{
                              marginTop: 1,
                              width: "100%",
                            }}>
                              <View></View>
                              <Text style={{
                                textAlign: "left"
                              }}>{data}</Text>
                            </View>

                          }

                          return <View style={{
                            marginTop: 1,
                            width: "100%"
                          }}>
                            <View></View>
                            <Text style={{
                              textAlign: "left"
                            }}>{data}</Text>
                          </View>
                        })
                      }
                 
                   
                 
                    </View> 
                    }
                    
                    <Text style={{ fontSize: 7 }}>{this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]}</Text>
               
                  </View> 
                    

                  </View>
                </View>
                {/* this.state.data.Hematology[key][0].title=="Hemoglobin Level"?-7 08-sep-2023 lgae thi 6301883 */}

                {
                      key.includes("Complete") ||Object.keys( this.state.data.Hematology).length==index+1?
                      // 11-nov-2023 3302091 test is fibronogin level ye oper aa gya tha es k lye b condition add ki -7 wali 
                // <View style={{ alignItems: 'flex-end',width:"100%" ,marginTop:this.state.data.Hematology[key][0].title=="Hemoglobin Level"?-7:-20}}>
                // 22-dec-23 this.state.data.invoice_id=="2902123"?5 22-dec-23 MPL-it query from recption
                // 28-dec-23 this.state.data.Hematology[key][0].title.includes("Coomb's ")?0 28-dec-23 this.state.data.invoice_id=="2902123"?0 es ki jga title pe includes lga dya hai Indrect and direct coombs pe aa rha tha 


                <View style={{ alignItems: 'flex-end',width:"100%" ,marginTop:this.state.data.Hematology[key][0].title.includes("Coomb's ")?5:this.state.data.Hematology[key][0].title=="Hemoglobin Level"|| this.state.data.Hematology[key][0].title=="Fibrinogen Level"?-7:-20}}>

                                   
                                   <Text style={{ fontSize: 6 ,bottom: -10,}}>User ID : {this.state.data.DoctorSignature} Printed on Date   {this.state.data.TodayDate} at {this.state.data.TodayTime}   Inv.Date: {this.state.data.InvoiceDate[0]}  Inv.Time {this.state.data.InvoiceTime[0]}</Text>
                                   <Text style={{ color: 'red', bottom: -8, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                   
                                   {
                                       this.state.data.DoctorSignature.includes("Waqar")||this.state.data.DoctorSignature.includes("Najam1") ?
                                           <View style={{alignItems: 'flex-end'}}>
                                               <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                               <Text style={{ bottom: -4, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                               <Text style={{ bottom: -2, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                           </View>
                                           :(this.state.data.DoctorSignature.includes("Amna") ||this.state.data.DoctorSignature.includes("Najam2")) ?
                                            
                                           <View style={{alignItems: 'flex-end'}}>
                                           <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Dr. Amna Afzal"}</Text>
                                           <Text style={{bottom:-4, right:0,fontSize:6, fontFamily:'Helvetica'}}>FCPS Microbiology</Text>
                                             <Text style={{bottom:-2, right:0,fontSize:6, fontFamily:'Helvetica'}}>Consultant Microbiologist</Text>
                                             </View>
                                             :<></>
                                      //      <View style={{alignItems: 'flex-end'}}>
                                      //      <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{" "}</Text>
                                      //      <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>
                                      //      <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>

                                      //  </View>
                                   //     <View style={{alignItems: 'flex-end'}}>
                                   //     <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                   //     <Text style={{ bottom: -4, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                   //     <Text style={{ bottom: -2, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                   // </View>

                                   }
                               </View>
                               :null}


              </View>
             </View>
        }
        

      </View>
      


    }))}
    {/* 10-aug-2023 comment kiye hn oper dynamic kr dya tha */}

    {/* 24-july-2023 this total view */}
         {/* <View style={{ alignItems: 'flex-end',  }}>
                                   
                                    <Text style={{ fontSize: 6 ,bottom: -10,}}>User ID : {this.state.data.DoctorSignature} Printed on Date   {this.state.data.TodayDate} at {this.state.data.TodayTime}   Inv.Date: {this.state.data.InvoiceDate[0]}  Inv.Time {this.state.data.InvoiceTime[0]}</Text>
                                    <Text style={{ color: 'red', bottom: -8, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                    
                                    {
                                        this.state.data.DoctorSignature.includes("Waqar")||this.state.data.DoctorSignature.includes("Najam") ?
                                            <View style={{alignItems: 'flex-end'}}>
                                                <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                                <Text style={{ bottom: -4, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                                <Text style={{ bottom: -2, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                            </View>
                                            :
                                            <View style={{alignItems: 'flex-end'}}>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{" "}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>

                                        </View>
                                    //     <View style={{alignItems: 'flex-end'}}>
                                    //     <Text style={{ bottom: -6, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                    //     <Text style={{ bottom: -4, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                    //     <Text style={{ bottom: -2, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                    // </View>

                                    }
                                </View> */}
    </View>
    
  }
}



export default InvoiceItemsTable