import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 3,
        width: "380em",
        borderColor: 'black'
    },
});

const InvoiceItemsTable = ({ invoice }) => (

    <View>
        <InvoiceTitle item={invoice} />
        <View style={styles.tableContainer}>
            <InvoiceTableHeader />
            <InvoiceTableRow items={invoice.reports} key={invoice.name} />
            <View>

                <View style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    alignItems: 'center',
                    width: "100%",
                    height: 20,
                    fontSize: 7,
                    fontStyle: 'bold'
                }} key={"q123"}>
                    <Text style={{

                        width: '50%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        height: '100%',

                        fontSize: 6.5,
                        padding: 5,

                    }}>

                    </Text>
                    <Text style={{

                        width: '5%',
                        textAlign: 'left',
                        height: '100%',

                        fontSize: 6.5,
                        padding: 5,

                    }}>


                    </Text>
                    <Text style={{

                        width: '30%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        height: '100%',

                        fontSize: 6.5,
                        padding: 5,


                    }}>{"Net Cost"}</Text>
                    <Text style={{

                        width: '15%',
                        height: '100%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        backgroundColor: "#eeeeee",
                        fontSize: 6.5,
                        borderRightWidth: 1,
                        textOverflow: 'auto',
                        padding: 5

                    }}>{invoice.Invoice_Value}</Text>

                </View>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    height: 20,
                    fontSize: 7,
                    fontStyle: 'bold'
                }} key={"2w1"}>
                    <Text style={{

                        width: '55%',
                        textAlign: 'left',
                        height: '100%',
                        padding: 5,
                        fontSize: 7,
                    }}>

                    </Text>
                    <Text style={{

                        width: '30%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        height: '100%',

                        fontSize: 6.5,
                        padding: 5,


                    }}>{"Discount"}</Text>
                    <Text style={{

                        width: '15%',
                        height: '100%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        backgroundColor: "#eeeeee",
                        fontSize: 6.5,
                        borderRightWidth: 1,
                        textOverflow: 'auto',
                        padding: 5

                    }}>{invoice.Discount_Total}</Text>

                </View>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    height: 20,
                    fontSize: 7,
                    fontStyle: 'bold'
                }} key={"221"}>
                    <Text style={{

                        width: '55%',
                        textAlign: 'left',
                        height: '100%',
                        padding: 5,
                        fontSize: 7,
                    }}>

                    </Text>
                    <Text style={{

                        width: '30%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        height: '100%',

                        fontSize: 6.5,
                        padding: 5,


                    }}>{"Invoice Net Payable"}</Text>
                    <Text style={{

                        width: '15%',
                        height: '100%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        backgroundColor: "#eeeeee",
                        fontSize: 6.5,
                        borderRightWidth: 1,
                        textOverflow: 'auto',
                        padding: 5

                    }}>{invoice.Invoice_Payable}</Text>

                </View>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    height: 20,
                    fontSize: 7,
                    fontStyle: 'bold'
                }} key={"221"}>
                    <Text style={{

                        width: '55%',
                        textAlign: 'left',
                        height: '100%',
                        padding: 5,
                        fontSize: 7,
                    }}>

                    </Text>
                    <Text style={{

                        width: '30%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        height: '100%',

                        fontSize: 6.5,
                        padding: 5,


                    }}>{"Total Received Value"}</Text>
                    <Text style={{

                        width: '15%',
                        height: '100%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        backgroundColor: "#eeeeee",
                        fontSize: 6.5,
                        borderRightWidth: 1,
                        textOverflow: 'auto',
                        padding: 5

                    }}>{invoice.Invoice_Received}</Text>

                </View>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    height: 20,
                    fontSize: 7,
                    fontStyle: 'bold'
                }} key={"221"}>
                    <Text style={{

                        width: '55%',
                        textAlign: 'left',
                        height: '100%',
                        padding: 5,
                        fontSize: 7,
                    }}>

                    </Text>
                    <Text style={{

                        width: '30%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        height: '100%',

                        fontSize: 6.5,
                        padding: 5,


                    }}>{"Balance Value"}</Text>
                    <Text style={{

                        width: '15%',
                        height: '100%',
                        textAlign: 'left',
                        borderColor: 'black',
                        borderWidth: 0.5,
                        backgroundColor: "#eeeeee",
                        fontSize: 6.5,
                        borderRightWidth: 1,
                        textOverflow: 'auto',
                        padding: 5

                    }}>{invoice.Invoice_Balance}</Text>

                </View>
            </View>


        </View>
        {
            invoice.flight_no != "" ?
                <View>

                    <View >
                        <Text style={{

                            textAlign: 'left',
                            padding: 2,
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 6.5,
                            color: 'black'
                        }}>
                            {"I have read and verified my particulars as correct"}
                        </Text>

                    </View>
                    <View>


                        <Text style={{

                            textAlign: 'left',
                            padding: 2,
                            fontSize: 6.5,
                            color: 'black'
                        }}>
                            {"Name : " + invoice.name}
                        </Text>
                        <Text style={{

                            textAlign: 'left',
                            padding: 2,
                            fontSize: 6.5,
                            color: 'black'
                        }}>
                            {"CNIC : " + invoice.cnic}
                        </Text>
                        {
                            invoice.flight_no != "" ?
                                <Text style={{

                                    textAlign: 'left',
                                    padding: 2,
                                    fontSize: 6.5,
                                    color: 'black'
                                }}>
                                    {"Passport No : " + invoice.passport_no}
                                </Text>
                                : null
                        }



                    </View>
                    <View style={{
                        height: 30
                    }} >

                        <Text style={{

                            width: '40%',
                            textAlign: 'left',
                            borderColor: 'black',
                            borderWidth: 0.5,
                            fontSize: 6.5,
                            padding: 7,


                        }}>{"Signature"}</Text>

                        <Text style={{

                            width: '60%',
                            textAlign: 'left',

                            padding: 5,
                            fontSize: 7,
                        }}>

                        </Text>

                    </View>
                    <View style={{ paddingLeft: 10, paddingTop: 5 }}>
                        <Text style={{
                            fontFamily: 'Helvetica-Bold'
                        }}>Note:</Text>
                        <Text>o <Text style={{ fontSize: 7, fontFamily: "Helvetica" }}>Reporting time is subject to the sample submission.</Text></Text>
                        <Text>o <Text style={{ fontSize: 7, fontFamily: "Helvetica" }}>Please bring this form to collect the reports.</Text></Text>
                        <Text>o <Text style={{ fontSize: 7, fontFamily: "Helvetica" }}>In case of any query regarding report, please call at the given number within 48 hours.</Text></Text>
                    </View>
                </View>
                :
                null
        }
    </View>


);

export default InvoiceItemsTable