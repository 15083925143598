import React ,{useEffect,Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import ReactLoading from 'react-loading';



import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import {PDFViewer} from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet ,PDFDownloadLink} from '@react-pdf/renderer';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import RefreshIcon from '@material-ui/icons/Refresh';

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';

import RadiologyPDF from './RadiologyPDF'

import SignRadiologyPDF from './SignRadiologyPDF'
// for whatsapp
import ReactWhatsapp from 'react-whatsapp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const columns = 
  [{ id: 'id', label: 'Invoice ID' },
  { id: 'name', label: 'Patient Name' },
  { id: 'title', label: 'Test Name' },
  { id: 'code', label: 'Test Code' },
  {id:"mobile",label:"Mobile"},

  { id: 'branch', label: 'Branch' },
  { id: 'status', label: 'Status' },
  {id:'barcode' , label:'Print Report Sticker'},
{id:'download' , label:'Lab Report'},
{id:'official' , label:'MPL Sign Lab Report'},
{id:'whatsapp' , label:'WhatsApp'},
]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
 return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime (ms) {
  var seconds = ms
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return addZero(hours) + ':' + addZero(minutes) ;
}

export default function Lab_Tests() {
  // for temp img jab backend se aa jae gi tab yahan se remove kr den ge 
   
  const classes = useStyles();
  var date=new Date()
  var temp_role=localStorage.getItem('role')
  

  const [selected,setSelected]=React.useState({
    reports:[]
  })
  
  const [OpenCovidTemplate,setCovidTemplate]=React.useState(false)

  const [OpenOfficialCovidTemplate,setOfficialCovidTemplate]=React.useState(false)

  const [patient_invoice,setPatientInvoice]=React.useState(false)
  
  const [from,setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel,setPanel]=React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rows,setRows]= React.useState([]);
  const [show,setShow]=React.useState(false)
  const [showSignCovidMPL,setShowSignMPL]=React.useState(false)
  const [Invoice_Aux_ID,setInvoice_Aux_ID]=React.useState("")
  const [User_ID,setUser_ID]=React.useState("")
  const [name,setName]=React.useState('')
  const [RefBy,setRefBy]=React.useState('')
  const [invoice_id,setInvoiceID]=React.useState("")
  const [age_gender,setAgeGender]=React.useState("")
  const [Salutation,setSalutation]=React.useState('')
  const [TestTitle,setTestTitle]=React.useState('')
  const [InvoiceDate,setInoviceDate]=React.useState('')
  const [InvoiceTime,setInvoiceTime]=React.useState('')
  const [UserName,setUserName]=React.useState('')
  const [DoctorName,setDoctorName]=React.useState('')
  const [DoctorInfo,setDoctorInfo]=React.useState('')
  const [margTopForXrayResult,setmargTopForXrayResult]=React.useState(155)
  
  const [ReportDate,setReportDate]=React.useState('')
  const [ReportTime,setReportTime]=React.useState('')
  const [testCode,settestCode]=React.useState("")
  const [Ref_no,setRef_no]=React.useState("")

  const [selectedCovid,setSelectedCovid]=React.useState({
    reports:[]
  })
  const [selectedOfficialCovidReport,setSelectedOfficialCovidReport]=React.useState({
    reports:[]
  })

  const [org_rows,setOrg_Rows]= React.useState([]);
  const [loading,setLoading]=React.useState(true)

  const [covidButton,setCovidButton]=React.useState(false)

  const [panelList,setpanelList]=React.useState([])
  const [laborder,setLabOrder]=React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches,setBranches]=React.useState([])
  const [branch,setBranch]=React.useState("")
  const [selectBranch,setSelectBranch]=React.useState([])
  const [state, setState] = React.useState({
    status:'All',
  });
  var svgString2Image=(svgString, width, height, format, callback)=>{
    
    var svgStringFormat=window.atob(svgString)
    var temp=svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
        // clear canvas
        context.clearRect(0, 0, width, height);
        // draw image with SVG data to canvas
        context.drawImage(image, 0, 0, width, height);
        // snapshot canvas as png
        var pngData = canvas.toDataURL('image/' + format);
        // pass png data URL to callbac
        callback(pngData)
    }; // end async
    image.src=svgData
  
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
                    if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
                      localStorage.clear()
                      window.location.href="https://printing.fslabs.com.pk/login"
                    }
                      var patient_id= localStorage.getItem('patient_id')
                      var role=localStorage.getItem('role')
                    
                      var from = new Date();
                    
                    
                      var branchID=localStorage.getItem('branch_id')
                    
                      var to= new Date();
                      var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID:"0" }
                      var vSearchStr = JSON.stringify(SearchFormData);
  
                      var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/RadiologyResultsCallingList?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole="+role
  
    fetch(url).then((res)=>res.json())
     .then((result)=>{
      console.log("Lab Test.js useEffect 241 response of API :",result)
      var the_rows=[]
      result.map((data)=>{
              var br_title=""
          
              if(data.B_TiTLE=="METROPOLE"){
                br_title="Islamabad"
              }else{
              
                  br_title=data.B_TiTLE.split('MPL,')[1]
                
            
              }
        var obj={
                    id:data.Invoice_ID,
                    name:data.Patient_Name,
                    title:data.T_TiTLE,
                    status:data.Test_Status,
                    cnic:data.CNIC,
                    passport:data.Passport_No,
                    mobile:data.Mobile_No,
                    reference_no:data.Reference_No,
                    code:data.T_CODE,
                    Panel_Code:data.Panel_Code,
                    Panel_Name:data.Panel_Name,
                    branch:br_title,
                    dateObject:new Date(data.ACCESS_DATE),
          
                    barcode:<center>
                                  <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={data.Invoice_ID} disableElevation onClick={()=>{
                                    
                                        var obj1={
                                          name:data.Patient_Name,
                                          age_gender:data.Age_gender,
                                          mpl_id:data.Invoice_ID,
                                          ref_by:data.Doctor_Name,
                                          date:new Date().ddmmyyy(),
                                          study:data.T_TiTLE
                                        }
                                        var url="http://localhost:1000/ReportSticker"
                                        fetch(url,{
                                          method:"POST",
                                          body:JSON.stringify(obj1),
                                          headers:{
                                            'Content-Type':"application/json"
                                          }
                                        }).then(()=>{
                                          alert("Print Request Sent")
                                        })
                                    
                                    }} >
                                         Report Sticker
                                    </Button>
                             </center>,
                    official:
                              <center>
                                
                                <Button variant="contained" color="success" key={data.Invoice_ID}   onClick={()=>{
                              if(!data.DOnotShowUnpaidReport){
                                    if(data.Test_Status=='Results Ready'){
                                      //ye add kiye hn radiology me thy but yhan ni 2 jan 2023 sir najam 7501303
                                      settestCode(data.T_CODE)
                                      setReportDate(new Date(data.Result_Date).ddmmyyy())
                                      setReportTime(msToTime(data.Result_Time))
                                      setRef_no(data.Reference_No)

                                      setName(data.Patient_Name)
                                      setRefBy(data.Refer_By_Name)
                                      setSalutation(data.Salutation)
                                      setInvoice_Aux_ID(data.InV_AuX_ID)
                                      setUser_ID(data.User_ID)
                                      setInvoiceID(data.Invoice_ID)
                                      setAgeGender(data.Age_gender)
                                      setDoctorName(data.Doctor_Name)
                                      setDoctorInfo(data.Doctor_Degree)
                                      setTestTitle(data.T_TiTLE)
                                      setInoviceDate(new Date(data.Booking_Date).ddmmyyy())
                                      setInvoiceTime(msToTime(data.Booking_Time))
                                      setUserName(data.User_Name)
                                      setShowSignMPL(true)   
                                      setShow(true)
                                       //this cond for X-ray report result niche tha bht us ko oper lany k lye dynamic kiya hai es ko                                                 
                                    // data.T_TiTLE=="Ultrasound Abdomen this cond apply when we can changed footer es ki line userID: pe aa rhi thi mny niche kr dya  
                                       if(data.T_TiTLE=="Ultrasound Abdomen"){
                                        setmargTopForXrayResult(160)
                                      }
                                      else if(data.T_TiTLE.includes("X-Ray")){
                                        setmargTopForXrayResult(145)
                                      }
                                      else{
                                        setmargTopForXrayResult(155)
                                      }     
                                     
                                    }else{
                                      alert("Results not Ready Yet")
                                    }
                                    
                                  }else{
                                    alert("Sorry do not Show Unpaid Reports")
                                  }
                                  
                                    } }>
                                    {covidButton ? "Loading......" :  <  PictureAsPdfSharpIcon  />}
                                 </Button>
                          
                              
                              </center>
            
                     ,
                    download:
          
          
                               <center>
                                      
                                      <Button variant="contained" color="secondary" key={data.Invoice_ID}   onClick={()=>{
                                    
                                    if(!data.DOnotShowUnpaidReport){

                                          if(data.Test_Status=='Results Ready'){
                                             //ye add kiye hn radiology me thy but yhan ni 2 jan 2023 sir najam 7501303
                                      settestCode(data.T_CODE)
                                      setReportDate(new Date(data.Result_Date).ddmmyyy())
                                      setReportTime(msToTime(data.Result_Time))
                                      setRef_no(data.Reference_No)
                                            setName(data.Patient_Name)
                                            setRefBy(data.Refer_By_Name)
                                            setSalutation(data.Salutation)
                                            setInvoice_Aux_ID(data.InV_AuX_ID)
                                            setUser_ID(data.User_ID)
                                            setInvoiceID(data.Invoice_ID)
                                            setAgeGender(data.Age_gender)
                                            setDoctorName(data.Doctor_Name)
                                            setDoctorInfo(data.Doctor_Degree)
                                            setTestTitle(data.T_TiTLE)
                                            setInoviceDate(new Date(data.Booking_Date).ddmmyyy())
                                            setInvoiceTime(msToTime(data.Booking_Time))
                                            setUserName(data.User_Name)
                                            setShow(true)  
                                             //this cond for X-ray report result niche tha bht us ko oper lany k lye dynamic kiya hai es ko                                                 
                                    // data.T_TiTLE=="Ultrasound Abdomen this cond apply when we can changed footer es ki line userID: pe aa rhi thi mny niche kr dya  
                                            
                                             if(data.T_TiTLE=="Ultrasound Abdomen"){
                                              setmargTopForXrayResult(160)
                                            }
                                            else if(data.T_TiTLE.includes("X-Ray")){
                                              setmargTopForXrayResult(145)
                                            }
                                            else{
                                              setmargTopForXrayResult(155)
                                            }         
                                          }else{
                                            alert("Results not Ready Yet")
                                          }
                                        }else{
                                          alert("Sorry do not Show Unpaid Reports")
                                        }
                                        
                                         } }>
                                          {covidButton ? "Loading......" :  <  PictureAsPdfSharpIcon  />}
                                        </Button>
                                
                                    
                               </center>,
                                 // for whatsapp
            whatsapp:<center><ReactWhatsapp  style={{border:"none",cursor:"pointer",alignSelf:"center",background:"white"}} number={data.Mobile_No.replace("0","+92")} message="" >
           
            <WhatsAppIcon  size="large" style={{color:"green",height:30,width:30}}></WhatsAppIcon>
          </ReactWhatsapp></center>,

                }
                  the_rows.push(obj)

             })
      
                setRows(the_rows)
                setOrg_Rows(the_rows)
                setLoading(false)
        })
      //fetch  239 complete in this line 
                fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetAllPanelsList").then((res)=>res.json())
                  .then((result)=>{
                    setpanelList(result)
                  }) 
                if(localStorage.getItem('org_branch_id')=='0'){
                  fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then((response)=>{
                  setBranches(response)
                  var obj={}
                  response.map((item)=>{
                    obj[item.BranchID]=item.BranchName
                  })
                  setSelectBranch(obj)

                  setBranch(localStorage.getItem('branch_id'))
                })
                 }


  },[])
  // useeffect complete in this line
          Date.prototype.hhmm = function () {
                var mm = this.getMinutes(); 
                var hh = this.getHours();

                return [
                    (hh > 9 ? '' : '0') + hh,
                    (mm > 9 ? '' : '0') + mm
                ].join(':');
            };

          Date.prototype.ddmmyyy = function () {
              var mm = this.getMonth() + 1; // getMonth() is zero-based
              var dd = this.getDate();

              return [
                  (dd > 9 ? '' : '0') + dd,
                  (mm > 9 ? '' : '0') + mm,
                  this.getFullYear()
              ].join('/');
                                            };
  return (
    <>
    <Paper className={classes.root}>
      <GridContainer style={{padding:'2em'}}>
                <GridItem xs={6} sm={6} md={2} lg={2}>
                
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
 <KeyboardDatePicker
        variant="inline"
        inputVariant="outlined"
        label="From"
        format="dd/MM/yyyy"
        value={from}
        InputAdornmentProps={{ position: "start" }}
        onChange={date=> setFrom(date)}
      />
      </MuiPickersUtilsProvider>
                </GridItem>
                {
                  width<600
                  ?
<GridItem xs={6} sm={6} md={0} lg={0}>

</GridItem>:""
                }
                
                <GridItem xs={6} sm={6} md={2} lg={2}>
                 
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
 <KeyboardDatePicker
        variant="inline"
        inputVariant="outlined"
        label="To"
        format="dd/MM/yyyy"
        value={to}
        InputAdornmentProps={{ position: "start" }}
        onChange={date=> setTo(date)}
      />
      </MuiPickersUtilsProvider>
                  
                </GridItem>
                {
                  width<600
                  ?
                    <GridItem xs={6} sm={6} md={0} lg={0}>

                    </GridItem>:""
                }
              <GridItem xs={6} sm={6} md={2} lg={2}>
                <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
                  <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                      value={state.status}
                    onChange={handleChange}
                    inputProps={{
                      name: 'status',
                      id: 'filled-age-native-simple',
                    }}
                  >
                    <MenuItem aria-label="Select" value="All" >All</MenuItem>
                    <MenuItem value={'Just Booked'}>Just Booked</MenuItem>
                    <MenuItem value={'Only Phlebotomy Done'}>Only Phlebotomy Done</MenuItem>
                    <MenuItem value={'Lying Pending Results'}>Lying Pending Results</MenuItem>
                    <MenuItem value={'Results Done But not Signed'}>Results Done But not Signed</MenuItem>
                    <MenuItem value={'Results Ready'}>Results Ready</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
                   {/* Search Buttton start 482 to 657 */}
              <GridItem xs={12} sm={12} md={2} lg={2} style={{margin:'auto'}} >
                    
                  <Button variant="contained" color="primary" disableElevation disabled={loading}
  onClick={()=>{
                    setPage(0)
                    document.getElementById('search_data').value=""
                    var role=localStorage.getItem('role')
                    var patient_id= localStorage.getItem('patient_id')
                    setLoading(true)
                    
                    var  branchID=localStorage.getItem('branch_id')
                    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID:"0" }
                    console.log(SearchFormData)
                    var vSearchStr = JSON.stringify(SearchFormData);
                    console.log(vSearchStr)
                  
                    var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/RadiologyResultsCallingList?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole="+role
                    console.log(url)
                    fetch(url).then((res)=>res.json())
              .then((result)=>{
                     console.log(result)
                      var the_rows=[]

                      var name=localStorage.getItem('patient_name')
                result.map((data,index)=>{
        
                     {console.log(index)}

                      var br_title=""
       
                        if(data.B_TiTLE=="METROPOLE"){
                            br_title="Islamabad"
                           }else{
                          
                            br_title=data.B_TiTLE.split('MPL,')[1]
                          
                      
                           }
                      var obj={
                          id:data.Invoice_ID,
                          name:data.Patient_Name,
                          title:data.T_TiTLE,
                          status:data.Test_Status,
                          cnic:data.CNIC,
                          passport:data.Passport_No,
                          mobile:data.Mobile_No,
                          reference_no:data.Reference_No,
                          code:data.T_CODE,
                          Panel_Code:data.Panel_Code,
                          Panel_Name:data.Panel_Name,
                          branch:br_title,
                          dateObject:new Date(data.ACCESS_DATE),
                          
                          barcode:<center>
                                          <Button variant="contained" style={{backgroundColor:'white', color:'black'}} key={data.Invoice_ID} disableElevation onClick={()=>{
                                              
                                                var obj1={
                                                  name:data.Patient_Name,
                                                  age_gender:data.Age_gender,
                                                  mpl_id:data.Invoice_ID,
                                                  ref_by:data.Doctor_Name,
                                                  date:new Date().ddmmyyy(),
                                                  study:data.T_TiTLE
                                  
                                                }
                                                var url="http://localhost:1000/ReportSticker"
                                                fetch(url,{
                                                  method:"POST",
                                                  body:JSON.stringify(obj1),
                                                  headers:{
                                                    'Content-Type':"application/json"
                                                  }
                                                }).then(()=>{
                                                  alert("Print Request Sent")
                                                })
                                            
                                            }} >
                                                Report Sticker
                                          </Button>
                                      </center>,
                         official:
                                      <center>
                                          
                                            <Button variant="contained" color="success" key={data.Invoice_ID}   onClick={()=>{
                                      if(!data.DOnotShowUnpaidReport){
                                        
                                              if(data.Test_Status=='Results Ready'){
                                                       //ye add kiye hn radiology me thy but yhan ni 2 jan 2023 sir najam 7501303
                                      settestCode(data.T_CODE)
                                      setReportDate(new Date(data.Result_Date).ddmmyyy())
                                      setReportTime(msToTime(data.Result_Time))
                                      setRef_no(data.Reference_No)
                                                setName(data.Patient_Name)
                                                setRefBy(data.Refer_By_Name)
                                                setSalutation(data.Salutation)
                                                setInvoice_Aux_ID(data.InV_AuX_ID)
                                                setUser_ID(data.User_ID)
                                                setInvoiceID(data.Invoice_ID)
                                                setAgeGender(data.Age_gender)
                                                setDoctorName(data.Doctor_Name)
                                                setDoctorInfo(data.Doctor_Degree)
                                                setTestTitle(data.T_TiTLE)
                                                setInoviceDate(new Date(data.Booking_Date).ddmmyyy())
                                                setInvoiceTime(msToTime(data.Booking_Time))
                                                setUserName(data.User_Name)
                                                setShowSignMPL(true)   
                                                setShow(true)
                                                //this cond for X-ray report result niche tha bht us ko oper lany k lye dynamic kiya hai es ko
                                    // data.T_TiTLE=="Ultrasound Abdomen this cond apply when we can changed footer es ki line userID: pe aa rhi thi mny niche kr dya  
                                             
                                                if(data.T_TiTLE=="Ultrasound Abdomen"){
                                                  setmargTopForXrayResult(160)
                                                }
                                                else if(data.T_TiTLE.includes("X-Ray")){
                                                  setmargTopForXrayResult(145)
                                                } 
                                                else{
                                                  setmargTopForXrayResult(155)
                                                }    
                                              }else{
                                                alert("Results not Ready Yet")
                                              }
                                              
                                            }else{
                                              alert("Sorry do not Show Unpaid Reports")
                                            }
                                                } }>
                                                 {covidButton ? "Loading......" :  <  PictureAsPdfSharpIcon  />}
                                             </Button>
                                      
                                        
                                      </center>,
                         download:
          
                                      <center>
                                        
                                        <Button variant="contained" color="secondary" key={data.Invoice_ID}   onClick={()=>{
                                      if(!data.DOnotShowUnpaidReport){
                                            if(data.Test_Status=='Results Ready'){
                                                     //ye add kiye hn radiology me thy but yhan ni 2 jan 2023 sir najam 7501303
                                      settestCode(data.T_CODE)
                                      setReportDate(new Date(data.Result_Date).ddmmyyy())
                                      setReportTime(msToTime(data.Result_Time))
                                      setRef_no(data.Reference_No)
                                              console.log(data)
                                              setName(data.Patient_Name)
                                              setRefBy(data.Refer_By_Name)
                                              setSalutation(data.Salutation)
                                              setInvoice_Aux_ID(data.InV_AuX_ID)
                                              setUser_ID(data.User_ID)
                                              setInvoiceID(data.Invoice_ID)
                                              setAgeGender(data.Age_gender)
                                              setTestTitle(data.T_TiTLE)
                                              setInoviceDate(new Date(data.Booking_Date).ddmmyyy())
                                              setInvoiceTime(msToTime(data.Booking_Time))
                                              setUserName(data.User_Name)
                                              setDoctorName(data.Doctor_Name)
                                              setDoctorInfo(data.Doctor_Degree)
                                              setShow(true) 
                                              //this cond for X-ray report result niche tha bht us ko oper lany k lye dynamic kiya hai es ko                                                 
                                    // data.T_TiTLE=="Ultrasound Abdomen this cond apply when we can changed footer es ki line userID: pe aa rhi thi mny niche kr dya  
                                            
                                              if(data.T_TiTLE=="Ultrasound Abdomen"){
                                                setmargTopForXrayResult(160)
                                              }
                                              else if(data.T_TiTLE.includes("X-Ray")){
                                                setmargTopForXrayResult(145)
                                              }
                                              else{
                                                setmargTopForXrayResult(155)
                                              }        
                                            }else{
                                              alert("Results not Ready Yet")
                                            }
                                          }else{
                                            alert("Sorry do not Show Unpaid Reports")
                                          }
                                          
                                            } }>
                                                 {covidButton ? "Loading......" :  <  PictureAsPdfSharpIcon  />}
                                         </Button>
                                  
                                      
                                       </center>,
                                         // for whatsapp
            whatsapp:<center><ReactWhatsapp  style={{border:"none",cursor:"pointer",alignSelf:"center",background:"white"}} number={data.Mobile_No.replace("0","+92")} message="" >
           
            <WhatsAppIcon  size="large" style={{color:"green",height:30,width:30}}></WhatsAppIcon>
          </ReactWhatsapp></center>,

                        }
                        // yhan tab obj wala object initial ho rha hai
                        if(state.status=="All"){
                            the_rows.push(obj)
                            //yhan se obj waly object ka sara data the_rows.push ho rha hai status me All me drop down me
                  
                        }else{
                               if(state.status.trim()==data.Test_Status.trim()){
                                  the_rows.push(obj)
                            //yhan se obj waly object ka data the_rows me push ho rha just wo wala jo status me select hua hai drop down me

                                  }
                              } 

       
                  })
                  //map end 
                  {console.log(the_rows)}
     
                      setRows(the_rows)
                      setOrg_Rows(the_rows)
                      setLoading(false)
                      setPage(0)
                 })
                 //.then end 
                    
            }}
            // onClick end
                  >
                    SeaRch<SearchIcon />
                  </Button>
         
                  </GridItem>
              </GridContainer>
              {/* Search Buttton end 482 to 657*/}
        <div align="right">
               
               <TextField id="standard-basic" id="search_data" label="Search by Invoice ID, Passport No , Reference No , CNIC , Patient Name , Mobile No , Test Name and Test Code"
             style={{maxWidth:'50em'}}
              fullWidth={true}
              
               onChange={async (e)=>{
                   var lab_tests=[]
                   var text=e.target.value
                  var data=await org_rows.map((item)=>{
                    if(item.title=="Covid-19 RNA by PCR"){
                      if(item.cnic.includes(text)){
                        lab_tests.push(item)
                        return
                      }
                      if(item.passport.toLowerCase().includes(text.toLowerCase())){
                        lab_tests.push(item)
                        return
                      }
                      if(item.reference_no.toLowerCase().includes(text.toLowerCase())){
                        lab_tests.push(item)
                        return
                      }
                    }
                    if(item.mobile.includes(text.toLowerCase())){
                      lab_tests.push(item)
                      return
                   
                  }
                
                      if(item.title.toLowerCase().includes(text.toLowerCase())){
                          lab_tests.push(item)
                          return
                       
                      }
                     
                      if(item.name.trim().toLowerCase().includes(text.trim().toLowerCase())){
                        lab_tests.push(item)
                        return
                     
                    }
                    if(item.code.toLowerCase().includes(text.toLowerCase())){
                      lab_tests.push(item)
                      return
                   
                  }
                  if(item.id.toLowerCase().includes(text.toLowerCase())){
                    lab_tests.push(item)
                    return
                 
                }
                      
                      return item
              
                  })
                  Promise.all(data).then((item)=>{
                   
                     setRows(lab_tests)
                     
                  })
                  
            }}              
               />
            
               </div>
     {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <>
      <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code+Math.random()} >
                {columns.map((column) => {
                  const value = row[column.id];
                  {console.log(column)}
                  {console.log(value)}

                  var status_color= value=='Just Booked' ? 'brown' : value=='Only Phlebotomy Done' ? 'red' :
value=='Lying Pending Results' ? 'purple' : value=='Results Done But not Signed' ? 'blue' : value=='Results Ready' ? 'green' :'' 

                  return (
                  <>
                      {status_color.trim()!='' ?  
                      <TableCell key={column.id} align={column.align} style={{backgroundColor:status_color, color:'white'}} >
                     <i><b>{value}</b></i>
                                      </TableCell>
                      :  
                       <TableCell key={column.id} align={column.align} >
                      {value}
                                       </TableCell>
                     
                                       }
                   </>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[100, 150, 200]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
     }
  
      
    </Paper>
    <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}

                  maxWidth={'xl'}
                  fullWidth={true}
                  open={show}
                  TransitionComponent={Transition}
                  keepMounted
                  
                  onClose={() => {setPatientInvoice(false)
                  setSelected({
                    reports:[]
                  })
                  }}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>PDF Generation</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                 {
                   show && !showSignCovidMPL ?
                   <RadiologyPDF  DoctorName={DoctorName} DoctorInfo={DoctorInfo}   UserName={UserName} InvoiceDate={InvoiceDate} InvoiceTime={InvoiceTime} Salutation={Salutation} TestTitle={TestTitle} invoice_id={invoice_id} age_gender={age_gender} name={name} refBy={RefBy} Invoice_Aux_ID={Invoice_Aux_ID} Ref_No={Ref_no} ReportDate={ReportDate} ReportTime={ReportTime}
                    //ye add kiye hn radiology me thy but yhan ni 2 jan 2023 sir najam 7501303 Report time date RefNo pass kiye
                   //  GroupTitleNew={"ISH-Pre Employment Medical (Food Handler)"}
                  //  GroupTitleNew={""}
                    // this is for X-ray Result height
        xRayHeight={margTopForXrayResult}
        //image send  that are in base64
        // imageSend={img}
                   />
       :showSignCovidMPL ?
       <SignRadiologyPDF  DoctorName={DoctorName} DoctorInfo={DoctorInfo}   UserName={UserName} InvoiceDate={InvoiceDate} InvoiceTime={InvoiceTime} Salutation={Salutation} TestTitle={TestTitle} invoice_id={invoice_id} age_gender={age_gender} name={name} refBy={RefBy} Invoice_Aux_ID={Invoice_Aux_ID} Ref_No={Ref_no} ReportDate={ReportDate} ReportTime={ReportTime}
                    //ye add kiye hn radiology me thy but yhan ni 2 jan 2023 sir najam 7501303 Report time date RefNo pass kiye
       // GroupTitleNew={"ISH-Pre Employment Medical (Food Handler)"}
        // GroupTitleNew={""}
        // this is for X-ray Result height
        xRayHeight={margTopForXrayResult}
        //image send  that are in base64
        // imageSend={""}
        />
:null
                 }
                
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => {
                        setShow(false)
                        setShowSignMPL(false)
                     
                      }}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
               
               
               
   
  </>
  );
}
